import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const classNames = mergeStyleSets({
    image: {
        fontSize: 24,
        marginTop: 15,
        color: '#006CAD',
    },
    buttonContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 56,
        width: 56,
        margin: '45px 23px',
        color: '#868685',
        selectors: {
            ':hover': {
                boxShadow: '0px 20px 42px rgba(0, 0, 0, 0.08), 0px -2px 14px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 29, 0.08)',
                borderRadius: '4px',
            },
            p: {
                fontSize: 11,
                lineHeigh: 11,
            },
        },
    },
    activeLink: {
        boxShadow: '0px 20px 42px rgba(0, 0, 0, 0.08), 0px -2px 14px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 29, 0.08)',
        borderRadius: '4px',
    },
    badge: {
        background: '#006CAD',
        color: '#ffffff',
        fontSize: 11,
        textAlign: 'center',
        lineHeight: 20,
        height: 20,
        width: 20,
        position: 'absolute',
        top: 5,
        right: 5,
        borderRadius: '50%',
    },
});

const MenuButtonSmall = ({ iconName, to, icon }) => {
    const location = useLocation();
    const className = [classNames.buttonContent];
    if (to === location.pathname) {
        className.push(classNames.activeLink);
    }
    return (
        <div className={className.join(' ')}>
            <FontAwesomeIcon icon={icon} className={classNames.image} />
        </div>
    );
};

export default MenuButtonSmall;
