export const toggleSelectorItem = (selectedOption, itemToToggle) => {
    if (selectedOption.includes(itemToToggle)) {
        return selectedOption.filter(selectedTechArea => selectedTechArea !== itemToToggle);
    } else {
        return [...selectedOption, itemToToggle];
    }
};

export const getMachineDocumentLink = (machineNumber) => {
    return `https://aarsleff.sharepoint.com/teams/FS/SitePages/${machineNumber.toLowerCase().replace(/ /g, '-')}.aspx`
}