// Import libraries
import { useState, useMemo } from "react";
import {
  Persona,
  PersonaSize,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  Stack,
  TeachingBubble,
  mergeStyleSets,
} from "@fluentui/react";

// Import types
import { TProject } from "../../types/project";

const classNames = mergeStyleSets({
  Persona: {
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  station: {
    margin: 5,
    width: 25,
    height: 14,
    color: "#868685",
    background: "#DFDFDF",
    textAlign: "center",
    fontSize: 11,
    lineHeight: 14,
    borderRadius: 4,
    padding: 2,
  },
  favoriteDriver: {
    position: "absolute",
    marginLeft: "200px",
  },
});

export const searchBoxStyles = {
  root: {
    border: "none",
    backgroundColor: "#FFFFFF",
    margin: "-10px -10px 0 -5px",
    height: "56px",
    ":after": {
      border: "none",
    },
  },
  iconContainer: {
    display: "none",
  },
  clearButton: {
    marginLeft: "-50px",
  },
};

type PopupStaffProps = {
  onDismiss?: ((ev?: any) => void) | undefined;
  targetElemId: string;
  projectsData: TProject[];
  onSelectProject: (selectedStaff: TProject) => void;
};

const PopupProject = ({
  onDismiss,
  targetElemId,
  projectsData,
  onSelectProject,
}: PopupStaffProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const displayList: TProject[] = useMemo(() => {
    const arr = projectsData.filter((projectData) => {
      return searchValue
        ? projectData.projectName
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        : true;
    });

    return arr;
  }, [searchValue, projectsData]);

  return (
    <TeachingBubble
      target={`#${targetElemId}`}
      hasSmallHeadline={true}
      onDismiss={onDismiss}
      closeButtonAriaLabel="Close"
    >
      <SearchBox
        styles={searchBoxStyles}
        placeholder="Søg"
        clearButtonProps={{
          style: {
            backgroundColor: "#FFFFFF",
            marginLeft: "20px",
          },
        }}
        value={searchValue}
        onChange={(e, value) => setSearchValue(value)}
      />
      <hr
        style={{
          width: "145%",
          marginLeft: "-50px",
          marginTop: "-2px",
          opacity: 0.5,
        }}
      />
      <div
        style={{
          height: 200,
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ScrollablePane
          style={{ height: 200 }}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          <Stack
            // vertical
            tokens={{ childrenGap: 10 }}
          >
            {displayList &&
              displayList.map((project) => (
                <div
                  key={`worker-${project.projectId}`}
                  className={classNames.Persona}
                  onClick={() => {
                    onSelectProject(project);
                    onDismiss && onDismiss();
                  }}
                >
                  {" "}
                  <Persona
                    text={`${project.projectName}`}
                    secondaryText={project.projectNo}
                    size={PersonaSize.size40}
                  />
                </div>
              ))}
          </Stack>
        </ScrollablePane>
      </div>
    </TeachingBubble>
  );
};

export default PopupProject;
