import { useBoolean } from '@fluentui/react-hooks';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { faClone, faEllipsisVAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CALENDAR_STEP_HEIGHT, hasEditRight, SOURCE_API, stationOptions, workingRoleOptions } from '../../utils/constants';
import { lighterDarkerColor } from '../../utils/utils';

const commonLabelStyle = {
    margin: 5,
    width: 25,
    height: 14,
    color: '#868685',
    background: '#DFDFDF',
    textAlign: 'center',
    fontSize: 11,
    lineHeight: 14,
    borderRadius: 4,
};

const ItemContainer = ({
    name,
    techDepartment,
    techArea,
    index,
    machineId,
    workingRole,
    count = 1,
    imageUrl,
    color,
    machineData,
    isClone,
    staffId,
    calendarStepHeight,
}) => {
    const { highlightedStaffId } = useSelector((state) => state.app);
    const { barDrawer } = useSelector((state) => state.app);
    const userRoles = useSelector((state) => state.user.user.workingRole);
    const { userId } = useSelector((state) => state.user.user);
    let allowEdit = hasEditRight(userRoles);
    const [teachingBubbleShowImage, { toggle: toggleTeachingShowImage }] = useBoolean(false);
    const height = useMemo(() => calendarStepHeight * count, [calendarStepHeight, count]);
    if (barDrawer && !allowEdit && userId === staffId) color = '255, 121, 121';
    // Highlight staff with according y axis which is being hovered when draw bar
    if (staffId === highlightedStaffId) color = '153, 179, 255';

    const classNames = mergeStyleSets({
        root: {
            width: 250,
            height: height,
            // borderBottom: '1px solid #DBDBDB',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: `rgb(${color})`,
            selectors: {
                ':hover': {
                    backgroundColor: color ? `rgb(${lighterDarkerColor(color, -10)})` : 'rgb(232, 232, 232)',
                },
            },
            boxSizing: 'border-box',
        },
        iconSection: {
            display: 'flex',
            alignItems: 'center',
            userSelect: 'none',
        },
        iconContainer: {
            width: 24,
        },
        icon: {
            color: '#006cad',
            fontSize: 16,
        },
        staffIcon: {
            marginRight: 8,
        },
        text: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: 190,
            marginLeft: 16,
            fontWeight: 'bold',
            fontSize: 11,
            lineHeight: 13,
            wordWrap: 'break-word',
            userSelect: 'none',
        },
        stationEast: commonLabelStyle,
        stationWest: [
            commonLabelStyle,
            {
                color: '#DFDFDF',
                background: '#868685',
            },
        ],
        colorSelector: {
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto auto',
            gridGap: 20,
            float: 'left',
            padding: 12,
            maxWidth: 200,
            minWidth: 200,
        },
        actionHeader: {
            fontWeight: 'bold',
            fontFamily: 'Verdana',
            fontSize: 13,
        },
        section: {
            margin: '10px 0',
        },
    });

    // const chooseClassAndText = (techDepartment, techArea) => {
    //     const classes = [classNames.stationEast, classNames.stationWest];
    //     let className = null;
    //     let text = null;
    //     if (techDepartment) {
    //         const options = stationOptions[techDepartment]?.options;
    //         if (options) {
    //             for (let i = 0; i < options.length; i++) {
    //                 if (techArea === options[i].key) {
    //                     text = options[i].shortcutText;
    //                     className = classes[i];
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //     return { className, text };
    // };
    // const { className, text } = chooseClassAndText(techDepartment, techArea);

    const history = useHistory();
    const imageUrlRoleStaff = workingRoleOptions.find((item) => item.key === workingRole)?.icon;

    return (
        <>
            <div className={classNames.root} onDoubleClick={toggleTeachingShowImage} id={`showImage-${index}`}>
                <div className={classNames.text}>
                    {imageUrlRoleStaff && (
                        <div className={classNames.iconContainer}>
                            <FontAwesomeIcon icon={imageUrlRoleStaff} className={classNames.icon} />
                        </div>
                    )}
                    {name}
                </div>
                <div className={classNames.iconSection}>
                    <div style={{ width: 20, height: height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {machineData && <FontAwesomeIcon id={`machineActions-${machineId}`} icon={faEllipsisVAlt} className={classNames.icon} />}
                        {isClone && <FontAwesomeIcon id={`machineActions-${machineId}`} icon={faClone} className={classNames.icon} />}
                    </div>
                    {/* {className && text && (
                        <div className={className}>{text}</div>
                    )} */}
                </div>
            </div>
            {teachingBubbleShowImage && (
                <TeachingBubble closeButtonAriaLabel='Close' target={`#showImage-${index}`} onDismiss={toggleTeachingShowImage}>
                    <Image imageFit={ImageFit.contain} src={imageUrl ? `${SOURCE_API}${imageUrl}` : ''} alt='Machine Image' />
                </TeachingBubble>
            )}
        </>
    );
};

export default ItemContainer;
