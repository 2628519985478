// Import libraries
import { mergeStyleSets } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import redux
import { clearDrawEffort } from "../../../redux/app/app.actions";

// Import types
import { TWeeks } from "../../../types/dates";
import { TProject } from "../../../types/project";
import { TStaff } from "../../../types/staff";
import { THorizontalPositionMap } from "../../../types/positionmap";
import { TCalendar } from "../../../types/calendar";

// Import components
import CalendarBackground from "../../common/CalendarBackground";
import PeopleList from "../../people/PeopleList";
import EffortBarDrawer from "../../common/EffortBarDrawer";
import FerieBar from "../../common/FerieBar";
import FerieBarDrawer from "../../common/FerieBarDrawer";
import EffortBar from "../../common/EffortBar";

// Import utils
import { generatePeopleMap } from "../../../utils/map";
import {
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  HEADER_HEIGHT,
  ITEM_HEIGHT,
} from "../../../utils/constants";

const classNames = mergeStyleSets({
  peopleListContainer: {
    display: "flex",
    gap: 16,
    flexDirection: "column",
    padding: "16px 11px 0px",
  },
  leftContainer: {
    width: 250,
    position: "sticky",
    zIndex: 1002,
    top: 0,
    left: 0,
    paddingLeft: 30,
    background: "rgb(241, 241, 241)",
    transition: "all 0.5s ease",
  },
  machineContainer: {
    width: 250,
    minHeight: "100%",
    marginLeft: 0,
    // marginTop: 30,
    background: "#f1f1f1",
  },
  machineAndCalendarContainer: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "flex-start",
    background: "rgb(241, 241, 241)",
  },
  calendarContainer: {
    minHeight: "100%",
    marginLeft: 0,
    transition: "all 0.5s ease",
  },
});

export type TAddProjectData = {
  userId: string;
  selectedProject: TProject;
};

type PeopleProps = {
  dates: TWeeks[];
  calendarStartFrom: string;
  displayWeekView: boolean;
  totalHeight: number;
  calendarStepWidth: number;
  projectsData: TProject[];
  staffsList: TStaff[];
  horizontalPositionMap: THorizontalPositionMap;
  calendar: TCalendar;
};

const HoritonzalLine = ({ yPosition }: { yPosition: number }) => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      top: yPosition,
      borderBottom: "1px solid #c5c5c5",
    }}
  />
);

const People = ({
  dates,
  calendarStartFrom,
  displayWeekView,
  calendarStepWidth,
  projectsData,
  staffsList,
  horizontalPositionMap,
  calendar,
}: PeopleProps) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const { barDrawer } = useSelector((state) => state.app) as {
    barDrawer: string;
  };

  // @ts-ignore
  const { drawUserEffortId } = useSelector((state) => state.app);

  const [addProjectData, setAddProjectData] = useState<TAddProjectData>();

  // @ts-ignore
  const filter = useSelector((state) => state.machine.filter);

  // We don't want the project id to be persisted
  useEffect(() => {
    return () => {
      dispatch(clearDrawEffort());
    };
  }, [dispatch]);

  const staffNames = filter["staffNames"] || "";
  const projectNames = filter["projectNames"] || "";

  const peopleMap = useMemo(
    () =>
      generatePeopleMap({
        projectsData,
        staffsList,
        addProjectData,
        staffNames,
        projectNames,
      }),
    [projectsData, staffsList, addProjectData, projectNames, staffNames]
  );

  const onClickAddProject = ({
    selectedProject,
    userId,
  }: {
    selectedProject: TProject;
    userId: string;
  }) => {
    setAddProjectData({
      selectedProject,
      userId,
    });
  };

  // This is to ensure draw vacation and draw effor do not contradict each other
  useEffect(() => {
    if (drawUserEffortId) {
      setAddProjectData(undefined);
    }
  }, [drawUserEffortId]);

  useEffect(() => {
    if (addProjectData) {
      dispatch(clearDrawEffort());
    }
  }, [addProjectData, dispatch]);

  const onClearProjectData = useCallback(() => {
    setAddProjectData(undefined);
  }, []);

  const renderAddProjectEffort = useCallback(() => {
    if (!addProjectData) {
      return <></>;
    }

    const yPosition = peopleMap.idToData[addProjectData.userId]?.projects?.find(
      (project) =>
        project.projectId === addProjectData.selectedProject.projectId
    )?.yPosition;

    if (!yPosition) {
      return <></>;
    }

    return (
      <EffortBarDrawer
        calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        calendarStepWidth={calendarStepWidth}
        onDrawingCancelled={onClearProjectData}
        yPosition={yPosition}
        height={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        horizontalPositionMap={horizontalPositionMap}
        color={addProjectData.selectedProject.color}
        userId={addProjectData.userId}
        projectId={addProjectData.selectedProject.projectId}
        efforts={peopleMap.idToData[addProjectData.userId].efforts}
        staffVacations={
          peopleMap.idToData[addProjectData.userId].staffVacations
        }
      />
    );
  }, [
    onClearProjectData,
    addProjectData,
    calendarStepWidth,
    horizontalPositionMap,
    peopleMap.idToData,
  ]);

  const renderEditProjectEffort = useCallback(() => {
    if (!drawUserEffortId) {
      return <></>;
    }

    const { userId, projectId } = drawUserEffortId as {
      userId: string;
      projectId: string;
    };

    const project = peopleMap.idToData[userId]?.projects?.find(
      (project) => project.projectId === projectId
    );

    if (!project) {
      return <></>;
    }

    const { yPosition, color } = project;

    return (
      <EffortBarDrawer
        calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        calendarStepWidth={calendarStepWidth}
        onDrawingCancelled={() => {
          dispatch(clearDrawEffort());
        }}
        yPosition={yPosition}
        height={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        horizontalPositionMap={horizontalPositionMap}
        color={color}
        userId={userId}
        projectId={projectId}
        efforts={peopleMap.idToData[userId].efforts}
        staffVacations={peopleMap.idToData[userId].staffVacations}
      />
    );
  }, [
    drawUserEffortId,
    calendarStepWidth,
    dispatch,
    horizontalPositionMap,
    peopleMap.idToData,
  ]);

  const renderEfforts = useCallback(() => {
    const arr: JSX.Element[] = [];

    peopleMap.data.forEach(({ efforts, userId, projects, staffVacations }) => {
      efforts.forEach((effort) => {
        const project = projects.find(
          (project) => project.projectId === effort.projectId
        );

        if (project) {
          arr.push(
            <EffortBar
              allocation={effort.percentage}
              calendar={calendar}
              calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
              color={project.color}
              end={effort.end}
              start={effort.start}
              horizontalPositionMap={horizontalPositionMap}
              userId={userId}
              yPosition={project.yPosition}
              key={`${effort.end}-${effort.start}-${userId}-${project.projectId}`}
              efforts={efforts}
              staffVacations={staffVacations}
              effortId={effort.id}
              projectId={project.projectId}
            />
          );
        }
      });
    });

    return arr;
  }, [peopleMap, calendar, horizontalPositionMap]);

  const renderFerie = useCallback(() => {
    const arr: JSX.Element[] = [];

    staffsList.forEach((staff) => {
      const { starfVacations, userId, efforts } = staff;

      if (peopleMap.idToData[userId]) {
        if (starfVacations) {
          starfVacations.forEach((staffVacation, idx) => {
            const { start, end } = staffVacation;

            arr.push(
              <FerieBar
                staffVacation={staffVacation}
                key={`staff-vacation-${userId}-${idx}`}
                id={`staff-vacation-${userId}-${idx}`}
                staffVacationIndex={idx}
                calendar={calendar}
                calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                end={end}
                start={start}
                staff={staff}
                horizontalPositionMap={horizontalPositionMap}
                userId={userId}
                yPosition={peopleMap.idToData[userId].yPosition}
                height={HEADER_HEIGHT}
                efforts={efforts}
                staffVacations={starfVacations}
              />
            );
          });
        }
      }
    });

    return arr;
  }, [staffsList, peopleMap, calendar, horizontalPositionMap]);

  const renderFerieBarDrawer = useCallback(() => {
    if (!barDrawer || !peopleMap?.data) {
      return <></>;
    }

    return (
      <FerieBarDrawer
        calendar={calendar}
        calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        calendarStepWidth={calendarStepWidth}
        horizontalPositionMap={horizontalPositionMap}
        staffsData={staffsList}
        peopleData={peopleMap.data.map(
          ({ staffData, userId, yPosition, efforts }) => ({
            staffData: staffData,
            userId: userId,
            yPosition: yPosition,
            efforts,
          })
        )}
        height={HEADER_HEIGHT}
      />
    );
  }, [
    peopleMap,
    barDrawer,
    calendar,
    calendarStepWidth,
    horizontalPositionMap,
    staffsList,
  ]);

  const renderHorizontalLines = useCallback(() => {
    const arr: JSX.Element[] = [];

    peopleMap.data.forEach((user) => {
      const { yPosition, userId, projects } = user;

      // We are drawing the top and bottom
      arr.push(
        <HoritonzalLine
          key={"top-" + userId + "" + yPosition}
          yPosition={yPosition}
        />
      );

      arr.push(
        <HoritonzalLine
          key={"bottom-" + userId + "" + (yPosition + HEADER_HEIGHT)}
          yPosition={yPosition + HEADER_HEIGHT}
        />
      );

      projects.forEach(({ projectId, yPosition }) => {
        // We are drawing the top and bottom of the lines
        arr.push(
          <HoritonzalLine
            yPosition={yPosition}
            key={`top-${projectId}-${userId}-${yPosition}`}
          />
        );
        arr.push(
          <HoritonzalLine
            yPosition={yPosition + ITEM_HEIGHT}
            key={`bottom-${projectId}-${userId}-${yPosition + ITEM_HEIGHT}`}
          />
        );
      });
    });

    return arr;
  }, [peopleMap]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div className={classNames.leftContainer}>
        <div className={classNames.machineContainer}>
          <div className={classNames.peopleListContainer}>
            {peopleMap.data.map(({ name, projects, userId }) => (
              <PeopleList
                name={name}
                projects={projects}
                userId={userId}
                key={userId}
                projectsData={projectsData}
                onClickAddProject={onClickAddProject}
                selectedProjectId={
                  userId === addProjectData?.userId
                    ? addProjectData.selectedProject.projectId
                    : userId === drawUserEffortId?.userId
                    ? drawUserEffortId?.projectId || undefined
                    : undefined
                }
              />
            ))}
          </div>
        </div>
      </div>
      <div className={classNames.machineAndCalendarContainer}>
        <div className={classNames.calendarContainer}>
          <CalendarBackground
            dates={dates}
            calendarStartFrom={calendarStartFrom}
            displayWeekView={displayWeekView}
            totalHeight={peopleMap.height}
            calendarStepWidth={calendarStepWidth}
          >
            <div
              id="planContainer"
              className="planContainer"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 556,
                width: "100%",
                height: peopleMap.height,
              }}
            >
              {renderEditProjectEffort()}
              {renderAddProjectEffort()}
              {renderEfforts()}
              {renderFerie()}
              {renderFerieBarDrawer()}
              {renderHorizontalLines()}
            </div>
          </CalendarBackground>
        </div>
      </div>
    </div>
  );
};

export default People;
