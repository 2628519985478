import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onChangeFilterStaffRole } from "../../../../redux/machines/machines.actions";
import { Stack } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipForText } from "../../../common";
import { classNames } from "./SelectorStyles";

const StaffRoleSelector = () => {
  const dispatch = useDispatch();
  const { techDepartment } = useSelector((state) => state.machine.filter);
  const { staffRole } = useSelector((state) => state.machine.filter);

  return (
    <div className={classNames.root}>
      <div className={classNames.techDepartmentSection}>
        <Stack horizontal="true" tokens={{ childrenGap: 8 }}>
          {staffRole.map((option, index) => (
            <TooltipForText key={option["key"]} text={option.text} noWidth>
              <div
                className={
                  option.isOn ? classNames.buttonSelected : classNames.button
                }
                onClick={() => {
                  dispatch(
                    onChangeFilterStaffRole(
                      index,
                      staffRole,
                      techDepartment,
                      false
                    )
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={option.icon}
                  className={classNames.icon}
                />
              </div>
            </TooltipForText>
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default StaffRoleSelector;
