import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearMessage } from '../../redux/message/message.actions';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { MessageBarType } from '@fluentui/react';
import { MESSAGE_INFO } from '../../utils/constants';

const classNames = mergeStyleSets({
    closeIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30,
        cursor: 'pointer',
        borderRadius: '50%',
        selectors: {
            ':hover': {
                color: '#DFDFDF',
                backgroundColor: '#868685',
            }
        } 
    }
})

const Message = ({message: {message, messageType}, clearMessage}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let timer;
        if(message) {
            setOpen(true);
            timer = setTimeout(() => {clearMessage()}, messageType === MessageBarType.success ? 5000 : 10000);
        } else {
            setOpen(false);
            clearMessage();
        }
        return () => {
            clearTimeout(timer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    if (!open) {
        return null;
    }
    
    return (messageType !== null) && ReactDOM.createPortal(
        <div style={{ position: 'fixed', top: 50, left: 'calc(50% - 250px)', width: 500, zIndex: 9999999}}>
            <div style={{display: 'flex', height: 80, alignItems: 'center', borderRadius: 10, color: '#000', backgroundColor: '#fff'}}>
                <div style={{flexBasis: '15%', height: '100%', textAlign: 'center', fontSize: 24, color: '#fff', backgroundColor: MESSAGE_INFO[messageType].color, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <FontAwesomeIcon icon={MESSAGE_INFO[messageType].icon}/>
                    </div>
                </div>
                <div style={{flexBasis: '75%', textAlign: 'left', width: '75%', wordBreak: 'break-all', fontSize: 14, fontWeight: 400, marginLeft: 15}}>
                    <h3 style={{marginBottom: 5, marginTop: 0}}>{MESSAGE_INFO[messageType].text}</h3>
                    <div style={{wordBreak: 'initial'}}>{message}</div>
                </div>
                <div style={{flexBasis: '10%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#006CAD'}}>
                    <div className={classNames.closeIcon} onClick={() => {setOpen(false); clearMessage()}}> 
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector('#message'),
    )
}

const mapStateToProps = state => ({
    message: state.message,
});

export default connect(mapStateToProps, {clearMessage})(Message);