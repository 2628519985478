import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faCheck } from '@fortawesome/pro-regular-svg-icons'

const classNames = mergeStyleSets({
    root: {
        height: 48,
        width: '80%',
        display: 'flex',
        flexDirection: 'flex-end',
    },
    buttons: {
        width: 72,
        marginTop: 8,
        display: 'flex',
        justifyContent: 'space-between',
    },
    smallApprove: {
        height: 18,
        width: 18,
        borderRadius: '50%',
        background: '#FFFFFF',
        cursor: 'pointer'
    },
    smallApproveIcon: {
        fontSize: 14,
        marginTop: 2,
        marginLeft: 2,
        color: '#1A936F',
    },
    smallCancel: {
        height: 18,
        width: 18,
        borderRadius: '50%',
        background: '#FFFFFF',
        cursor: 'pointer'
    },
    smallCancelIcon: {
        fontSize: 14,
        marginTop: 1,
        marginLeft: 4,
        color: '#F57C00',
    },
    approve: {
        height: 32,
        width: 32,
        borderRadius: '50%',
        background: '#FFFFFF',
        cursor: 'pointer'
    },
    cancel: {
        height: 32,
        width: 32,
        borderRadius: '50%',
        background: '#FFFFFF',
        cursor: 'pointer'
    },
    approveIcon: {
        fontSize: 16,
        margin: 8,
        color: '#1A936F'
    },
    cancelIcon: {
        fontSize: 16,
        marginTop: 8,
        marginLeft: 11,
        color: '#F57C00'
    }
});

const CalendarConfirmation = ({onApprove, onCancel, width}) => {
    if(width <= 75) {
        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%'}}>
                <div className={classNames.smallApprove} onClick={onApprove} >
                    <FontAwesomeIcon icon={faCheck} className={classNames.smallApproveIcon}/>
                </div>
                <div className={classNames.smallCancel} onClick={onCancel}>
                    <FontAwesomeIcon icon={faTimes} className={classNames.smallCancelIcon}/>
                </div>
            </div>
        )
    }
    return (
        <div className={classNames.root}>
            <div className={classNames.buttons}>
                <div className={classNames.approve} onClick={onApprove} >
                    <FontAwesomeIcon icon={faCheck} className={classNames.approveIcon}/>
                </div>
                <div className={classNames.cancel} onClick={onCancel}>
                    <FontAwesomeIcon icon={faTimes} className={classNames.cancelIcon}/>   
                </div>
            </div>
        </div>
    )
}

export default CalendarConfirmation;
