import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import messageReducer from "./message/message.reducer";
import machineReducer from "./machines/machines.reducer";
import planReducer from "./plan/plan.reducer";
import projectReducer from "./project/project.reducer";
import defaultDataReducer from "./defaultData/defaultData.reducer";
import appReducer from "./app/app.reducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["app", "user", "defaultData", "machine", "project"],
  blacklist: ["app", "project"],
  stateReconciler: autoMergeLevel2,
};

const appPersistConfig = {
  key: "app",
  storage,
  blacklist: [
    "barDrawer",
    "page",
    "drawProjectEffortId",
    "drawUserEffortId",
    "zoomRatio",
  ],
};

const projectPersistConfig = {
  key: "project",
  storage,
  blacklist: ["projectData"],
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  user: userReducer,
  message: messageReducer,
  machine: machineReducer,
  plan: planReducer,
  project: persistReducer(projectPersistConfig, projectReducer),
  defaultData: defaultDataReducer,
});

export default persistReducer(persistConfig, rootReducer);
