// Import types
import { TAddProjectData } from "../components/pages/planning/People";
import { TAddUserData } from "../components/pages/planning/Project";
import { TEffort } from "../types/effort";
import { TProject } from "../types/project";
import { StaffVacation, TStaff } from "../types/staff";
import { HEADER_HEIGHT, ITEM_HEIGHT } from "./constants";

export type TPeopleData = {
  name: string;
  userId: string;
  projects: {
    projectId: string;
    projectName: string;
    yPosition: number;
    isEdit?: boolean;
    color: string;
  }[];
  yPosition: number;
  staffVacations: StaffVacation[];
  staffData: TStaff;
  efforts: TEffort[];
};

export type TProjectData = {
  projectData: TProject;
  users: {
    userId: string;
    userName: string;
    yPosition: number;
    isEdit?: boolean;
    staffVacations: StaffVacation[];
    efforts: TEffort[];
  }[];
  yPosition: number;
  efforts: TEffort[];
};

export type TMap<T> = {
  [k: string]:
    | number
    | T[]
    | {
        id: string;
        yPosition: number;
      }[]
    | {
        [k: string]: T;
      };
  height: number;
  data: T[];
  positions: {
    id: string;
    yPosition: number;
  }[];
  idToData: {
    [k: string]: T;
  };
};

// project id to projectName and projectId
type TProjectMap = {
  [k: string]: {
    projectData: TProject;
    efforts: TEffort[];
  };
};

// Basically user id to user name and userId
type TUserMap = {
  [k: string]: {
    userId: string;
    name: string;
    efforts: TEffort[];
    staffVacations: StaffVacation[];
    staffData: TStaff;
  };
};

const getMaps = ({
  projectsData,
  staffsList,
}: {
  staffsList: TStaff[];
  projectsData: TProject[];
}) => {
  const projectMap: TProjectMap = {};

  projectsData.forEach((projectData) => {
    projectMap[projectData.projectId] = {
      projectData,
      efforts: [],
    };
  });

  const userMap: TUserMap = {};

  staffsList.forEach(
    ({ userId, lastName, firstName, efforts, starfVacations }, idx) => {
      const name = firstName + " " + lastName;

      userMap[userId] = {
        name,
        userId,
        efforts,
        staffVacations: starfVacations,
        staffData: staffsList[idx],
      };

      efforts.forEach((effort) => {
        const { projectId } = effort;

        if (projectMap[projectId]) {
          projectMap[projectId].efforts = [
            ...projectMap[projectId].efforts,
            effort,
          ];
        }
      });
    }
  );

  return {
    userMap,
    projectMap,
  };
};

export const generateProjectMap = ({
  projectsData,
  staffsList,
  addUserData,
  projectNames,
  staffNames,
}: {
  staffNames?: string;
  projectNames?: string;
  staffsList: TStaff[];
  projectsData: TProject[];
  addUserData?: TAddUserData;
}): {
  projectMap: TMap<TProjectData>;
  userIdToData: {
    [userId: string]: {
      yPositions: number[];
    };
  };
  usersData: {
    yPosition: number;
    staffData: TStaff;
    userId: string;
    efforts: TEffort[];
  }[];
} => {
  // I will start with the padding to the top
  const map: TMap<TProjectData> = {
    data: [],
    height: 16,
    positions: [],
    idToData: {},
  };

  const userIdToData: {
    [userId: string]: {
      yPositions: number[];
    };
  } = {};

  const usersData: {
    yPosition: number;
    staffData: TStaff;
    userId: string;
    efforts: TEffort[];
  }[] = [];

  // We are making a dictionary for the user and project
  // So we can look up the id quickly
  const { projectMap, userMap } = getMaps({ projectsData, staffsList });

  const projectIds = Object.keys(projectMap);

  // If this is the second project then there is a gap of 16px
  // User Header - 40px Height
  // Project Item - 40px Height
  projectIds.forEach((projectId, idx) => {
    const { projectName, color } = projectMap[projectId].projectData;
    const { efforts } = projectMap[projectId];

    if (
      projectNames &&
      !projectName.toLowerCase().includes(projectNames.toLowerCase())
    ) {
      return;
    }

    if (idx !== 0 && map.positions.length) {
      // Because there is a gap of 16px between each segments
      map.height += 16;
    }

    const projectYPosition = map.height;

    map.height += HEADER_HEIGHT;

    const users: {
      userName: string;
      userId: string;
      yPosition: number;
      isEdit?: boolean;
      staffVacations: StaffVacation[];
      efforts: TEffort[];
      staffData: TStaff;
    }[] = [];

    // Accumulate the toal user height push
    // let usersHeight = 0;

    const userIdLists: string[] = [];

    efforts.forEach(({ userId }) => {
      if (userMap[userId] && !userIdLists.includes(userId)) {
        const { name, staffVacations, efforts, staffData } = userMap[userId];

        if (
          staffNames &&
          !name.toLowerCase().includes(staffNames.toLowerCase())
        ) {
          return;
        }

        users.push({
          userName: name,
          userId,
          yPosition: map.height,
          staffVacations,
          efforts,
          staffData,
        });

        usersData.push({
          userId,
          staffData,
          yPosition: map.height,
          efforts,
        });

        if (!userIdToData[userId]) {
          userIdToData[userId] = {
            yPositions: [map.height],
          };
        } else {
          userIdToData[userId].yPositions.push(map.height);
        }

        map.height += ITEM_HEIGHT;

        userIdLists.push(userId);
      }
    });

    if (addUserData?.projectId === projectId) {
      const {
        selectedStaff: { userId, firstName, lastName },
      } = addUserData;

      const { staffVacations, efforts, staffData } = userMap[userId];

      users.push({
        userId,
        userName: firstName + " " + lastName,
        yPosition: map.height,
        isEdit: true,
        staffVacations,
        efforts,
        staffData,
      });

      usersData.push({
        userId,
        staffData,
        yPosition: map.height,
        efforts,
      });

      if (!userIdToData[userId]) {
        userIdToData[userId] = {
          yPositions: [map.height],
        };
      } else {
        userIdToData[userId].yPositions.push(map.height);
      }

      map.height += ITEM_HEIGHT;
    }

    // This is for the add button
    map.height += ITEM_HEIGHT;

    map.data.push({
      projectData: projectMap[projectId].projectData,
      users,
      yPosition: projectYPosition,
      efforts,
    });

    map.idToData[projectId] = {
      projectData: projectMap[projectId].projectData,
      users,
      yPosition: projectYPosition,
      efforts,
    };

    map.positions.push({
      id: projectId,
      yPosition: projectYPosition,
    });
  });

  return { projectMap: map, userIdToData, usersData };
};

// 1) Make a dictionary of users and projects so we can look up
// 2) The dictionary's key is the id of the user id and project id
// 3) You can refer to TUserMap and TProjectMap for the value of each respective dictionary
// 4) Then we loop through the user map and add them into the final map
// 5) Excluding the first user, we will add 16px before processing the other users
// 6) Because the ui is in such a way that there is a 16px gap between each of the segments
// 7) Segment refers to a user and the project under it
export const generatePeopleMap = ({
  projectsData,
  staffsList,
  addProjectData,
  projectNames,
  staffNames,
}: {
  staffsList: TStaff[];
  projectsData: TProject[];
  addProjectData?: TAddProjectData;
  staffNames?: string;
  projectNames?: string;
}): TMap<TPeopleData> => {
  // I will add the padding to the top
  const map: TMap<TPeopleData> = {
    data: [],
    height: 16,
    positions: [],
    idToData: {},
  };

  // We are making a dictionary for the user and project
  // So we can look up the id quickly
  const { projectMap, userMap } = getMaps({ projectsData, staffsList });

  const userIds = Object.keys(userMap);

  // If this is the second person then there is a gap of 16px
  // User Header - 40px Height
  // Project Item - 40px Height

  userIds.forEach((userId, idx) => {
    const userData = userMap[userId];

    const { name, efforts, staffVacations, staffData } = userMap[userId];

    if (
      staffNames &&
      !userData.name.toLowerCase().includes(staffNames.toLowerCase())
    ) {
      return;
    }

    const projectNamesMap = efforts
      .map(({ projectId }) => projectMap[projectId]?.projectData?.projectName)
      .filter((el) => !!el);

    if (
      projectNames &&
      !projectNamesMap.find((el) => el.toLowerCase().includes(projectNames))
    ) {
      return;
    }

    if (idx !== 0 && map.positions.length) {
      // Because there is a gap of 16px between each segments
      map.height += 16;
    }

    const userYPosition = map.height;

    // The user header is 40px
    map.height += HEADER_HEIGHT;

    const projects: {
      yPosition: number;
      projectName: string;
      projectId: string;
      isEdit?: boolean;
      color: string;
    }[] = [];

    // Accumulated the total project height
    // let projectsHeight = 0;

    const projectIdLists: string[] = [];

    efforts.forEach(({ projectId }) => {
      if (projectMap[projectId] && !projectIdLists.includes(projectId)) {
        if (
          projectNames &&
          projectMap[projectId].projectData.projectName
            .toLowerCase()
            .includes(projectMap[projectId].projectData.projectName)
        ) {
          return;
        }

        const { projectName, color } = projectMap[projectId].projectData;

        projects.push({
          projectId,
          projectName,
          yPosition: map.height,
          color,
        });

        map.height += ITEM_HEIGHT;
        projectIdLists.push(projectId);
      }
    });

    if (addProjectData && addProjectData.userId === userId) {
      const {
        selectedProject: { projectId, projectName, color },
      } = addProjectData;

      projects.push({
        projectId,
        projectName,
        yPosition: map.height,
        isEdit: true,
        color,
      });

      map.height += ITEM_HEIGHT;
    }

    // The add row is 32 px
    map.height += ITEM_HEIGHT;

    map.data.push({
      name,
      projects,
      userId,
      yPosition: userYPosition,
      staffVacations,
      efforts,
      staffData,
    });

    map.idToData[userId] = {
      name,
      projects,
      userId,
      yPosition: userYPosition,
      staffVacations,
      efforts,
      staffData,
    };

    map.positions.push({
      id: userId,
      yPosition: userYPosition,
    });
  });

  return map;
};

// Height might differ depending on the view
// For project view, users are placed under a header of project
// So for users have 32px height
// For user view, projects are placed under a header of user
// So users are 40px since they are the headers
export const findUserByPosition = ({
  peopleData,
  height = ITEM_HEIGHT,
  mouseYPosition,
}: {
  peopleData: {
    yPosition: number;
    staffData: TStaff;
    userId: string;
    efforts: TEffort[];
  }[];
  height?: number;
  mouseYPosition: number;
}) => {
  let left = 0;
  let right = peopleData.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const user = peopleData[mid];
    const userYPosition = user.yPosition;

    if (
      mouseYPosition >= userYPosition - height / 2 &&
      mouseYPosition <= userYPosition + height / 2
    ) {
      return user;
    } else if (mouseYPosition < userYPosition) {
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  return null;
};
