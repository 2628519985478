import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@fluentui/react";
import { useEffect, useLayoutEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ConflictMessage, Message } from "./components/common";
import UserLeaveConfirmation from "./components/common/UserLeaveConfirmation";
import Login from "./components/pages/Login";
import NotificationManagement from "./components/pages/NotificationManagement";
import Planning from "./components/pages/planning/Planning";
import PrivateRoute from "./components/routing/PrivateRoute";
import { getDefaultData } from "./redux/defaultData/defaultData.action";
import { appTheme } from "./utils/theme";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import LoginTeams from "./components/pages/LoginTeams";
import ConfigureTeams from "./components/pages/TeamsConfiguration";

const queryClient = new QueryClient();

const App = ({ instance }) => {
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const filter = useSelector((state) => state.machine.filter);
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(true);

  useLayoutEffect(() => {
    const body = document.querySelectorAll("body")[0];
    body.style.zoom = 1 * userZoomRatio;
  }, [userZoomRatio]);

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      return;
    }
    dispatch(getDefaultData());
  }, [dispatch, filter]);

  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={appTheme}>
          <Router
            getUserConfirmation={(message, callback) => {
              return UserLeaveConfirmation(
                message,
                callback,
                confirmOpen,
                setConfirmOpen
              );
            }}
          >
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/login-teams">
                <LoginTeams />
              </Route>
              <Route exact path="/configure-teams">
                <ConfigureTeams />
              </Route>
              <PrivateRoute exact path="/">
                <Planning />
              </PrivateRoute>
              <PrivateRoute exact path="/notification-management">
                <NotificationManagement />
              </PrivateRoute>
            </Switch>
          </Router>
          <Message />
          <ConflictMessage />
          <ReactQueryDevtools />
        </ThemeProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
