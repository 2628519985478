// Import libraries
import { useState, useRef, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Rnd } from "react-rnd";
import { useDispatch, useSelector } from "react-redux";

// Import types
import { THorizontalPositionMap } from "../../types/positionmap";
import { TCalendar } from "../../types/calendar";
import { StaffVacation, TStaff } from "../../types/staff";

// Import constants
import {
  calendarConfirmationStatus,
  hasEditRight,
  reasonVacationStaffOptions,
} from "../../utils/constants";

// Import components
import ConfirmBubble from "./ConfirmBubble";
import TooltipForText from "./TooltipForText";

// Import redux
import { setBarDrawer } from "../../redux/app/app.actions";
import { updateStaff } from "../../redux/user/user.actions";

// Import utils
import { checkOverlap } from "../../utils/effort";
import { findUserByPosition } from "../../utils/map";
import { saveMessage } from "../../redux/message/message.actions";
import { TEffort } from "../../types/effort";

type FerieBarDrawerProps = {
  calendarStepWidth: number;
  calendarStepHeight: number;
  horizontalPositionMap: THorizontalPositionMap;
  calendar: TCalendar;
  staffsData: TStaff[];
  peopleData: {
    yPosition: number;
    staffData: TStaff;
    userId: string;
    efforts: TEffort[];
  }[];
  height: number;
};

const FerieBarDrawer = ({
  calendar,
  calendarStepHeight,
  calendarStepWidth,
  horizontalPositionMap,
  staffsData,
  peopleData,
  height,
}: FerieBarDrawerProps) => {
  const drawerTypes = {
    staffVacation: {
      text: "Staff vacation",
      textColor: "#fff",
      colorCode: "0,108,173",
      lighterColorCode: "51,137,189",
      onSave: () => saveStaffVacation(),
    },
  };

  const dispatch = useDispatch();

  // @ts-ignore
  const calendarState = JSON.stringify(useSelector((state) => state.machine));

  // @ts-ignore
  const userRoles = useSelector((state) => state.user.user.workingRole);
  const allowEdit = hasEditRight(userRoles);

  // const {
  //     barDrawer,
  //     vacationReason,
  //     // highlightedStaffId
  // } = useSelector(
  //     // @ts-ignore
  //     (state) => state.app
  // ) as { barDrawer: keyof typeof drawerTypes, vacationReason: number };

  // @ts-ignore
  const { barDrawer, vacationReason } = useSelector((state) => state.app);

  // @ts-ignore
  const currentDrawType = drawerTypes[barDrawer];

  const [barInfo, setBarInfo] = useState<{
    start: number | null;
    end: number | null;
    id: string;
    currentItemIndex: number | null;
    name: undefined | string | number;
    reason: number;
    notes: string;
    currentStaffId?: string;
    matchedStaff: {
      yPosition: number;
      staffData: TStaff;
      userId: string;
      efforts: TEffort[];
    } | null;
  }>({
    id: `Id-${currentDrawType.text} ${new Date().toUTCString()}`,
    start: null,
    end: null,
    currentItemIndex: null, // index of machine/staff with the y axis
    name: reasonVacationStaffOptions.find((item) => item.key === vacationReason)
      ?.text,
    reason: vacationReason,
    notes: "",
    matchedStaff: null,
  });

  // @ts-ignore
  const defaultFilter = useSelector((state) => state.user.user.defaultFilter);
  // @ts-ignore
  const userZoomRatio = 1;
  // @ts-ignore
  const currentUserId = useSelector((state) => state.user.user.userId);

  // Flag Variables
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );

  // Calculation variables
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState<{
    yPosition: number;
    staffData: TStaff;
    userId: string;
    efforts: TEffort[];
  }>();
  const [width, setWidth] = useState(0);
  const [canDisplay, setCanDisplay] = useState(false);
  const [isDrawing, setIsDrawing] = useState(true);

  const [editName, setEditName] = useState(false);

  const queryClient = useQueryClient();

  const ref = useRef(null);
  const renderDependencies = calendarState + JSON.stringify(barInfo);

  // @ts-ignore
  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;
  const xAxisStartingPoint = 280;
  const yAxisStartingPoint = 202;

  const [updatePackage, setUpdatePackage] = useState(null);

  const { mutate: updateStaffMutation } = useMutation<any, Error, TStaff>(
    (staff) => updateStaff(staff)(dispatch),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("plans");
          queryClient.invalidateQueries("staffs");
          cancelDrawBar();
        }
      },
    }
  );

  const { colorCode, lighterColorCode } = currentDrawType;
  let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterColorCode}) 24px, rgb(${lighterColorCode}) 48px)`;

  useEffect(() => {
    changeCursorOfPlanContainer(isDrawing);

    // Style the resize cursor
    const barDiv = document.getElementById(barInfo.id) as HTMLElement;

    if (barDiv && barDiv?.parentElement?.lastChild?.firstChild) {
      const firstChild = barDiv.parentElement.lastChild
        .firstChild as HTMLElement;
      const lastChild = barDiv.parentElement.lastChild;

      firstChild.style.cursor = `url(${window.location.origin}/img/arrows-alt-h.svg), auto`;
      firstChild.style.left = "-14px";

      if (lastChild?.lastChild) {
        const lastChild = barDiv.parentElement.lastChild
          .lastChild as HTMLElement;

        lastChild.style.cursor = `url(${window.location.origin}/img/arrows-alt-h.svg), auto`;
        lastChild.style.right = "3px";
      }
    }
  }, [isDrawing]);

  const changeCursorOfPlanContainer = (isDrawing: boolean) => {
    const planContainer = document.getElementsByClassName(
      "planContainer"
    )[0] as HTMLElement;
    planContainer.style.cursor = isDrawing
      ? `url(${window.location.origin}/img/pencil-alt.svg), auto`
      : "default";
  };

  const isOverlap = (newX: number, newWidth: number, newY: number) => {
    // @ts-ignore
    // const newStart = parseInt(parseInt(newX / calendarStepWidth).toFixed(0));
    // @ts-ignore
    // const newEnd = parseInt(parseInt(newStart + newWidth / calendarStepWidth).toFixed(0));
    // const staffData = Object.values(verticalPositions.positionsToIds).find((item) => item.userId === barInfo.currentStaffId);
    // const staffData = {};
    // const startDate = new Date(horizontalPositionMap.positionsToDates[newStart].slice(0, 10));
    // let endDate = new Date(horizontalPositionMap.positionsToDates[newEnd].slice(0, 10));
    // endDate.setDate(endDate.getDate() - 1);
    // const listToCheck = [...(staffData.starfVacations || [])];
  };
  const handleMouseDown = (ev: MouseEvent) => {
    if (isDrawing) {
      const xPosition = ev.pageX / userZoomRatio - xAxisStartingPoint;
      const yPosition = ev.pageY / userZoomRatio - yAxisStartingPoint;

      let startOfBar = Math.floor(xPosition / calendarStepWidth);
      const currentItemIndex = Math.floor(yPosition / calendarStepHeight);

      const matchedStaff = findUserByPosition({
        mouseYPosition: yPosition,
        peopleData,
        height,
      });

      if (!matchedStaff) {
        cancelDrawBar();
      } else {
        const { userId } = matchedStaff;

        if (userId !== currentUserId && !allowEdit) {
          cancelDrawBar();
          dispatch(saveMessage(`You can't create vacation for other users`));
          return;
        }

        setSelectedStaff(matchedStaff);
      }

      setBarInfo({
        ...barInfo,
        start: startOfBar,
        end: startOfBar + 1,
        currentItemIndex: currentItemIndex,
        currentStaffId: matchedStaff?.userId as string,
        matchedStaff,
      });
    }
  };

  const handleMouseMove = (ev: MouseEvent) => {
    // Highlight the machine has same y axis
    // if (isDrawing) {
    // const yPosition = ev.pageY / userZoomRatio - yAxisStartingPoint;
    // const currentItemIndex = Math.floor(yPosition / calendarStepHeight);
    // const matchedStaffId = verticalPositions['positionsToIds'][currentItemIndex * calendarStepHeight];
    // if (highlightedStaffId !== matchedStaffId) dispatch(setHighlightStaff(matchedStaffId || null));
    // }
    if (ev?.pageX && isDrawing && barInfo.start !== null) {
      const xPosition = ev.pageX / userZoomRatio - xAxisStartingPoint;
      const endOfBar = Math.floor(xPosition / calendarStepWidth);
      setBarInfo({
        ...barInfo,
        end: endOfBar,
      });
    }
  };

  const handleMouseUp = (ev: MouseEvent) => {
    if (isDrawing && typeof barInfo.start === "number") {
      const xPosition = ev.pageX / userZoomRatio - xAxisStartingPoint;
      const endOfBar = Math.floor(xPosition / calendarStepWidth);
      const isBackwardDrawing = endOfBar < barInfo.start;

      if (isBackwardDrawing) cancelDrawBar();

      const startDateStr =
        horizontalPositionMap["positionsToDates"][barInfo.start];
      const endDateStr =
        horizontalPositionMap["positionsToDates"][endOfBar - 1];

      const matchedStaff = selectedStaff;

      if (matchedStaff) {
        const {
          efforts,
          staffData: { starfVacations: staffVacations },
        } = matchedStaff;

        if (
          checkOverlap({
            efforts,
            staffVacations,
            end: endDateStr,
            start: startDateStr,
            isFerie: true,
          })
        ) {
          dispatch(saveMessage("Cannot Overlap"));
          cancelDrawBar();
          return;
        }

        setIsDrawing(false);
        setBarInfo({
          ...barInfo,
          end: endOfBar === barInfo.start ? endOfBar + 1 : endOfBar,
        });
        setConfirmation(
          calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
        );

        // @ts-ignore
        if (barDrawer !== "plan") setTimeout(() => setEditName(true), 100);
      }
    }
  };

  const handleEscClick = ({ key }: KeyboardEvent) => {
    if (key === "Escape") cancelDrawBar();
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown, { passive: true });
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    window.addEventListener("mouseup", handleMouseUp, { passive: true });
    window.addEventListener("keydown", handleEscClick, { passive: true });
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleEscClick);
    };
  });

  useEffect(() => {
    if (
      typeof barInfo.start === "number" &&
      typeof barInfo.end === "number" &&
      barInfo.matchedStaff
    ) {
      // @ts-ignore
      if (shouldComponentRerender) ref.current = renderDependencies;
      setX(barInfo.start * calendarStepWidth);
      // setY(yPosition);
      setY(barInfo.matchedStaff.yPosition);
      setWidth((barInfo.end - barInfo.start) * calendarStepWidth);
      setCanDisplay(true);
    }
  }, [horizontalPositionMap, calendar, calendarStepWidth, barInfo]);

  const saveStaffVacation = async () => {
    if (
      typeof barInfo.start === "number" &&
      typeof barInfo.end === "number" &&
      barInfo.matchedStaff
    ) {
      // const staffData = staffsData.find((item) => item.userId === barInfo.currentStaffId);
      const start = horizontalPositionMap["positionsToDates"][barInfo.start];
      const end = horizontalPositionMap["positionsToDates"][barInfo.end - 1];
      let color = null;

      switch (barInfo.reason) {
        case 0:
          color = "245,124,0"; // orange
          break;
        case 1:
          color = "0,108,173"; // blue
          break;
        case 2:
          color = "26, 147, 111"; // green
          break;
        default:
      }
      let vacationStaffForm: StaffVacation = {
        color,
        reason: barInfo.reason,
        notes: barInfo.notes,
        start,
        end,
      };

      const starfVacations =
        barInfo.matchedStaff.staffData.starfVacations &&
        barInfo.matchedStaff.staffData.starfVacations.length > 0
          ? [
              ...barInfo.matchedStaff.staffData.starfVacations,
              vacationStaffForm,
            ]
          : [vacationStaffForm];

      const staff: TStaff = {
        ...barInfo.matchedStaff.staffData,
        starfVacations,
      };
      await updateStaffMutation(staff);
    }
  };

  const rollback = ({
    previousX,
    previousY,
    previousW,
  }: {
    previousX: number;
    previousY: number;
    previousW: number;
  }) => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    setX(previousX);
    setY(previousY);
    setWidth(previousW);
    setUpdatePackage(null);
  };

  const cancelDrawBar = () => {
    changeCursorOfPlanContainer(false);
    // dispatch(setHighlightStaff(null));
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    dispatch(setBarDrawer(""));
  };

  let content = (
    <div
      className={editName ? "modifying" : "dragHandle"}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "calc(100% - 15px)",
        height: "100%",
        cursor: `url(${window.location.origin}/img/arrows-alt.svg), auto`,
      }}
    >
      <div style={{ height: "100%", width: "100%", marginLeft: 10 }}>
        <div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              userSelect: "none",
              marginTop: 2,
            }}
          >
            <span
              style={{
                color: currentDrawType.textColor,
                fontWeight: "bold",
                fontSize: 11,
                marginRight: 8,
                cursor: "text",
              }}
              onClick={() => setEditName(true)}
            >
              {barInfo["name"]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return canDisplay && width ? (
    <Rnd
      style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
      size={{ width, height: calendarStepHeight }}
      position={{ x, y }}
      enableResizing={{
        left: !isDrawing ? true : false,
        right: !isDrawing ? true : false,
      }}
      onResize={() =>
        setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET)
      }
      onResizeStop={(e, direction, ref, delta, position) => {
        setConfirmation(
          calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
        );
        const prevX = x;
        const prevW = width;
        const newX = parseInt(position.x.toFixed(0));
        let newWidth = ref.offsetWidth;
        // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
        if (newWidth % calendarStepWidth !== 0) {
          newWidth =
            // @ts-ignore
            (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
        }
        if (newX === prevX && newWidth === prevW) {
          return;
        }

        const start = Math.floor(newX / calendarStepWidth);
        const end = Math.floor((newX + newWidth) / calendarStepWidth);

        const startDateStr = horizontalPositionMap["positionsToDates"][start];
        const endDateStr = horizontalPositionMap["positionsToDates"][end - 1];

        const matchedStaff = selectedStaff;

        if (matchedStaff) {
          const {
            efforts,
            staffData: { starfVacations: staffVacations },
          } = matchedStaff;

          if (
            checkOverlap({
              efforts,
              staffVacations,
              end: endDateStr,
              start: startDateStr,
              isFerie: true,
            })
          ) {
            dispatch(saveMessage("Cannot Overlap"));
            return;
          }
          setX(newX);
          setWidth(newWidth);
          setBarInfo({
            ...barInfo,
            start,
            end,
          });
        }
      }}
      onDrag={() =>
        setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET)
      }
      onDragStop={(e, d) => {
        setConfirmation(
          calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
        );
        // this is kinda suprising but the values that match the grids are the lastX and lastY instead of x and y, so that is what we set
        const prevX = x;
        const prevY = y;
        const newX = parseInt(d.lastX.toFixed(0));
        const newY = parseInt(d.lastY.toFixed(0));
        if (newX === prevX && newY === prevY) {
          return;
        }

        if (selectedStaff) {
          const {
            efforts,
            staffData: { starfVacations: staffVacations },
          } = selectedStaff;

          const start = Math.floor(newX / calendarStepWidth);
          const end = Math.floor((newX + width) / calendarStepWidth);

          const startDateStr = horizontalPositionMap["positionsToDates"][start];
          const endDateStr = horizontalPositionMap["positionsToDates"][end - 1];

          if (
            checkOverlap({
              efforts,
              end: endDateStr,
              staffVacations,
              start: startDateStr,
              isFerie: true,
            })
          ) {
            dispatch(saveMessage("Cannot overlap"));
            return;
          }
          setX(newX);
          setY(newY);
          const currentItemIndex = Math.floor(newY / calendarStepHeight);
          setBarInfo({
            ...barInfo,
            start,
            end,
            currentItemIndex,
          });
        }
      }}
      resizeGrid={[calendarStepWidth, calendarStepHeight]}
      dragGrid={[calendarStepWidth, calendarStepHeight]}
      dragAxis="x"
      bounds=".planContainer"
      dragHandleClassName="dragHandle"
    >
      <div
        id={barInfo.id}
        style={{
          background: bgColor,
          display: "flex",
          marginTop: 5,
          height: calendarStepHeight - 8,
          borderRadius: 4,
        }}
      >
        {confirmation ===
          calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED &&
          typeof updatePackage === "object" && (
            // @ts-ignore
            <ConfirmBubble
              onCancel={cancelDrawBar}
              onApprove={currentDrawType.onSave}
              targetId={barInfo.id}
            />
          )}
        {/* @ts-ignore */}
        <TooltipForText text={barInfo["name"]}>{content}</TooltipForText>
      </div>
    </Rnd>
  ) : (
    <></>
  );
};

export default FerieBarDrawer;
