import { ProjectActionTypes } from "./project.types";

const initialState = {
  projectId: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectActionTypes.VIEW_PROJECT:
      return {
        ...state,
        projectId: action.payload.projectId,
      };
    case ProjectActionTypes.UNVIEW_PROJECT: {
      return {
        projectId: null,
      };
    }
    default:
      return state;
  }
};

export default projectReducer;
