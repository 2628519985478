import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TooltipForText } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { getWeeksOfYear, getWeekNumber, getMonday } from '../../../utils/utils';

const classNames = direction => mergeStyleSets({
    yearContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    arrowContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        marginBottom: '10px'
    },
    arrowItem: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 13,
        width: 28,
        height: 28,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgb(222, 222, 222)',
            borderRadius: 2,
        }
    },
    arrow: {
        color: '#888888',
        fontSize: 13,
    },
    year: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '0px 0px 10px 5px',
    },
    weekContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        transform: direction !== 'none' ? (direction === 'down' ? 'translate(0, 130%)' : 'translate(0, -130%)') : 'none',
        transition: 'all 0.3s ease',
    },
    weekItem: {
        color: '#000',
        cursor: 'pointer',
        zIndex: 2,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 34,
        height: 34,
        fontSize: 11,
        selectors: {
            ":hover": {
                backgroundColor: '#DEDEDE',
            },
        },
        transform: 'all 0.3s ease',
    },
    circleCurrentWeek: {
        position: 'absolute',
        border: '2px solid #006CAD',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 26,
        width: 26,
        borderRadius: '50%'
    },
    footerText: {
        margin: '20px 0 5px 180px',
        color: 'black',
        fontSize: 12,
        cursor: 'pointer',
        '&:hover': {
            color: 'rgb(0, 108, 173)'
        }
    },
    footerTextDisabled: {
        margin: '20px 0 5px 180px',
        color: '#adadad',
        fontSize: 12,
    },
    selected: {
        backgroundColor: '#006CAD',
        color: '#fff'
    },
});

const WeekCalendar = ({onClickChooseDate}) => {
    const [direction, setDirection] = useState('none');
    const { selectedDayOnCalendar } = useSelector((state) => state.machine);
    const selectedDate = new Date(selectedDayOnCalendar);
    const currentYear = selectedDate.getFullYear();
    const selectedWeek = getWeekNumber(selectedDate.getMonth() + 1, selectedDate.getDate(), selectedDate.getFullYear());
    const [weeks, setWeeks] = useState(getWeeksOfYear(currentYear));
    const [year, setYear] = useState(currentYear);
    const monday = getMonday(new Date());
    const currentWeek = getWeekNumber(monday.getMonth() + 1, monday.getDate(), monday.getFullYear());
    const classes = classNames(direction);

    useEffect(() => {
        const newWeeks = getWeeksOfYear(year);
        setWeeks(newWeeks);
    }, [year])
    return (
        <div>
            <div className={classes.yearContainer}>
                <div className={classes.year}>{year}</div>
                <div className={classes.arrowContainer}>
                    <div className={classes.arrowItem}
                        onClick={() => {
                            setDirection('up');
                            setTimeout(() => {
                                setYear(year - 1);
                                setDirection('none');
                            }, 300);
                        }}
                    >
                        <TooltipForText text={`Previous year ${year - 1}`}>
                            <FontAwesomeIcon className={classes.arrow} icon={faArrowUp}/>
                        </TooltipForText>
                    </div>
                    <div className={classes.arrowItem}
                        onClick={() => {
                            setDirection('down');
                            setTimeout(() => {
                                setYear(year + 1);
                                setDirection('none');
                            }, 300);
                        }}
                    >
                        <TooltipForText text={`Next year ${year + 1}`}>
                            <FontAwesomeIcon className={classes.arrow} icon={faArrowDown}/>
                        </TooltipForText>
                    </div>
                </div>
            </div>
            <div className={classes.weekContainer}>
                {weeks.map((week, index) => 
                    <div
                        key={index}
                        className={classes.weekItem}
                        style={index + 1 === selectedWeek && year === currentYear ? { backgroundColor: '#A1CCE7'} : {}}
                        onClick={() => onClickChooseDate(new Date(week.dates[0].year, week.dates[0].month, week.dates[0].date))}>
                        {index + 1}
                        {week.currentWeek && index + 1 !== selectedWeek && <div className={classes.circleCurrentWeek}/>}
                    </div>
                )}
            </div>
            <div 
                className={(currentWeek !== selectedWeek || year !== (new Date()).getFullYear()) ? classes.footerText : classes.footerTextDisabled}
                onClick={() => {
                    if (year !== (new Date()).getFullYear()) {
                        setDirection('down');
                        setTimeout(() => {
                            setYear((new Date()).getFullYear());
                            onClickChooseDate(monday);
                            setDirection('none');
                        }, 300);
                    }
                    if (currentWeek !== selectedWeek) onClickChooseDate(monday);
                }}
            >Go to current week</div>
        </div>
    )
}

export default WeekCalendar;