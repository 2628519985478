import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { TProject } from "../../types/project";
import {
  getProject,
  unviewProject,
  updateProject,
} from "../../redux/project/project.actions";
import {
  Panel,
  ScrollablePane,
  ScrollbarVisibility,
  TeachingBubble,
  mergeStyleSets,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faLink,
  faPalette,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { crmLink, openInNewTab } from "../../utils/utils";
import MapAddressComponent from "./MapAddressComponent";
import ColorPickerComponent from "./ColorPickerComponent";
import { TDates } from "../../types/dates";
import { getPlans } from "../../redux/plan/plan.actions";
import { TPlan } from "../../types/plan";
import PictureAndCommentSection from "./PictureAndCommentSection";
import ScreenSizeAndPosition from "./ScreenSizeAndPosition";
import PlanReview from "./PlanReview";
// import ScreenSizeAndPosition from "./ScreenSizeAndPosition";
// import PictureAndCommentSection from "./PictureAndCommentSection";

const planClassNames = mergeStyleSets({
  buttonBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButtonOutside: {
    zIndex: 1,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 20,
    left: -40,
    height: 40,
    width: 40,
    background: "#DFDFDF",
    cursor: "pointer",
    boxShadow:
      "rgb(0 0 0 / 22%) -5px 0px 5px 0px, rgb(0 0 0 / 18%) -14px 0px 20px 0px",
    borderTopLeftRadius: "10%",
    borderBottomLeftRadius: "10%",
  },
  closeButtonInside: {
    width: "80%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    selectors: {
      ":hover": {
        background: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
});

const ProjectDetailsContainer = ({ dates }: { dates: TDates }) => {
  // @ts-ignore
  const projectViewId = useSelector((state) => state.project.projectId) as
    | string
    | null;

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  // @ts-ignore
  const { data: projectData, refetchProject } = useQuery(
    ["project", projectViewId],
    () => dispatch(getProject(projectViewId)),
    {
      enabled: !!projectViewId,
    }
  ) as {
    data: TProject | null | undefined;
  };

  // const { data: allPlansData } = useQuery("plans", () =>
  //   dispatch(getPlans())
  // ) as {
  //   data: TPlan[];
  // };

  const mutationUpdateProject = useMutation(
    // @ts-ignore
    (project) => dispatch(updateProject(project)),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("projects");
        // queryClient.invalidateQueries("plans");
        queryClient.invalidateQueries({
          queryKey: ["project", projectViewId],
        });
        refetchProject();
      },
    }
  );

  const clearPlanOverlayProjectId = () => {
    dispatch(unviewProject());
  };

  // const updateNewProjectData = (key, value) => {
  //   const updatedProjectData = updateProjectData(
  //     projectData,
  //     { ...projectData, [key]: value },
  //     defaultData,
  //     currentUserId
  //   );
  //   mutationUpdateProject.mutate(updatedProjectData);
  // };

  // const plan: TPlan | null = useMemo(() => {
  //   if (allPlansData && allPlansData.length && projectData) {
  //     const projectPlans = allPlansData.filter(
  //       (el) => el.projectId === projectData.projectId
  //     );

  //     if (projectPlans.length) {
  //       return projectPlans[0];
  //     }
  //   }

  //   return null;
  // }, [allPlansData, projectData]);

  return (
    <Panel
      // To entirely disable the default dismiss behavior:
      // 1. Don't provide an onDismiss prop
      isOpen={!!projectViewId} // 2. Control the visibility
      hasCloseButton={false}
      customWidth="500px"
      styles={{
        main: {
          overflow: "visible",
          // minWidth: "50%",
          background: "#DFDFDF",
        },
      }}
    >
      <div
        onClick={() => {
          clearPlanOverlayProjectId();
        }}
        className={planClassNames.closeButtonOutside}
      >
        <div className={planClassNames.closeButtonInside}>
          <FontAwesomeIcon
            icon={faTimes as IconDefinition}
            style={{
              color: projectData ? `rgb(${projectData.color}` : "",
              fontSize: 30,
            }}
          />
        </div>
      </div>
      <div>
        {projectData && (
          <>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  marginRight: 12,
                  // maxWidth: "50%",
                }}
              >
                {projectData ? (
                  <ProjectDetails
                    onUpdateProject={(updatedProject) => {
                      // @ts-ignore
                      mutationUpdateProject.mutate(updatedProject);
                    }}
                    projectData={projectData}
                  />
                ) : (
                  <></>
                )}
              </div>
              {/* <div style={{ flex: 1, marginLeft: 12 }}>
                {plan ? (
                  <>
                    <ScreenSizeAndPosition>
                      <PictureAndCommentSection
                        projectId={projectData.projectId}
                        projectColor={projectData.color}
                        planData={plan}
                        overlayPlanIndex={0}
                      />
                    </ScreenSizeAndPosition>
                  </>
                ) : (
                  <></>
                )}
              </div> */}
            </div>

            {/* {planIsSuccess && planData &&
                  <UserSlots projectId={overlayProjectId} planData={planData} index={overlayPlanIndex} projectData={projectData}/>
              } */}
            {/* <div className={classNames.buttonBottomContainer}>
                  <DefaultButton text="REDIGER PLANEN" onClick={onClickToDetailPage} styles={defaultButtonStyles} />
              </div> */}

            {/* {plan ? <PlanReview data={plan?.machineRequirements[0]} /> : <></>} */}
          </>
        )}
      </div>
    </Panel>
  );
};

const classNames = mergeStyleSets({
  projectNameSection: {
    height: 144,
    width: "100%",
    borderRadius: "10px",
    marginBottom: 16,
  },
  responsibleAndDescriptionSection: {
    height: 144,
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#fff",
    marginBottom: 16,
  },
  locationSection: {
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  icon: {
    fontSize: 16,
  },
  iconClickable: {
    fontSize: 16,
    cursor: "pointer",
  },
  projectNameContainer: {
    fontSize: 20,
    fontWeight: 700,
    cursor: "pointer",
  },
});

interface ProjectDetailsProps {
  projectData?: TProject | null;
  onUpdateProject: (project: TProject) => void;
}

const ProjectDetails = ({
  projectData,
  onUpdateProject,
}: ProjectDetailsProps) => {
  const [colorPickerVisible, { toggle: togglePickerVisible }] =
    useBoolean(false);

  if (!projectData) {
    return <></>;
  }

  const updateNewProjectData = (field: string, val: any) => {
    const copy = JSON.parse(JSON.stringify(projectData)) as TProject;

    // @ts-ignore
    if (copy[field]) {
      // @ts-ignore
      copy[field] = val;
      onUpdateProject(copy);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={classNames.projectNameSection}
          style={{ backgroundColor: `rgb(${projectData.color})` }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 16,
                color: "#fff",
                height: 114,
                maxWidth: "80%",
              }}
            >
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: 14, fontWeight: 400 }}>
                    {/* @ts-ignore */}
                    {projectData.hovedsagsNummer}
                  </div>
                  <div>
                    {projectData.isQuotation && (
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar as IconDefinition}
                        className={classNames.icon}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={classNames.projectNameContainer}
                  // onClick={onClickToMachineRequirementPage}
                >
                  {projectData.projectName}
                </div>
                <div style={{ fontSize: 14, fontWeight: 400, marginTop: 5 }}>
                  {projectData.projectNo}
                </div>
              </div>
              {/* <div style={{ fontSize: 12, textTransform: "uppercase" }}>
                {machineData?.machineName
                  ? machineData?.machineName
                  : "Unknown Machine"}
                <FontAwesomeIcon
                  icon={faQrcode}
                  className={classNames.iconClickable}
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    openInNewTab(getMachineDocumentLink(machineData?.machineNo))
                  }
                />
              </div> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                padding: 16,
                color: "#fff",
                height: 114,
              }}
            >
              {projectData?.crmProjectId && (
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  href={crmLink(projectData.crmProjectId, projectData.category)}
                  rel="noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLink as IconDefinition}
                    onClick={() =>
                      openInNewTab(
                        crmLink(projectData.crmProjectId, projectData.category)
                      )
                    }
                    className={classNames.iconClickable}
                  />
                </a>
              )}
              <FontAwesomeIcon
                id="colorInput"
                onClick={togglePickerVisible}
                icon={faPalette as IconDefinition}
                className={classNames.iconClickable}
              />
            </div>
          </div>
        </div>
        <div className={classNames.responsibleAndDescriptionSection}>
          <div style={{ padding: 16, color: "#000", height: 112 }}>
            <div style={{ position: "relative", height: "100%" }}>
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginBottom: 10 }}>
                    <div
                      style={{ fontSize: 14, fontWeight: 700, marginBottom: 4 }}
                    >
                      Sagsansvarlig
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 400 }}>
                      {projectData?.personResponsbible?.name
                        ? projectData?.personResponsbible?.name
                        : "Unknown"}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{ fontSize: 14, fontWeight: 700, marginBottom: 4 }}
                    >
                      Beskrivelse
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        overflowWrap: "break-word",
                      }}
                    >
                      {projectData.projectDescription}
                    </div>
                  </div>
                </div>
              </ScrollablePane>
            </div>
          </div>
        </div>
        <div className={classNames.locationSection}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 16,
              color: "#000",
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 16 }}>
              Location
            </div>
            <div style={{ fontSize: 14, marginBottom: 16 }}>{`Adresse: ${
              projectData.address || "No address found!"
            }`}</div>
            <div style={{ fontSize: 14, marginBottom: 16 }}>{`Postnummer: ${
              projectData.postNummer || "Unknown"
            }`}</div>
            <div style={{ fontSize: 14, marginBottom: 16 }}>{`By: ${
              projectData.projectBy || "Unknown"
            }`}</div>
            <div>
              <MapAddressComponent
                height={225}
                width={"100%"}
                marker={{
                  lat: projectData.geoCoordinate?.latitude,
                  lng: projectData.geoCoordinate?.longitude,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {colorPickerVisible && (
        <TeachingBubble
          target="#colorInput"
          onDismiss={togglePickerVisible}
          styles={{
            content: {
              background: "white",
            },
          }}
        >
          {/* @ts-ignore  */}
          <ColorPickerComponent
            selectColor={(value: any) => updateNewProjectData("color", value)}
            color={projectData.color}
            togglePickerVisible={togglePickerVisible}
          />
        </TeachingBubble>
      )}
    </>
  );
};

export default ProjectDetailsContainer;
