import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { CALENDAR_STEP_WIDTH, CALENDAR_WEEKVIEW_STEP_WIDTH } from '../../../utils/constants';

const classNames = mergeStyleSets({
    month: {
        fontSize: 11,
        borderRadius: 10,
        fontWeight: 'bold',
        position: 'relative'
    },
    monthText: {
        marginLeft: 6,
    }
});

const MonthHeader = ({length, label = '', index, isWeekView = false, year, isVertical}) => {
    const widthHeightValue = isWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH * length : CALENDAR_STEP_WIDTH * length
    const widthOrHeight = isVertical ? {minHeight: widthHeightValue} : {minWidth: widthHeightValue}
    return (
        <div style={{height: 20, ...widthOrHeight }} className={classNames.month}>
            <div className={classNames.monthText} style={{writingMode: isVertical ? 'tb' : 'unset'}}>
                
                <div style={{marginTop: -5}}>{year}</div>
                <div>{ length > 10 ? label : '' }</div>
            </div>
            {index !== 0 &&<div style={{position: 'absolute', top: 0, borderLeft: '2px solid #868685', height: '100%', width: '20px' }}/>}
        </div>
    )
}

export default MonthHeader;
