// Import libraries
import axios from "axios";
import { MessageBarType } from "@fluentui/react";

// Import actions
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";

// Import utils
import setDefaults from "../../utils/setDefaults";
import { SOURCE_API } from "../../utils/constants";
import { ProjectActionTypes } from "./project.types";

export const viewProject = (projectId) => {
  return {
    type: ProjectActionTypes.VIEW_PROJECT,
    payload: {
      projectId,
    },
  };
};

export const unviewProject = () => {
  return {
    type: ProjectActionTypes.UNVIEW_PROJECT,
    payload: null,
  };
};

export const getProjects = (from, to) => async (dispatch) => {
  try {
    let uri = `${SOURCE_API}/api/Project/getall`;
    if (from && to) {
      uri += `?from=${from}&to=${to}`;
    }
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(uri);
    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getProject = (id) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/Project/get?projectIds=${id}`
    );
    return response.data.data[0] ? response.data.data[0] : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createProject = (projectData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(`${SOURCE_API}/api/Project`, projectData);
    if (response.data.succeeded === true) {
      dispatch(
        saveMessage("Project sucessfully created!", MessageBarType.success)
      );
    } else {
      dispatch(saveMessage(response.data?.message || "There was an error"));
    }
    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updateProject = (projectData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.put(`${SOURCE_API}/api/Project`, projectData);
    if (response.data.succeeded === true) {
      dispatch(
        saveMessage("Project sucessfully updated!", MessageBarType.success)
      );
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const deleteProject = (projectId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.delete(
      `${SOURCE_API}/api/Project?projectId=${projectId}`
    );

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Project deleted", MessageBarType.success));
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return true;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};
