// import libraries
import { useState, useEffect, useCallback } from 'react';
import { Rnd } from 'react-rnd';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { TeachingBubble, mergeStyleSets } from '@fluentui/react';
import { DraggableData } from "react-rnd";
import { Position } from "react-rnd";
import { ResizableDelta } from "react-rnd";
import { useBoolean } from '@fluentui/react-hooks';

// Import types
import { THorizontalPositionMap } from '../../types/positionmap';
import { TEffort } from '../../types/effort';

// Import utils
import { CALENDAR_STEP_WIDTH, CALENDAR_WEEKVIEW_STEP_WIDTH, calendarConfirmationStatus, hasEditRight } from '../../utils/constants';

// Import action
import { deleteProjectEffort, updateProjectEffort } from '../../redux/effort/effort.action';

// Import component
import AllocationBubble from './AllocationBubble';
import moment from 'moment';
import { TCalendar } from '../../types/calendar';
import { checkOverlap } from '../../utils/effort';
import { StaffVacation } from '../../types/staff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareEllipsisVertical, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import DeleteConfirmation from './DeleteConfirmation';
import { saveMessage } from '../../redux/message/message.actions';

const classNames = mergeStyleSets({
    allocation: {
        color: 'white',
        overflow: 'hidden',
        fontFamily: 'Verdana',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 11,
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        height: '100%',
        alignItems: 'center',
        paddingRight: 8
    },
    icon: {
        color: '#fff',
        marginRight: 2,
        fontSize: 13,
        fontWeight: 'lighter',
        selectors: {
            ':hover': {
                color: '#DBDBDB',
            },
        },
        cursor: 'pointer',
        paddingRight: 8
    },
});

type EffortBarProps = {
    allocation: number,
    start: string,
    end: string,
    horizontalPositionMap: THorizontalPositionMap,
    color: string,
    calendarStepHeight: number,
    userId: string,
    yPosition: number,
    calendar: TCalendar,
    efforts: TEffort[],
    staffVacations: StaffVacation[],
    effortId: string,
    projectId: string
};

const EffortBar = ({
    allocation,
    end,
    horizontalPositionMap,
    start,
    color,
    calendarStepHeight,
    userId,
    yPosition,
    calendar,
    efforts,
    staffVacations,
    effortId,
    projectId
}: EffortBarProps) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const id = `Id-allocation-${effortId}`;

    // Mutations
    const { mutate: updateEffortMutation } = useMutation<any, Error, TEffort>((effort) => (updateProjectEffort({
        updateData: effort
    }))(dispatch), {
        onSuccess: (data) => {
            // if (data) {
            // rollback()
            // }
        },
        onError: (error, variables) => {
            // rollback()
            reset();
        },
        onSettled: () => {
            queryClient.invalidateQueries('staffs');
            queryClient.invalidateQueries('plans');
        }
    });

    const { mutate: deleteEffortMutation } = useMutation<any, Error, TEffort>((effort) => (deleteProjectEffort({
        deleteData: effort
    }))(dispatch), {
        onSuccess: (data) => {
            // if (data) {
            // rollback()
            // }
            queryClient.invalidateQueries('staffs');
            queryClient.invalidateQueries('plans');
        },
        onError: (error, variables) => {
            // rollback()
        },
        onSettled: () => {

        }
    })


    // Use Selector
    // @ts-ignore
    const currentUserId = useSelector((state) => state.user.user.userId);
    // @ts-ignore
    const userRoles = useSelector((state) => state.user.user.workingRole);
    let allowEdit = hasEditRight(userRoles);

    if (userId === currentUserId) {
        allowEdit = true;
    }

    // @ts-ignore
    const { displayWeekView } = useSelector((state) => state.machine);

    const calendarStepWidth = displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH;

    // Use Booleans
    const [teachingBubbleStaffVacation, { toggle: toggleTeachingStaffVacation }] = useBoolean(false);
    const [openActions, { toggle: toggleOpenActions }] = useBoolean(false);

    // flag variables
    const [canDisplay, setCanDisplay] = useState<boolean>();

    const [tempAllocation, setTempAllocation] = useState<number>();
    const [width, setWidth] = useState<number>(250);
    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const [confirmation, setConfirmation] = useState(calendarConfirmationStatus.CONFIRMATION_UNSET);
    const needConfirmation = confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
    const [isEditing, setIsEditing] = useState(false);

    // useEffects
    useEffect(() => {
        setTempAllocation(allocation);
    }, [allocation]);

    useEffect(() => {
        setY(yPosition);
    }, [yPosition])

    const reset = useCallback(() => {
        const keyStart = moment(start).utcOffset(0).format('YYYY-M-D');
        const keyEnd = moment(end).add(1, 'd').utcOffset(0).format('YYYY-M-D');

        let xPosition = horizontalPositionMap[keyStart] as number;
        let endValue = horizontalPositionMap[keyEnd] as number;

        if (
            new Date(start.slice(0, -1))?.getTime() <= calendar.start?.getTime() &&
            new Date(end.slice(0, -1))?.getTime() >= calendar.end?.getTime() &&
            typeof xPosition === 'undefined' &&
            typeof endValue === 'undefined'
        ) {
            xPosition = 0;
            endValue = horizontalPositionMap['end'];
        }

        if (new Date(start.slice(0, -1))?.getTime() <= calendar.start?.getTime() && typeof endValue !== 'undefined') {
            xPosition = 0;
        }

        if (new Date(end.slice(0, -1))?.getTime() >= calendar.end?.getTime()) {
            endValue = horizontalPositionMap['end'];
        }

        if (typeof xPosition !== 'undefined') {
            setX(xPosition * calendarStepWidth);
            setWidth((endValue - xPosition) * calendarStepWidth);
            setCanDisplay(true);
        } else {
            setCanDisplay(false);
        }
    }, [start, end, horizontalPositionMap, calendar, userId, calendarStepWidth])

    useEffect(() => {
        reset();
    }, [reset]);

    const onDragStop = (e: React.MouseEvent<HTMLElement | SVGElement>
        | React.TouchEvent<HTMLElement | SVGElement>
        | MouseEvent
        | TouchEvent, d: DraggableData) => {
        setIsEditing(false);

        const prevX = x;
        const prevY = y;

        if ((d.lastX === prevX && d.lastY === prevY) || d.lastY !== prevY) {
            return;
        }

        const newStartNum = Math.floor(d.lastX / calendarStepWidth);
        const newEndNum = newStartNum + Math.floor(width / calendarStepWidth)

        const startDateStr = horizontalPositionMap['positionsToDates'][newStartNum];
        const endDateStr = horizontalPositionMap['positionsToDates'][newEndNum - 1];

        if (checkOverlap({
            start: startDateStr,
            end: endDateStr,
            efforts,
            staffVacations,
            effortId,
            effortProjectId: projectId
        })) {
            dispatch(saveMessage('Cannot Overlap'));
            e.stopPropagation();
            return;
        }
        // if (isOverlap(d.lastX, width)) {
        //     return;
        // }

        setX(d.lastX);
        // setY(d.lastY);
        setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
    }

    const onClickConfirmDelete = () => {
        deleteEffortMutation({
            userId,
            end,
            start,
            percentage: allocation,
            projectId,
            id: effortId
        });

        toggleTeachingStaffVacation();
    }

    const onClickSave = (perc: number) => {
        if (width) {
            const newStartNum = Math.floor(x / calendarStepWidth);
            const newEndNum = newStartNum + Math.floor(width / calendarStepWidth)

            const startDateStr = horizontalPositionMap['positionsToDates'][newStartNum];
            const endDateStr = horizontalPositionMap['positionsToDates'][newEndNum - 1];

            const saveData: TEffort = {
                percentage: perc,
                projectId,
                userId,
                end: endDateStr,
                start: startDateStr,
                id: effortId
            }

            updateEffortMutation(saveData);
        }
    }

    const onResizeStop = (e: MouseEvent | TouchEvent, dir: 'top' | 'right' | 'bottom' | 'left' | 'topRight' | 'bottomRight' | 'bottomLeft' | 'topLeft', ref: HTMLElement, delta: ResizableDelta, position: Position) => {
        setIsEditing(false);
        const prevX = x;
        const prevW = width;
        const newX = parseInt(position.x.toFixed(0));
        let newWidth = ref.offsetWidth;
        // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
        if (newWidth % calendarStepWidth !== 0) {
            // @ts-ignore
            newWidth = (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
        }
        if (newX === prevX && newWidth === prevW) {
            return;
        }

        const newStartNum = Math.floor(newX / calendarStepWidth);
        const newEndNum = newStartNum + Math.floor(newWidth / calendarStepWidth)

        const startDateStr = horizontalPositionMap['positionsToDates'][newStartNum];
        const endDateStr = horizontalPositionMap['positionsToDates'][newEndNum - 1];

        if (checkOverlap({
            efforts,
            staffVacations,
            effortId,
            start: startDateStr,
            end: endDateStr,
            effortProjectId: projectId
        })) {
            dispatch(saveMessage('Cannot Overlap'));
            e.stopPropagation();
            return;
        }

        // if (isOverlap(newX, newWidth)) {
        //     rollback({ previousY: y, previousW: prevW });
        //     return;
        // }
        setX(newX);
        setWidth(newWidth);
        setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
    }

    return (
        <>
            {
                (width && canDisplay) ? <><Rnd
                    style={{ zIndex: 3, top: 0, left: 0, display: 'absolute', borderRadius: 4 }}
                    size={{ width, height: calendarStepHeight }}
                    position={{
                        x,
                        y
                    }}
                    enableResizing={{ left: allowEdit && !needConfirmation, right: allowEdit && !needConfirmation }}
                    resizeGrid={[calendarStepWidth, calendarStepHeight]}
                    dragGrid={[calendarStepWidth, calendarStepHeight]}
                    dragAxis='x'
                    onDragStop={onDragStop}
                    onResizeStop={onResizeStop}
                    // disableDragging={isDrawing}
                    bounds='.planContainer'
                    dragHandleClassName='dragHandle'
                    onDrag={() => {
                        if (!isEditing) {
                            setIsEditing(true);
                        }
                    }}
                    onResize={() => {
                        if (!isEditing) {
                            setIsEditing(true);
                        }
                    }}
                >
                    <div
                        id={id}
                        style={{
                            display: 'flex',
                            height: calendarStepHeight - 8,
                            borderRadius: 4,
                            backgroundColor: `rgb(${color})`,
                            marginTop: 4
                        }}
                        className="dragHandle"
                    >
                        {
                            confirmation === calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED ?
                                <AllocationBubble
                                    targetElemId={id}
                                    onConfirm={(allocation) => {
                                        if (allowEdit) {
                                            onClickSave(allocation)
                                            setTempAllocation(allocation);
                                            setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
                                        }
                                        else {
                                            dispatch(saveMessage('You are not allowed to edit the allocation.'));
                                        }
                                    }}
                                    onCancel={() => {
                                        reset();
                                        setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
                                    }}
                                    value={tempAllocation}
                                />
                                :
                                <></>
                        }
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '100%',
                            // cursor: 'pointer',
                            cursor: allowEdit ? `url(${window.location.origin}/img/arrows-alt.svg), auto` : 'default',
                        }}
                            onClick={() => {
                                if (confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET && !isEditing) {
                                    setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED)
                                }
                            }}
                        >
                            <div className={classNames.allocation}>
                                {`${allocation}%`}
                            </div>
                            {allowEdit && (
                                <FontAwesomeIcon
                                    id={`staffVacationAction-${id}`}
                                    icon={faSquareEllipsisVertical as IconDefinition}
                                    style={{ marginRight: width > calendarStepWidth * 2 ? 5 : 4, fontSize: 13 }}
                                    className={classNames.icon}
                                    onClick={(e) => {
                                        toggleOpenActions();
                                        e.stopPropagation();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Rnd>
                    {openActions && (
                        <>
                            <TeachingBubble
                                target={`#staffVacationAction-${id}`}
                                onDismiss={toggleOpenActions}
                                styles={{
                                    subText: { color: 'black' },
                                    subComponentStyles: {
                                        callout: {
                                            root: {
                                                maxWidth: 110,
                                            },
                                        },
                                    },
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', height: 25, justifyContent: 'space-around' }}>
                                    {/* {hasEditRight(userRoles) && (
                                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}>
                                            <FontAwesomeIcon
                                                icon={faSquare as IconDefinition}
                                                style={{ marginRight: 4, fontSize: 13, color: '#000' }}
                                                className={classNames.icon}
                                            />
                                            <span style={{ fontSize: 11 }}>Godkendt</span>
                                        </div>
                                    )} */}
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}
                                        id={`deleteVacation-${userId}`}
                                        onClick={() => {
                                            toggleTeachingStaffVacation();
                                            toggleOpenActions();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt as IconDefinition} style={{ marginRight: 4, fontSize: 13, color: '#000' }} className={classNames.icon} />
                                        <span style={{ fontSize: 11 }}>Slet</span>
                                    </div>
                                </div>
                            </TeachingBubble>
                        </>
                    )}
                    {
                        teachingBubbleStaffVacation &&
                        <DeleteConfirmation
                            target={`#staffVacationAction-${id}`}
                            toggleTeaching={toggleTeachingStaffVacation}
                            onClickConfirm={() => {
                                onClickConfirmDelete();
                            }}
                        />
                    }
                </> : <></>
            }
        </>
    )
}

export default EffortBar