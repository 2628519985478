// Import libraries
import { useState, useMemo } from 'react';
import { Persona, PersonaSize, ScrollablePane, ScrollbarVisibility, SearchBox, Stack, TeachingBubble, mergeStyleSets } from '@fluentui/react';

// Import types
import { TStaff } from '../../types/staff';
import { SOURCE_API, stationOptions, workingRoleOptions } from '../../utils/constants';

const classNames = mergeStyleSets({
    Persona: {
        padding: "5px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    station: {
        margin: 5,
        width: 25,
        height: 14,
        color: "#868685",
        background: "#DFDFDF",
        textAlign: "center",
        fontSize: 11,
        lineHeight: 14,
        borderRadius: 4,
        padding: 2,
    },
    favoriteDriver: {
        position: "absolute",
        marginLeft: "200px",
    },
});

export const searchBoxStyles = {
    root: {
        border: 'none',
        backgroundColor: '#FFFFFF',
        margin: '-10px -10px 0 -5px',
        height: '56px',
        ':after': {
            border: 'none',
        },
    },
    iconContainer: {
        display: 'none',
    },
    clearButton: {
        marginLeft: '-50px',
    },
};

type PopupStaffProps = {
    onDismiss?: ((ev?: any) => void) | undefined,
    targetElemId: string,
    staffsData: TStaff[],
    onSelectStaff: (selectedStaff: {
        techDepartment: number;
        techArea: number;
        firstName: string;
        lastName: string;
        role: string;
        userId: string;
        imageUrl: string;
    }) => void
}

const PopupStaff = ({ onDismiss, targetElemId, staffsData, onSelectStaff }: PopupStaffProps) => {

    const [searchValue, setSearchValue] = useState<string | undefined>();

    const displayList: {
        techDepartment: number;
        techArea: number;
        firstName: string;
        lastName: string;
        role: string;
        userId: string;
        imageUrl: string;
    }[] = useMemo(() => {
        const arr = staffsData.filter((staffData) => {
            return searchValue ? `${staffData.firstName} ${staffData.lastName}`.toLowerCase().includes(searchValue.toLowerCase()) : true
        })
            .map((staffData) => {
                const { techDepartment, techArea, firstName, lastName, workingRole, userId, imageUrl } = staffData;

                const roleArr = workingRole.split(';');

                let roleText: string = '';

                workingRoleOptions.forEach(({ key, text }) => {
                    if (roleArr.find((val) => val === key + '')) {
                        roleText = text;
                    }
                });

                return {
                    techDepartment,
                    techArea,
                    firstName,
                    lastName,
                    role: roleText,
                    userId,
                    imageUrl
                }
            });

        return arr;
    }, [searchValue, staffsData]);

    return (
        <TeachingBubble target={`#${targetElemId}`} hasSmallHeadline={true} onDismiss={onDismiss} closeButtonAriaLabel='Close'>
            <SearchBox
                styles={searchBoxStyles}
                placeholder='Søg'
                clearButtonProps={{
                    style: {
                        backgroundColor: '#FFFFFF',
                        marginLeft: '20px',
                    },
                }}
                value={searchValue}
                onChange={(e, value) => setSearchValue(value)}
            />
            <hr style={{ width: '145%', marginLeft: '-50px', marginTop: '-2px', opacity: 0.5 }} />
            <div style={{ height: 200, position: 'relative', backgroundColor: 'white', display: 'flex', flexDirection: 'row' }}>
                <ScrollablePane style={{ height: 200 }} scrollbarVisibility={ScrollbarVisibility.auto}>
                    <Stack
                        // vertical 
                        tokens={{ childrenGap: 10 }}
                    >
                        {displayList &&
                            displayList.map((user) => (
                                <div
                                    key={`worker-${user.userId}`}
                                    className={classNames.Persona}
                                    onClick={() => {
                                        onSelectStaff(user);
                                        onDismiss && onDismiss();
                                    }}
                                >
                                    {' '}
                                    <Persona
                                        text={`${user?.firstName} ${user?.lastName}`}
                                        secondaryText={user.role}
                                        size={PersonaSize.size40}
                                        imageUrl={user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ''}
                                    />
                                    <div className={classNames.station}>
                                        {/* @ts-ignore */}
                                        {stationOptions[user.techDepartment]?.options.find((option) => option.key === user.techArea)?.shortcutText ?? ''}
                                    </div>
                                </div>
                            ))}
                    </Stack>
                </ScrollablePane>
            </div>
        </TeachingBubble>
    )
}

export default PopupStaff