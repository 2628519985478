// Import libraries
import axios, { AxiosResponse } from 'axios';
import { MessageBarType } from '@fluentui/react';

// Import actions
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from '../message/message.actions';

// Import utils
import setDefaults from '../../utils/setDefaults';

// Import constants
import { SOURCE_API } from '../../utils/constants';

// Import types
import { TEffort } from '../../types/effort';

export const getEffortsByUser = (userId: string) => async (dispatch: any) => {
    try {
        let uri = `${SOURCE_API}/api/User/GetEffortsByUserId/${userId}`;

        setDefaults(localStorage.getItem('token'));

        const response = await axios.get<AxiosResponse<TEffort[]>>(uri);

        return response.data.data;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
}

export const getEffortsByProject = (projectId: string) => async (dispatch: any) => {
    try {
        let uri = `${SOURCE_API}/api/User/GetEffortsByProjectId/${projectId}`;

        setDefaults(localStorage.getItem('token'));

        const response = await axios.get<AxiosResponse<TEffort[]>>(uri);

        return response.data.data;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
}

export const updateProjectEffort = ({ updateData }: { updateData: TEffort }) => async (dispatch: any) => {
    try {
        let uri = `${SOURCE_API}/api/User/UpdateEffort`;

        setDefaults(localStorage.getItem('token'));

        const response = await axios.post(uri, updateData);

        dispatch(saveMessage("Allocation successfully updated!", MessageBarType.success));

        return response.data.value.succeeded;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
}

export const deleteProjectEffort = ({ deleteData }: { deleteData: TEffort }) => async (dispatch: any) => {
    try {
        let uri = `${SOURCE_API}/api/User/DeleteEffort`;

        setDefaults(localStorage.getItem('token'));

        await axios.delete<AxiosResponse<TEffort>>(uri, {
            data: deleteData
        });

        // return response.data.data;

        dispatch(saveMessage("Allocation successfully delete!", MessageBarType.success));

        return true;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
}