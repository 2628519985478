// Import libraries
import React, { ReactElement } from "react"


// Import types
import { TWeeks } from "../../types/dates"
import { CALENDAR_STEP_WIDTH } from "../../utils/constants"

// The purpose of this component is to make a reusable component whose
// purpose is to show date grid lines and weekend greyed out grids
type CalendarBackgroundProps = {
    totalHeight: number
    calendarStartFrom: string
    displayWeekView: boolean,
    dates: TWeeks[],
    children: ReactElement,
    calendarStepWidth: number
}

const CalendarBackground = ({ calendarStartFrom, dates, displayWeekView, totalHeight, calendarStepWidth, children }: CalendarBackgroundProps) => {
    return (
        <div
            style={{
                minHeight: totalHeight,
                width: calendarStepWidth * 7 * dates.length - 24,
                position: 'absolute',
                backgroundImage: displayWeekView ? 'url("./img/calendarBackground5.png")' : 'url("./img/calendarBackground3.png")',
                backgroundRepeat: 'repeat',
                backgroundPositionX:
                    calendarStartFrom === 'month' && !displayWeekView && Array.isArray(dates) && dates[0]?.length < 7
                        ? dates[0].length * CALENDAR_STEP_WIDTH
                        : 0,
            }}
        >
            {children}
        </div>
    )
}

export default CalendarBackground