// Import libraries
import { mergeStyleSets } from "@fluentui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faHelmetSafety,
  faInfoCircle,
  faPencil,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useState, useMemo } from "react";

// Import redux
import { setDrawUserEffort } from "../../redux/app/app.actions";
import { saveMessage } from "../../redux/message/message.actions";

// Import components
import PopupStaff from "./PopupStaff";

// Import types
import { TStaff } from "../../types/staff";
import { HEADER_HEIGHT, ITEM_HEIGHT } from "../../utils/constants";
import { TooltipForText } from "../common";
import { truncateText } from "../../utils/string";
import { viewProject } from "../../redux/project/project.actions";
import { TProject } from "../../types/project";

const classNames = mergeStyleSets({
  peopleNameContainer: {
    height: HEADER_HEIGHT,
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    alignContent: "center",
    gap: 10,
  },
  icon: {
    fontSize: 16,
    color: "#006CAD",
    cursor: "pointer",
  },
  projectNameContainer: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: ITEM_HEIGHT,
    borderBottom: "1px solid #DBDBDB",
    boxSizing: "border-box",
  },
  addContainer: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: ITEM_HEIGHT,
    borderBottom: "1px solid #DBDBDB",
    boxSizing: "border-box",
  },
});

export type TUserList = {
  userName: string;
  userId: string;
}[];

type ProjectListProps = {
  users: {
    userId: string;
    userName: string;
    yPosition: number;
    isEdit?: boolean;
  }[];
  staffsList: TStaff[];
  onClickAddUser: ({
    selectedStaff,
    projectId,
  }: {
    selectedStaff: {
      techDepartment: number;
      techArea: number;
      firstName: string;
      lastName: string;
      role: string;
      userId: string;
      imageUrl: string;
    };
    projectId: string;
  }) => void;
  selectedUserId?: string;
  projectData: TProject;
};

const ProjectList = ({
  users,
  staffsList,
  onClickAddUser,
  selectedUserId,
  projectData,
}: ProjectListProps) => {
  const dispatch = useDispatch();

  const id = `staff-add-people-${projectData.projectId}`;

  const [showAdd, setShowAdd] = useState<boolean>();

  const onDismissShowAdd = () => {
    setShowAdd(false);
  };

  const onProjectSelected = (selectedStaff: {
    techDepartment: number;
    techArea: number;
    firstName: string;
    lastName: string;
    role: string;
    userId: string;
    imageUrl: string;
  }) => {
    const { userId: selectedUserId } = selectedStaff;

    const existingProject = users.find(
      ({ userId }) => userId === selectedUserId
    );

    if (existingProject) {
      dispatch(saveMessage("This user has already added to this project"));

      return;
    }

    onClickAddUser({ selectedStaff, projectId: projectData.projectId });
  };

  const style: React.CSSProperties = useMemo(() => {
    const styleObj: React.CSSProperties = {};

    if (users.length === 0) {
      styleObj.borderBottom = "1px solid #DBDBDB";
    }

    return styleObj;
  }, [users]);

  const userStyle: React.CSSProperties = useMemo(() => {
    const styleObj: React.CSSProperties = {};

    if (selectedUserId) {
      styleObj.backgroundColor = "rgb(153,179,255)";
    }

    return styleObj;
  }, [selectedUserId]);

  return (
    <div>
      <div style={style} className={classNames.peopleNameContainer}>
        <div>
          <p style={{ margin: 0 }}>{projectData.projectName}</p>
        </div>
        <div>
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={faInfoCircle as IconDefinition}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(viewProject(projectData.projectId));
            }}
          />
        </div>
      </div>
      {users.map(({ userId, userName, isEdit }) => (
        <div
          key={userId}
          className={classNames.projectNameContainer}
          style={selectedUserId === userId ? userStyle : {}}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
            }}
          >
            <FontAwesomeIcon
              icon={faHelmetSafety as IconDefinition}
              style={{ marginRight: 0, cursor: "none" }}
              className={classNames.icon}
            />
            <TooltipForText
              zIndex=""
              text={userName.length > 20 ? userName : ""}
            >
              <span>
                {truncateText({
                  maxLength: 20,
                  text: userName,
                })}
              </span>
            </TooltipForText>
          </div>
          <FontAwesomeIcon
            icon={faPencil as IconDefinition}
            className={classNames.icon}
            onClick={() => {
              if (!isEdit) {
                dispatch(
                  setDrawUserEffort({
                    userId,
                    projectId: projectData.projectId,
                  })
                );
              }
            }}
          />
        </div>
      ))}
      <div className={classNames.addContainer}>
        <FontAwesomeIcon
          icon={faPlus as IconDefinition}
          className={classNames.icon}
          onClick={() => {
            setShowAdd(true);
          }}
          id={id}
        />
      </div>
      {showAdd && (
        <PopupStaff
          onSelectStaff={(selectedStaff) => {
            onProjectSelected(selectedStaff);
          }}
          targetElemId={id}
          onDismiss={onDismissShowAdd}
          staffsData={staffsList}
        />
      )}
    </div>
  );
};

export default ProjectList;
