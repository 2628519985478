// Import types
import moment from "moment";
import { TEffort } from "../types/effort";
import { StaffVacation } from "../types/staff";

export const checkOverlap = ({
  end,
  start,
  staffVacations,
  efforts,
  effortId,
  vacationIndex,
  effortProjectId,
  isFerie = false,
}: {
  staffVacations: StaffVacation[];
  efforts: TEffort[];
  start: string;
  end: string;
  effortId?: string;
  vacationIndex?: number;
  effortProjectId?: string;
  isFerie?: boolean;
}) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  let isOverlap: boolean = false;

  if (staffVacations && isFerie) {
    const { length: vacationLength } = staffVacations;

    for (let i = 0; i < vacationLength && !isOverlap; i++) {
      const { start, end } = staffVacations[i];

      const staffVacationStartDate = moment(new Date(start))
        .startOf("day")
        .toDate();
      const staffVacationEndDate = moment(new Date(end))
        .startOf("day")
        .toDate();

      if (vacationIndex !== i) {
        if (
          (startDate <= staffVacationEndDate &&
            startDate >= staffVacationStartDate) ||
          (endDate <= staffVacationEndDate &&
            endDate >= staffVacationStartDate) ||
          (staffVacationStartDate >= startDate &&
            staffVacationEndDate <= endDate) ||
          (startDate >= staffVacationStartDate &&
            startDate <= staffVacationEndDate) ||
          (endDate >= staffVacationStartDate && endDate <= staffVacationEndDate)
        ) {
          isOverlap = true;
        }
      }
    }
  }

  if (!isFerie) {
    const { length: effortLength } = efforts;

    for (let i = 0; i < effortLength && !isOverlap; i++) {
      const { start, end, id, projectId } = efforts[i];

      const effortStartDate = moment(new Date(start)).startOf("day").toDate();
      const effortEndDate = moment(new Date(end)).startOf("day").toDate();

      const currentEffortId = id;

      if (currentEffortId !== effortId) {
        if (
          effortProjectId === projectId &&
          // Check if the dates overlap
          ((startDate <= effortEndDate && startDate >= effortStartDate) ||
            (endDate <= effortEndDate && endDate >= effortStartDate) ||
            (effortStartDate >= startDate && effortStartDate <= endDate) ||
            (startDate >= effortStartDate && startDate <= effortEndDate) ||
            (endDate >= effortStartDate && endDate <= effortEndDate))

          // Check if it is the same project
          // This is because we allow allocations for different project during same time
        ) {
          isOverlap = true;
        }
      }
    }
  }

  return isOverlap;
};
