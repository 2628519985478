import { MessageActionTypes } from './message.types';
import { MessageBarType } from '@fluentui/react';

// RETURN MESSAGES
export const saveMessage = (message, type = MessageBarType.error) => {
  return {
    type: MessageActionTypes.ADD_MESSAGE,
    payload: { message, type }
  };
};

// CLEAR ERRORS
export const clearMessage = () => {
  return {
    type: MessageActionTypes.CLEAR_MESSAGE
  };
};

// RETURN CONFLICT MESSAGE
export const showConflictMessage = (conflictMessage, type = MessageBarType.error) => {
  return {
    type: MessageActionTypes.ADD_CONFLICT_MESSAGE,
    payload: { conflictMessage, type }
  };
};

// CLEAR CONFLICT MESSAGES
export const clearConflictMessage = () => {
  return {
    type: MessageActionTypes.CLEAR_CONFLICT_MESSAGE
  };
};