// Import libraries
import { useState, useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useBoolean } from '@fluentui/react-hooks';

// Import types
import { useDispatch, useSelector } from 'react-redux'
import { TCalendar } from '../../types/calendar'
import { THorizontalPositionMap } from '../../types/positionmap'
import { StaffVacation, TStaff } from '../../types/staff'


// Import constants
import { CALENDAR_STEP_WIDTH, CALENDAR_WEEKVIEW_STEP_WIDTH, calendarConfirmationStatus, hasEditRight, reasonVacationStaffOptions } from '../../utils/constants'
import { TeachingBubble, mergeStyleSets } from '@fluentui/react';
import { updateStaff } from '../../redux/user/user.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck, faSquareEllipsisVertical, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import ConfirmBubble from './ConfirmBubble';
import TooltipForText from './TooltipForText';
import { Rnd } from 'react-rnd';
import DeleteConfirmation from './DeleteConfirmation';
import moment from 'moment';
import { TEffort } from '../../types/effort';
import { checkOverlap } from '../../utils/effort';
import { saveMessage } from '../../redux/message/message.actions';

type FerieBarProps = {
    horizontalPositionMap: THorizontalPositionMap,
    staffVacation: StaffVacation,
    calendar: TCalendar,
    yPosition: number,
    userId: string,
    start: string,
    end: string,
    calendarStepHeight: number,
    staffVacationIndex: number,
    staff: TStaff,
    height: number,
    staffVacations: StaffVacation[],
    efforts: TEffort[],
    id: string,
    disabled?: boolean
}

const FerieBar = ({
    horizontalPositionMap,
    staffVacationIndex,
    staffVacation,
    calendar,
    calendarStepHeight,
    end,
    start,
    userId,
    yPosition,
    staff,
    height,
    efforts,
    staffVacations,
    id,
    disabled = false
}: FerieBarProps) => {
    const queryClient = useQueryClient();

    const dispatch = useDispatch();

    const { isApproved } = staffVacation;

    // @ts-ignore
    const userRoles = useSelector((state) => state.user.user.workingRole);
    let allowEdit = hasEditRight(userRoles);
    // @ts-ignore
    const currentUserId = useSelector((state) => state.user.user.userId);

    if (userId === currentUserId) {
        allowEdit = true;
    }

    // @ts-ignore
    const { displayWeekView } = useSelector((state) => state.machine);
    const calendarStepWidth = displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH;

    // useBoolean
    const [teachingBubbleStaffVacation, { toggle: toggleTeachingStaffVacation }] = useBoolean(false);
    const [openActions, { toggle: toggleOpenActions }] = useBoolean(false);

    // useStates
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [width, setWidth] = useState(250);
    const [canDisplay, setCanDisplay] = useState(false);
    const [confirmation, setConfirmation] = useState(calendarConfirmationStatus.CONFIRMATION_UNSET);
    const needConfirmation = confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
    const colorCode = '0,108,173';
    let lighterColorCode = '51,137,189';

    // useEffects
    useEffect(() => {
        setY(yPosition);
    }, [yPosition]);

    if (isApproved) lighterColorCode = colorCode;

    let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterColorCode}) 24px, rgb(${lighterColorCode}) 48px)`;

    const classNames = mergeStyleSets({
        icon: {
            color: '#fff',
            marginRight: 2,
            fontSize: 13,
            fontWeight: 'lighter',
            selectors: {
                ':hover': {
                    color: '#DBDBDB',
                },
            },
            cursor: 'pointer',
        },
        iconBlack: {
            color: '#000',
        },
        draggable: {
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        barContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 'calc(100% - 15px)',
            height: '100%',
            cursor: allowEdit ? `url(${window.location.origin}/img/arrows-alt.svg), auto` : 'default',
        },
    });

    const reset = useCallback(() => {
        const keyStart = moment(start).utcOffset(0).format('YYYY-M-D');
        const keyEnd = moment(end).add(1, 'd').utcOffset(0).format('YYYY-M-D');

        let xPosition = horizontalPositionMap[keyStart] as number;
        let endValue = horizontalPositionMap[keyEnd] as number;

        if (
            new Date(start.slice(0, -1))?.getTime() <= calendar.start?.getTime() &&
            new Date(end.slice(0, -1))?.getTime() >= calendar.end?.getTime() &&
            typeof xPosition === 'undefined' &&
            typeof endValue === 'undefined'
        ) {
            xPosition = 0;
            endValue = horizontalPositionMap['end'];
        }

        if (new Date(start.slice(0, -1))?.getTime() <= calendar.start?.getTime() && typeof endValue !== 'undefined') {
            xPosition = 0;
        }

        if (new Date(end.slice(0, -1))?.getTime() >= calendar.end?.getTime()) {
            endValue = horizontalPositionMap['end'];
        }
        setY(yPosition);

        if (typeof xPosition !== 'undefined') {
            setX(xPosition * calendarStepWidth);
            setWidth((endValue - xPosition) * calendarStepWidth);
            setCanDisplay(true);
        } else {
            setCanDisplay(false);
        }

        setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET)
    }, [start, end, horizontalPositionMap, calendar, staff, userId, yPosition, calendarStepWidth])

    const { mutate: mutateUpdateStaff } = useMutation<any, Error, {
        data: TStaff
    }>((staff) => updateStaff(staff.data)(dispatch), {
        onSuccess: (data, variables) => {
            // if (data !== true) {
            //     rollback(variables.rollbackData);
            // }
            queryClient.invalidateQueries('staffs');
            queryClient.invalidateQueries('plans');
        },
        onError: (data, variables) => {
            // setUpdatePackage(null);
            // rollback(variables.rollbackData);
            reset();
        },
        onSettled: () => {
            queryClient.invalidateQueries('staffs');
            queryClient.invalidateQueries('plans');
        },
    });


    useEffect(() => {
        // if (confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET && ((!x && !y) || shouldComponentRerender)) {
        reset()
        // }
    }, [reset]);

    const text = reasonVacationStaffOptions[staffVacation['reason']]['text'];

    const onClickConfirmDelete = (staffVacationIndex: number) => {
        const staffData = { ...staff };
        staffData.starfVacations.splice(staffVacationIndex, 1);
        toggleTeachingStaffVacation();
        mutateUpdateStaff({ data: staffData });
    };

    const onClickSave = async () => {
        const start = horizontalPositionMap['positionsToDates'][Math.floor(x / calendarStepWidth)];
        const end = horizontalPositionMap['positionsToDates'][Math.floor((x + width) / calendarStepWidth) - 1];

        let color = null;

        switch (staffVacation.reason) {
            case 0:
                color = '245,124,0'; // orange
                break;
            case 1:
                color = '0,108,173'; // blue
                break;
            case 2:
                color = '26, 147, 111'; // green
                break;
            default:
        }

        let vacationStaffForm: StaffVacation = {
            color,
            reason: staffVacation.reason,
            notes: staffVacation.notes,
            start,
            end,
        };

        const starfVacations = staff.starfVacations && staff.starfVacations.length > 0 ? [...staff.starfVacations] : [];

        starfVacations[staffVacationIndex] = vacationStaffForm;

        setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);

        await mutateUpdateStaff({ data: { ...staff, starfVacations } });
    }

    const onChangeStatus = (staffVacationIndex: number) => {
        if (!hasEditRight(userRoles)) return;
        const staffData = { ...staff };
        staffData.starfVacations[staffVacationIndex] = {
            ...staffData.starfVacations[staffVacationIndex],
            isApproved: !staffData.starfVacations[staffVacationIndex]?.isApproved,
        };
        mutateUpdateStaff({ data: staffData });
    };

    let content = (
        <div
            id={`staffVacationBar-${id}`}
            className={`${allowEdit && !needConfirmation ? 'dragHandle' : 'dragDisabled'} ${classNames.barContent}`}
            style={{ marginLeft: width > calendarStepWidth * 2 ? 10 : 6, justifyContent: 'flex-start' }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'flex-end',
                        marginTop: 4,
                    }}
                >
                    {(width > calendarStepWidth * 2 || !allowEdit) && (
                        <span
                            style={{
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 11,
                                marginRight: 8,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                minWidth: 30,
                            }}
                        >
                            {text}
                            <span style={{ color: '#fff', fontWeight: 400, fontSize: 11, marginLeft: 8 }}>{staffVacation['notes'] || ''}</span>
                        </span>
                    )}
                    {allowEdit && (
                        <FontAwesomeIcon
                            id={`staffVacationAction-${id}`}
                            icon={faSquareEllipsisVertical as IconDefinition}
                            style={{ marginRight: width > calendarStepWidth * 2 ? 5 : 4, fontSize: 13 }}
                            className={classNames.icon}
                            onClick={toggleOpenActions}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    return (
        (canDisplay &&
            width > 0) ? (
            <>
                <Rnd
                    style={{ zIndex: 3, top: 0, left: 0, display: 'absolute' }}
                    // default={{ x: x, y: y, width: width, height: 48}}
                    size={{ width: width, height: calendarStepHeight }}
                    // size={{ width: width, height }}
                    position={{ x, y }}
                    enableResizing={{ left: allowEdit && !needConfirmation, right: allowEdit && !needConfirmation }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        const prevX = x;
                        const prevW = width;
                        const newX = parseInt(position.x.toFixed(0));
                        let newWidth = ref.offsetWidth;
                        // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
                        if (newWidth % calendarStepWidth !== 0) {
                            // @ts-ignore
                            newWidth = (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
                        }
                        if (newX === prevX && newWidth === prevW) {
                            return;
                        }

                        const start = Math.floor(newX / calendarStepWidth);
                        const end = Math.floor((newX + newWidth) / calendarStepWidth);

                        const startDateStr = horizontalPositionMap['positionsToDates'][start];
                        const endDateStr = horizontalPositionMap['positionsToDates'][end - 1];

                        if (checkOverlap({
                            efforts,
                            end: endDateStr,
                            start: startDateStr,
                            staffVacations,
                            vacationIndex: staffVacationIndex,
                            isFerie: true
                        })) {
                            dispatch(saveMessage('Cannot overlap'));
                            return;
                        }

                        setX(newX);
                        setWidth(newWidth);
                        // setUpdatePackage({
                        //     newX: newX,
                        //     newY: y,
                        //     newW: newWidth,
                        //     previousX: prevX,
                        //     previousY: y,
                        //     previousW: prevW,
                        // });
                        setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
                    }}
                    onDragStop={(e, d) => {
                        const prevX = x;
                        const prevY = y;
                        if ((d.lastX === prevX && d.lastY === prevY) || d.lastY !== prevY) {
                            return;
                        }

                        const newX = d.lastX;
                        const start = Math.floor(newX / calendarStepWidth);
                        const end = Math.floor((newX + width) / calendarStepWidth);

                        const startDateStr = horizontalPositionMap['positionsToDates'][start];
                        const endDateStr = horizontalPositionMap['positionsToDates'][end - 1];

                        if (checkOverlap({
                            efforts,
                            end: endDateStr,
                            start: startDateStr,
                            staffVacations,
                            vacationIndex: staffVacationIndex,
                            isFerie: true
                        })) {
                            dispatch(saveMessage('Cannot overlap'));
                            return;
                        }

                        // if (isOverlap(d.lastX, width)) {
                        //     return;
                        // }
                        setX(d.lastX);
                        setY(d.lastY);
                        // setUpdatePackage({
                        //     newX: d.lastX,
                        //     newY: d.lastY,
                        //     newW: width,
                        //     previousX: x,
                        //     previousY: y,
                        //     previousW: width,
                        // });
                        setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
                    }}
                    resizeGrid={[calendarStepWidth, calendarStepHeight]}
                    dragGrid={[calendarStepWidth, calendarStepHeight]}
                    dragAxis='x'
                    bounds='.planContainer'
                    dragHandleClassName='dragHandle'
                >
                    <div
                        id={id}
                        className={classNames.draggable}
                        style={{
                            backgroundImage: bgColor,
                            backgroundSize: '48px 48px',
                            backgroundPosition: '0 0, 0 0, -24px -24px, 24px 24px',
                            display: 'flex',
                            height: calendarStepHeight - 8,
                            borderRadius: 4,
                        }}
                    >
                        {confirmation === calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED && (
                            // @ts-ignore
                            <ConfirmBubble
                                onCancel={() => {
                                    reset();
                                }}
                                onApprove={() => {
                                    onClickSave();
                                }}
                                targetId={id}
                            />
                        )}
                        <TooltipForText zIndex={''} text={text}>{content}</TooltipForText>
                    </div>
                </Rnd>
                {openActions && (
                    <TeachingBubble
                        target={`#staffVacationAction-${id}`}
                        onDismiss={toggleOpenActions}
                        styles={{
                            subText: { color: 'black' },
                            subComponentStyles: {
                                callout: {
                                    root: {
                                        maxWidth: 110,
                                    },
                                },
                            },
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', height: 50, justifyContent: 'space-around' }}>
                            {hasEditRight(userRoles) && (
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}>
                                    <FontAwesomeIcon
                                        icon={isApproved ? faSquareCheck as IconDefinition : faSquare as IconDefinition}
                                        style={{ marginRight: 4, fontSize: 13, color: '#000' }}
                                        className={classNames.icon}
                                        onClick={() => onChangeStatus(staffVacationIndex)}
                                    />
                                    <span style={{ fontSize: 11 }}>Godkendt</span>
                                </div>
                            )}
                            <div
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}
                                id={`deleteVacation-${id}`}
                                onClick={toggleTeachingStaffVacation}
                            >
                                <FontAwesomeIcon icon={faTrashAlt as IconDefinition} style={{ marginRight: 4, fontSize: 13, color: '#000' }} className={classNames.icon} />
                                <span style={{ fontSize: 11 }}>Slet</span>
                            </div>
                        </div>
                    </TeachingBubble>
                )}
                {teachingBubbleStaffVacation && (
                    <DeleteConfirmation
                        target={`#staffVacationAction-${id}`}
                        toggleTeaching={toggleTeachingStaffVacation}
                        onClickConfirm={() => {
                            onClickConfirmDelete(staffVacationIndex);
                        }}
                    />
                )}
            </>
        ) : <></>
    )
}

export default FerieBar