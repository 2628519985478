import {
  faUserHardHat,
  faDigging,
  faUserTie,
  faLineColumns,
  faUserCrown,
  faUser,
  faThumbsUp,
  faThumbsDown,
  faExclamationCircle,
  faInfoCircle,
  faUserGear,
} from "@fortawesome/pro-regular-svg-icons";
import API_SOURCE from "../config.json";
import { MessageBarType } from "@fluentui/react";

// // In-situ:
// Ankermaskine
// Bil
// Boremaskine
// Boreudstyr
// Boreværktøj, Kelly
// Boreværktøj, VDW
// Div. Udlejningsgrej
// Geo Blandeanlæg
// Geo Test
// Geo Transport
// Kran
// Pæleboremaskine

// // Ramme:
// Hamre
// Ingeniørinstrumenter
// Kran
// Multimaskine Fundering
// Rammemaskine
// Skurvogn/Container
// Vibrator

// // GEO:
// CTP rig
// Diverse
// Geo Blandeanlæg
// Geo Borerig
/*
Section options
220: Vest
230: Geo
260: Øst
290: Udland
*/

export const techDepartmentOptions = [
  { key: 200, text: "DK Stabe", label: "DK Stabe" },
  { key: 205, text: "GE Stabe", label: "GE Stabe" },
  { key: 201, text: "Insitu", label: "Insitu" },
  { key: 202, text: "Ramme", label: "Ramme" },
  { key: 203, text: "Gvs", label: "Gvs" },
  { key: 204, text: "Geo", label: "Geo" },
  { key: 220, text: "Vest", label: "Vest" },
  { key: 230, text: "Øst1", label: "Øst1" },
  { key: 260, text: "Øst2", label: "Øst2" },
  { key: 270, text: "Øresund", label: "Øresund" },
  { key: 280, text: "Udland", label: "Udland" },
];

export const workingRoleOptions = [
  {
    key: 0,
    text: "Formand",
    role: "SUPERVISOR",
    name: "managers",
    icon: faUserTie,
  }, // Supervisor
  {
    key: 1,
    text: "Maskinfører",
    role: "DRIVER",
    name: "drivers",
    icon: faUserHardHat,
  }, // Driver
  {
    key: 2,
    text: "Håndmand",
    role: "WORKER",
    name: "workers",
    icon: faDigging,
  }, // Worker
  { key: 3, text: "Disponent", role: "DISPONENT", name: "", icon: faUserCrown }, // Disponent
  { key: 4, text: "Funktionær", role: "FUNKTIONÆR", name: "", icon: faUser }, // Funktionær
  { key: 5, text: "Ekstern", role: "EKSTERN", name: "", icon: faUserGear }, // Ekstern
];

export const techAreaOptions = [
  { key: 0, text: "Geoteknik" },
  { key: 1, text: "GVS" },
  { key: 2, text: "Øst" },
  { key: 3, text: "Vest" },
  { key: 4, text: "Kelly" },
  { key: 5, text: "Anker" },
];

export const reasonVacationStaffOptions = [
  { key: 0, text: "Andet fravær", color: "245,124,0" }, // sick
  { key: 1, text: "Ferie", color: "0,108,173" }, // vacation
  { key: 2, text: "Udlån", color: "26, 147, 111" }, // other project
];

export const SIDE_MENU_LABEL = [
  { to: "/", label: "DISPONER", iconName: "plan", icon: faLineColumns },
];

export const stationOptions = {
  203: {
    options: [
      // {
      //   key: 0,
      //   value: "Geoteknik",
      //   text: "Geoteknik",
      //   label: "Geoteknik",
      //   shortcutText: "G",
      // },
      { key: 0, value: "GVS", text: "GVS", label: "GVS", shortcutText: "GVS" },
    ],
    defaultValues: [0, 1],
    text: "Gvs",
  },
  204: {
    options: [
      {
        key: 0,
        value: "Geo",
        text: "Geo",
        label: "Geo",
        shortcutText: "G",
      },
      // {
      //   key: 0,
      //   value: "Geoteknik",
      //   text: "Geoteknik",
      //   label: "Geoteknik",
      //   shortcutText: "G",
      // },
      // { key: 1, value: "GVS", text: "GVS", label: "GVS", shortcutText: "GVS" },
    ],
    defaultValues: [0, 1],
    text: "Geo",
  },
  202: {
    options: [
      { key: 2, value: "Øst", text: "Øst", label: "Øst", shortcutText: "Ø" },
      { key: 3, value: "Vest", text: "Vest", label: "Vest", shortcutText: "V" },
    ],
    defaultValues: [2, 3],
    text: "Ramme",
  },
  201: {
    options: [
      {
        key: 4,
        value: "Kelly",
        text: "Kelly",
        label: "Kelly",
        shortcutText: "K",
      },
      {
        key: 5,
        value: "Anker",
        text: "Anker",
        label: "Anker",
        shortcutText: "A",
      },
    ],
    defaultValues: [4, 5],
    text: "Insitu",
  },
};

export const DAYS_OF_WEEK = ["S", "M", "T", "W", "T", "F", "S"];

export const CALENDAR_PAGE = {
  DISPOSITION: "DISPOSITION",
  MACHINE_SERVICE: "MACHINE_SERVICE",
  STAFF: "STAFF",
  PRINT: "PRINT",
};

export const calendarConfirmationStatus = {
  CONFIRMATION_UNSET: 0,
  CONFIRMATION_DECISION_NEEDED: 1,
  CONFIRMATION_ACCEPT: 2,
  CONFIRMATION_CANCEL: 3,
};

export const REACT_APP_GOOGLE_MAPS_API_KEY = API_SOURCE.googleApiKey;

// export const HOLIDAY_API_KEY = API_SOURCE.holidayApiKey;

export const months = [
  "JANUAR",
  "FEBRUAR",
  "MARTS",
  "APRIL",
  "MAJ",
  "JUNI",
  "JULI",
  "AUGUST",
  "SEPTEMBER",
  "OKTOBER",
  "NOVEMBER",
  "DECEMBER",
];

export const ROLES = {
  MACHINE: "MACHINE",
  WORKER: "WORKER",
  DRIVER: "DRIVER",
  SUPERVISOR: "SUPERVISOR",
};

export const CALENDAR_STEP_WIDTH = 25;
export const CALENDAR_WEEKVIEW_STEP_WIDTH = 10;

export const CALENDAR_STEP_HEIGHT = 56;
export const CALENDAR_STAFFVIEW_STEP_HEIGHT = 32;

export const prodURLs = [
  "https://white.kodebaze.com",
  "https://fleetplanner-white.aarsleff.com",
];

export const SOURCE_API = [
  "https://white.kodebaze.com",
  "https://fleetplanner-white.aarsleff.com",
].some((el) => window.location.href.includes(el))
  ? API_SOURCE.apiURL
  : API_SOURCE.devApiUrl;

export const CONFIRM_PANEL_MODE = {
  NORMAL: {
    backgroundColor: "#fff",
    color: "#000",
  },
  DANGER: {
    backgroundColor: "#E3B505",
    color: "#000",
    noBGColor: "#E3B505",
    noColor: "#fff",
  },
  WARNING: {
    backgroundColor: "#006CAD",
    color: "#fff",
    noBGColor: "#006CAD",
    noColor: "#fff",
    confirmBGColor: "#fff",
    confirmColor: "#000",
  },
};

export const PRINTING_PAGE = {
  MACHINES: "MACHINES",
  SUPERVISOR: "SUPERVISOR",
  PROJECTS: "PROJECTS",
};

export const PRINTING_PAGE_OPTIONS = [
  { key: PRINTING_PAGE.MACHINES, text: "Print Machines Page" },
  { key: PRINTING_PAGE.SUPERVISOR, text: "Print Supervisors Page" },
  { key: PRINTING_PAGE.PROJECTS, text: "Print Projects Page" },
];

export const MACHINE_KEY_SELECT_PIVOT = {
  MACHINE_TABLE: "MACHINE_TABLE",
  MACHINE_CALENDAR: "MACHINE_CALENDAR",
  MACHINE_TABLE_TEAMS: "MACHINE_TABLE_TEAMS",
};

export const STAFF_KEY_SELECT_PIVOT = {
  STAFF_TABLE: "STAFF_TABLE",
  STAFF_MISSING_INFO: "STAFF_MISSING_INFO",
  STAFF_CALENDAR: "STAFF_CALENDAR",
};

export const ACTIVITY_LOG_ENUM = {
  PROJECT: 1,
  PLANNING: 2,
  BOOKING: 3,
  MACHINE: 4,
  MACHINE_TEAM: 5,
  USER: 6,
  MACHINE_REQ: 7,
};

export const ACTIVITY_LOG_TYPE_ENUM = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
};

export const colorList = [
  "0,95,174",
  "18,36,89",
  "182,225,242",
  "228,0,43",
  "101,84,47",
  "96,135,66",
  "95,107,109",
];

export const machineColorList = ["170,170,170", "220,220,220"];

export const ZOOM_CONTROL = [0.5, 0.67, 0.75, 0.8, 0.9, 1.0];

export const SEARCH_TYPE_ENUM = {
  EVERYTHING: 0,
  PLANNING: 1,
  MACHINE: 2,
  STAFF: 3,
  PROJECT: 4,
};

export const hasEditRight = (rolesString: any) => {
  return rolesString.includes(3);
};

export const MESSAGE_INFO = {
  [MessageBarType.success]: {
    text: "Success!",
    icon: faThumbsUp,
    color: "#1A936F",
  },
  [MessageBarType.error]: {
    text: "Error!",
    icon: faThumbsDown,
    color: "#F57C00",
  },
  [MessageBarType.warning]: {
    text: "Warning!",
    icon: faExclamationCircle,
    color: "#e60000",
  },
  [MessageBarType.info]: {
    text: "Info!",
    icon: faInfoCircle,
    color: "#006CAD",
  },
};

export const ITEM_HEIGHT = 32;
export const HEADER_HEIGHT = 32;

export const PROJECT_CATEGORY = {
  Projekt: {
    key: 1,
  },
  Aftaleopgave: {
    key: 2,
  },
};
