// Import libraries
import { mergeStyleSets } from "@fluentui/react";
import { useState, useMemo } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faInfoCircle,
  faPencil,
  faPencilRuler,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

// Import redux
import { setDrawUserEffort } from "../../redux/app/app.actions";
import { saveMessage } from "../../redux/message/message.actions";

// Import types
import { TProject } from "../../types/project";

// Import components
import PopupProject from "./PopupProject";
import { HEADER_HEIGHT, ITEM_HEIGHT } from "../../utils/constants";
import { TooltipForText } from "../common";
import { truncateText } from "../../utils/string";
import { viewProject } from "../../redux/project/project.actions";

const classNames = mergeStyleSets({
  peopleNameContainer: {
    height: HEADER_HEIGHT,
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
  },
  icon: {
    fontSize: 16,
    color: "#006CAD",
    cursor: "pointer",
  },
  projectNameContainer: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: ITEM_HEIGHT,
    borderBottom: "1px solid #DBDBDB",
    boxSizing: "border-box",
  },
  addContainer: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: ITEM_HEIGHT,
    borderBottom: "1px solid #DBDBDB",
    boxSizing: "border-box",
  },
});

export type TProjectList = {
  projectName: string;
  projectId: string;
}[];

type PeopleListProps = {
  name: string;
  userId: string;
  projects: {
    projectId: string;
    projectName: string;
    yPosition: number;
    isEdit?: boolean | undefined;
  }[];
  projectsData: TProject[];
  onClickAddProject: ({
    selectedProject,
    userId,
  }: {
    selectedProject: TProject;
    userId: string;
  }) => void;
  selectedProjectId?: string;
};

const PeopleList = ({
  name,
  projects,
  userId,
  projectsData,
  onClickAddProject,
  selectedProjectId,
}: PeopleListProps) => {
  const dispatch = useDispatch();

  const id = `staff-add-project-${userId}`;

  const [showAdd, setShowAdd] = useState<boolean>();

  const onDismissShowAdd = () => {
    setShowAdd(undefined);
  };

  const onProjectSelected = (selectedProject: TProject) => {
    const { projectId: selectedProjectId } = selectedProject;

    const existingProject = projects.find(
      ({ projectId }) => projectId === selectedProjectId
    );

    if (existingProject) {
      dispatch(saveMessage("This project has already added to this user"));

      return;
    }

    onClickAddProject({ selectedProject, userId });
  };

  const style: React.CSSProperties = useMemo(() => {
    const styleObj: React.CSSProperties = {};

    if (projects.length === 0) {
      styleObj.borderBottom = "1px solid #DBDBDB";
    }

    return styleObj;
  }, [projects]);

  const projectStyle: React.CSSProperties = useMemo(() => {
    const styleObj: React.CSSProperties = {};

    if (selectedProjectId) {
      styleObj.backgroundColor = "rgb(153,179,255)";
    }

    return styleObj;
  }, [selectedProjectId]);

  return (
    <div>
      <div style={style} className={classNames.peopleNameContainer}>
        <p style={{ margin: 0 }}>{name}</p>
      </div>
      {projects.map(({ projectId, projectName, isEdit }) => (
        <div
          key={projectId}
          className={classNames.projectNameContainer}
          style={selectedProjectId === projectId ? projectStyle : {}}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
            }}
          >
            <FontAwesomeIcon
              icon={faPencilRuler as IconDefinition}
              style={{ marginRight: 0, cursor: "none" }}
              className={classNames.icon}
            />
            <div
              style={{
                display: "flex",
                gap: 10,
                alignContent: "center",
              }}
            >
              <div>
                <TooltipForText
                  zIndex=""
                  text={projectName.length > 20 ? projectName : ""}
                >
                  <span>
                    {truncateText({
                      maxLength: 20,
                      text: projectName,
                    })}
                  </span>
                </TooltipForText>
              </div>
              <div>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faInfoCircle as IconDefinition}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(viewProject(projectId));
                  }}
                />
              </div>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faPencil as IconDefinition}
            className={classNames.icon}
            onClick={() => {
              if (!isEdit) {
                dispatch(setDrawUserEffort({ userId, projectId }));
              }
            }}
          />
        </div>
      ))}
      <div className={classNames.addContainer}>
        <FontAwesomeIcon
          icon={faPlus as IconDefinition}
          className={classNames.icon}
          onClick={() => {
            setShowAdd(true);
          }}
          id={id}
        />
      </div>
      {showAdd && (
        <PopupProject
          onSelectProject={onProjectSelected}
          projectsData={projectsData}
          targetElemId={id}
          onDismiss={() => {
            onDismissShowAdd();
          }}
        />
      )}
    </div>
  );
};

export default PeopleList;
