import { AppActionTypes } from "./app.types";

export const PAGES = {
  PROJECT: "PROJECT",
  PEOPLE: "PEOPLE",
};

const initialState = {
  showSidebar: true,
  barDrawer: "",
  vacationReason: null,
  highlightedStaffId: null,
  page: PAGES.PEOPLE,
  drawProjectEffortId: null,
  drawUserEffortId: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.CLEAR_DRAW_EFFORT:
      return {
        ...state,
        drawProjectEffortId: null,
        drawUserEffortId: null,
      };
    case AppActionTypes.SET_DRAW_USER_EFFORT:
      return {
        ...state,
        drawUserEffortId: action.value,
      };
    case AppActionTypes.CHANGE_PAGE:
      return {
        ...state,
        page: action.value,
      };
    case AppActionTypes.SET_SIDE_BAR_STATUS:
      return {
        ...state,
        showSidebar: action.value,
      };

    case AppActionTypes.CHANGE_ZOOM_RATIO:
      return {
        ...state,
        ...action.value,
      };

    case AppActionTypes.SET_BAR_DRAWER:
      return {
        ...state,
        barDrawer: action.payload.barDrawer,
        vacationReason: action.payload.vacationReason,
        drawProjectEffortId: null,
        drawUserEffortId: null,
      };

    case AppActionTypes.SET_HIGHLIGHT_STAFF:
      return {
        ...state,
        highlightedStaffId: action.value,
      };

    default:
      return state;
  }
};

export default appReducer;
