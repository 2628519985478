import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  DefaultButton,
  PrimaryButton,
  SearchBox,
  Tooltip,
} from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buttonStyle } from "../../../common/calendar/ButtonStyles";
import {
  faClose,
  faFilter,
  faHelmetSafety,
  faPencilRuler,
} from "@fortawesome/pro-regular-svg-icons";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { setFilter } from "../../../../redux/machines/machines.actions";
import {
  // stationOptions,
  techDepartmentOptions,
} from "../../../../utils/constants";
// import StaffRoleSelector from '../filterSelector/StaffRoleSelector';
import ToggleableSelector from "../filterSelector/ToggleableSelector";
import { changePage } from "../../../../redux/app/app.actions";
import { PAGES } from "../../../../redux/app/app.reducer";
import debounce from "lodash.debounce";
import {
  primaryButtonStyles,
  smallPrimaryButtonStyles,
} from "../../../../utils/theme";
import { TooltipForText } from "../../../common";
import StaffRoleSelector from "../filterSelector/StaffRoleSelector";

import { classNames as filterSelectorStyles } from "../filterSelector/SelectorStyles";

const classNames = mergeStyleSets({
  unSelectedIcon: {
    marginRight: 2,
    fontSize: 16,
    color: "#006CAD",
  },
  selectedIcon: {
    marginRight: 2,
    fontSize: 16,
    color: "#fff",
  },
  iconNumber: {
    position: "absolute",
    paddingLeft: "3px",
    paddingRight: "3px",
    borderRadius: "50%",
    marginTop: "-15px",
    marginLeft: "15px",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    backgroundColor: "#006CAD",
  },
  heading: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#000000",
  },
  sectionContainer: {
    marginTop: 20,
    paddingLeft: 32,
    paddingRight: 32,
  },
  lastSection: {
    marginTop: 35,
    marginBottom: 50,
  },
  sectionHeadingContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
});

const FILTER_KEY = "selectedTechDepartmentList";

const PlanningPageFilter = ({ scrollY }) => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.machine.filter);
  // const defaultData = useSelector((state) => state.defaultData);
  const { page } = useSelector((state) => state.app);
  const [showBubble, setShowBubble] = useState(false);

  const componentStyles = () => ({
    // teachingBubble: {
    // root: {
    // marginTop: 140 - scrollY,
    // },
    // subComponentStyles: {
    //     callout: {
    //         beak: {
    //             marginTop: -140 + scrollY,
    //         },
    //     },
    // },
    // },
    searchBox: {
      root: {
        border: "none",
        backgroundColor: "#FFFFFF",
        marginTop: "-10px",
        marginRight: "-10px",
        height: "56px",
        width: "204px",
        ":after": {
          border: "none",
        },
      },
      iconContainer: {
        display: "none",
      },
    },
  });

  useEffect(() => {
    dispatch(setFilter({ ...filter, staffNames: "", projectNames: "" }));
  }, [page, dispatch]);

  // const countSelectorNumbers = () => {
  //     let count = 0;
  //     const countKey = ['techArea', 'staffRole'];
  //     Object.keys(filter).forEach((key) => {
  //         if (countKey.includes(key)) {
  //             if (key === 'staffRole') {
  //                 count += filter[key]?.filter((item) => item.isOn)?.length;
  //             } else if (key === 'selectedRegion') {
  //                 count += filter['availableRegion']?.filter((item) => filter[key].includes(item))?.length || 0;
  //             } else if (key === 'techArea') {
  //                 count += filter[key]?.length;
  //             } else if (key === 'selectedMachineSizeGroup') {
  //                 count += filter['availableMachineSizeGroup']?.filter((item) => filter[key].includes(item))?.length || 0;
  //             }
  //         }
  //     });
  //     return count;
  // };

  const debouncedSetStaffName = debounce(function (newStaffName) {
    dispatch(setFilter({ ...filter, staffNames: newStaffName?.trim() || "" }));
  }, 0);

  const debouncedSetProjectName = debounce(function (newProjectNames) {
    dispatch(
      setFilter({ ...filter, projectNames: newProjectNames?.trim() || "" })
    );
  }, 0);

  return (
    <div>
      <div id="filter-button">
        <div style={{ display: "flex", gap: 24 }}>
          <div style={{ display: "flex" }}>
            <DefaultButton
              onClick={() => {
                dispatch(changePage(PAGES.PROJECT));
              }}
              styles={{
                ...buttonStyle(page === PAGES.PROJECT, "", { marginRight: 0 }),
              }}
            >
              <FontAwesomeIcon
                icon={faPencilRuler}
                style={{ marginRight: 0 }}
                className={
                  page === PAGES.PROJECT
                    ? classNames.selectedIcon
                    : classNames.unSelectedIcon
                }
              />
              {/* {countSelectorNumbers() > 0 && <span className={classNames.iconNumber}>{countSelectorNumbers()}</span>} */}
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                dispatch(changePage(PAGES.PEOPLE));
              }}
              styles={{
                ...buttonStyle(page === PAGES.PEOPLE, "", { marginRight: 0 }),
              }}
            >
              <FontAwesomeIcon
                icon={faHelmetSafety}
                style={{ marginRight: 0 }}
                className={
                  page === PAGES.PEOPLE
                    ? classNames.selectedIcon
                    : classNames.unSelectedIcon
                }
              />
            </DefaultButton>
          </div>
          <DefaultButton
            onClick={() => setShowBubble(!showBubble)}
            styles={{ ...buttonStyle(false) }}
          >
            <FontAwesomeIcon
              icon={faFilter}
              className={classNames.unSelectedIcon}
            />
          </DefaultButton>
        </div>
      </div>
      {showBubble && (
        <TeachingBubble
          styles={{
            body: {
              width: 424,
              // height: 300,
            },
            bodyContent: {
              padding: 0,
              paddingBottom: 20,
            },
          }}
          closeButtonAriaLabel="Close"
          target="#filter-button"
          onDismiss={() => setShowBubble(!showBubble)}
          calloutProps={{
            calloutHeight: 277,
            calloutMinWidth: 424,
          }}
        >
          <div style={{ marginTop: "32px" }}>
            <div
              style={{
                display: "flex",
                // gap: '32px',
                justifyContent: "space-between",
              }}
            >
              <div>
                <span
                  className={classNames.heading}
                  style={{ paddingLeft: 32 }}
                >
                  Projektnavn
                </span>
                <div style={{ marginTop: "20px" }}>
                  <SearchBox
                    styles={componentStyles().searchBox}
                    placeholder="Søg"
                    clearButtonProps={{
                      style: {
                        backgroundColor: "#FFFFFF",
                        marginLeft: "20px",
                      },
                    }}
                    value={filter["projectNames"] || ""}
                    onChange={(_, value) => {
                      debouncedSetProjectName(value || "");
                    }}
                  />
                  <hr
                    style={{ width: "100%", marginTop: "-2px", opacity: 0.5 }}
                  />
                </div>
              </div>
              <div>
                <span className={classNames.heading}>Mandskabsnavn</span>
                <div style={{ marginTop: "20px" }}>
                  <SearchBox
                    styles={componentStyles().searchBox}
                    placeholder="Søg"
                    clearButtonProps={{
                      style: {
                        backgroundColor: "#FFFFFF",
                        marginLeft: "20px",
                      },
                    }}
                    value={filter["staffNames"] || ""}
                    onChange={(_, value) => {
                      debouncedSetStaffName(value || "");
                    }}
                  />
                  <hr
                    style={{ width: "100%", marginTop: "-2px", opacity: 0.5 }}
                  />
                </div>
              </div>
            </div>
            <div className={classNames.sectionContainer}>
              <div className={classNames.sectionHeadingContainer}>
                <div className={classNames.heading}>Sektion</div>
                <div>
                  <TooltipForText text="Clear All">
                    <PrimaryButton
                      onClick={() => {
                        dispatch(
                          setFilter({
                            selectedTechDepartmentList: [],
                          })
                        );
                      }}
                      styles={{
                        ...smallPrimaryButtonStyles,
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </PrimaryButton>
                  </TooltipForText>
                </div>
                <div>
                  <TooltipForText text="Select All">
                    <div
                      onClick={() => {
                        dispatch(
                          setFilter({
                            selectedTechDepartmentList:
                              filter.availableTechDepartmentList,
                          })
                        );
                      }}
                      className={
                        (filter.availableTechDepartmentList || []).every((el) =>
                          filter.selectedTechDepartmentList.includes(el)
                        )
                          ? filterSelectorStyles.buttonSelected
                          : filterSelectorStyles.button
                      }
                    >
                      All
                    </div>
                  </TooltipForText>
                </div>
              </div>
              <ToggleableSelector
                filterKey={FILTER_KEY}
                allOptions={techDepartmentOptions}
                availableOptions={filter.availableTechDepartmentList}
                selectedOptions={filter.selectedTechDepartmentList}
              />

              <div
                style={{
                  marginTop: 35,
                }}
              >
                <span className={classNames.heading}>Personalets rolle</span>
                <StaffRoleSelector />
              </div>
            </div>
          </div>
        </TeachingBubble>
      )}
    </div>
  );
};

export default PlanningPageFilter;
