// Import libraries
import { Rnd } from "react-rnd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DraggableData } from "react-rnd";
import { Position } from "react-rnd";
import { ResizableDelta } from "react-rnd";

// Import constants
import {
  calendarConfirmationStatus,
  hasEditRight,
} from "../../utils/constants";

// Import types
import { THorizontalPositionMap } from "../../types/positionmap";

// Import redux
import { saveMessage } from "../../redux/message/message.actions";

// Import components
import AllocationBubble from "./AllocationBubble";

// Import libraries
import { mergeStyleSets } from "@fluentui/react";
import { useMutation, useQueryClient } from "react-query";
import { updateProjectEffort } from "../../redux/effort/effort.action";
import { TEffort } from "../../types/effort";
import { checkOverlap } from "../../utils/effort";
import { StaffVacation } from "../../types/staff";
import { generateUniqueId } from "../../utils/utils";

const classNames = mergeStyleSets({
  allocation: {
    color: "white",
    overflow: "hidden",
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 11,
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "center",
    paddingRight: 8,
  },
});

type EffortBarDrawerProps = {
  onDrawingCancelled: () => void;
  yPosition: number;
  height: number;
  calendarStepWidth: number;
  calendarStepHeight: number;
  horizontalPositionMap: THorizontalPositionMap;
  color: string;
  userId: string;
  projectId: string;
  staffVacations: StaffVacation[];
  efforts: TEffort[];
};

const EffortBarDrawer = ({
  onDrawingCancelled,
  height,
  efforts,
  staffVacations,
  projectId,
  userId,
  yPosition,
  calendarStepWidth,
  calendarStepHeight,
  horizontalPositionMap,
  color,
}: EffortBarDrawerProps) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  // Mutations
  const { mutate: updateStaffMutation } = useMutation<any, Error, TEffort>(
    (effort) =>
      updateProjectEffort({
        updateData: effort,
      })(dispatch),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("plans");
          queryClient.invalidateQueries("staffs");
          cancelDrawBar();
        }
      },
    }
  );

  // useSelectors
  // @ts-ignore
  const defaultFilter = useSelector((state) => state.user.user.defaultFilter);
  // @ts-ignore
  const userZoomRatio = 1;
  // @ts-ignore
  const userRoles = useSelector((state) => state.user.user.workingRole);
  const allowEdit = hasEditRight(userRoles);

  // Flag variables
  const [isDrawing, setIsDrawing] = useState<boolean>(true);
  const [canDisplay, setCanDisplay] = useState<boolean>(false);
  const [allocation, setAllocation] = useState<number>(100);

  // Calculation variables
  const [width, setWidth] = useState(0);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [barInfo, setBarInfo] = useState<{
    start: number | null;
    end: number | null;
    id: string;
  }>({
    start: null,
    end: null,
    id: `Id-allocation-${userId}-${projectId}`,
  });
  const [confirmation, setConfirmation] = useState<number>(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );

  const xAxisStartingPoint = 280;
  const yAxisStartingPoint = 202;

  // Use Effects
  useEffect(() => {
    setY(yPosition);
  }, [yPosition]);

  useEffect(() => {
    if (typeof barInfo.start === "number" && typeof barInfo.end === "number") {
      setX(barInfo.start * calendarStepWidth);
      setWidth((barInfo.end - barInfo.start) * calendarStepWidth);
      setCanDisplay(true);
    }
  }, [calendarStepWidth, barInfo]);

  useEffect(() => {
    changeCursorOfPlanContainer(isDrawing);
  }, [isDrawing]);

  const cancelDrawBar = () => {
    changeCursorOfPlanContainer(false);
    onDrawingCancelled();
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
  };

  const changeCursorOfPlanContainer = (isDrawing: boolean) => {
    const planContainer = document.getElementsByClassName(
      "planContainer"
    )[0] as HTMLElement;
    if (!planContainer) {
      console.error("No plan container found to draw bars");
      return;
    }

    if (isDrawing) {
      planContainer.style.cursor = `url(${window.location.origin}/img/pencil-alt.svg), auto`;
    } else {
      planContainer.style.cursor = "default";
    }
  };

  const isWithinYRange = useCallback(
    (mouseYPosition: number) => {
      const halfOfCalendarStepHeight = calendarStepHeight / 2;
      const actualYPosition = yPosition;

      if (
        mouseYPosition >= actualYPosition - halfOfCalendarStepHeight &&
        mouseYPosition <= actualYPosition + halfOfCalendarStepHeight
      ) {
        return true;
      }

      return false;
    },
    [yPosition]
  );

  const handleMouseDown = (ev: MouseEvent) => {
    if (isDrawing) {
      const mouseXPosition = ev.pageX / userZoomRatio - xAxisStartingPoint;
      const mouseYPosition = ev.pageY / userZoomRatio - yAxisStartingPoint;

      let startOfBar = Math.floor(mouseXPosition / calendarStepWidth);

      if (!isWithinYRange(mouseYPosition)) {
        cancelDrawBar();
      } else {
        if (!allowEdit) {
          cancelDrawBar();
          dispatch(saveMessage(`You can't create vacation for other users!`));
        }
      }

      setBarInfo({
        ...barInfo,
        start: startOfBar,
        end: startOfBar + 1,
      });
    }
  };

  const handleMouseMove = (ev: MouseEvent) => {
    if (isDrawing && barInfo.start !== null) {
      const xPosition = ev.pageX / userZoomRatio - xAxisStartingPoint;

      const endOfBar = Math.floor(xPosition / calendarStepWidth);

      setBarInfo({
        ...barInfo,
        end: endOfBar,
      });
    }
  };

  const handleMouseUp = (ev: MouseEvent) => {
    if (isDrawing && barInfo.start !== null) {
      const xPosition = ev.pageX / userZoomRatio - xAxisStartingPoint;
      const endOfBar = Math.floor(xPosition / calendarStepWidth);
      const isBackwardDrawing = endOfBar < barInfo.start;

      if (isBackwardDrawing) {
        cancelDrawBar();
      }

      const startDateStr =
        horizontalPositionMap["positionsToDates"][barInfo.start];
      const endDateStr =
        horizontalPositionMap["positionsToDates"][endOfBar - 1];

      if (
        checkOverlap({
          efforts,
          end: endDateStr,
          start: startDateStr,
          staffVacations,
          effortProjectId: projectId,
        })
      ) {
        dispatch(saveMessage("Cannot Overlap"));
        cancelDrawBar();
        return;
      }

      setIsDrawing(false);
      setBarInfo({
        ...barInfo,
        end: endOfBar === barInfo.start ? endOfBar + 1 : endOfBar,
      });
      setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
    }
  };

  const handleEscClick = ({ key }: KeyboardEvent) => {
    if (key === "Escape") cancelDrawBar();
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown, { passive: true });
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    window.addEventListener("mouseup", handleMouseUp, { passive: true });
    window.addEventListener("keydown", handleEscClick, { passive: true });
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleEscClick);
    };
  });

  const onClickSave = ({ allocation }: { allocation: number }) => {
    if (typeof barInfo.start === "number" && typeof barInfo.end === "number") {
      const start = horizontalPositionMap["positionsToDates"][barInfo.start];
      const end = horizontalPositionMap["positionsToDates"][barInfo.end - 1];

      updateStaffMutation({
        end,
        start,
        percentage: allocation,
        projectId,
        userId,
        id: generateUniqueId(`-${projectId}-${userId}`),
      });

      setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    }
  };

  const onResize = () => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
  };

  const onResizeStop = (
    e: MouseEvent | TouchEvent,
    dir:
      | "top"
      | "right"
      | "bottom"
      | "left"
      | "topRight"
      | "bottomRight"
      | "bottomLeft"
      | "topLeft",
    ref: HTMLElement,
    delta: ResizableDelta,
    position: Position
  ) => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
    const prevX = x;
    const prevW = width;
    const newX = parseInt(position.x.toFixed(0));
    let newWidth = ref.offsetWidth;
    // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
    if (newWidth % calendarStepWidth !== 0) {
      // @ts-ignore
      newWidth = (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
    }
    if (newX === prevX && newWidth === prevW) {
      return;
    }
    // if (isOverlap(newX, newWidth)) {
    //     rollback({ previousY: y, previousW: prevW });
    //     return;
    // }

    const newStartNum = Math.floor(newX / calendarStepWidth);
    const newEndNum = newStartNum + Math.floor(newWidth / calendarStepWidth);

    const startDateStr = horizontalPositionMap["positionsToDates"][newStartNum];
    const endDateStr = horizontalPositionMap["positionsToDates"][newEndNum - 1];

    if (
      checkOverlap({
        efforts,
        staffVacations,
        end: endDateStr,
        start: startDateStr,
        effortProjectId: projectId,
      })
    ) {
      dispatch(saveMessage("Cannot Overlap"));
      return;
    }

    setX(newX);
    setWidth(newWidth);
    const start = Math.floor(newX / calendarStepWidth);
    const end = Math.floor((newX + newWidth) / calendarStepWidth);
    setBarInfo({
      ...barInfo,
      start,
      end,
    });
  };

  const onDrag = () => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
  };

  const onDragStop = (
    e:
      | React.MouseEvent<HTMLElement | SVGElement>
      | React.TouchEvent<HTMLElement | SVGElement>
      | MouseEvent
      | TouchEvent,
    d: DraggableData
  ) => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
    // this is kinda suprising but the values that match the grids are the lastX and lastY instead of x and y, so that is what we set
    const prevX = x;
    const prevY = y;
    const newX = parseInt(d.lastX.toFixed(0));
    const newY = parseInt(d.lastY.toFixed(0));
    if (newX === prevX && newY === prevY) {
      return;
    }

    const newStartNum = Math.floor(newX / calendarStepWidth);
    const newEndNum = newStartNum + Math.floor(width / calendarStepWidth);

    const startDateStr = horizontalPositionMap["positionsToDates"][newStartNum];
    const endDateStr = horizontalPositionMap["positionsToDates"][newEndNum - 1];

    if (
      checkOverlap({
        efforts,
        staffVacations,
        end: endDateStr,
        start: startDateStr,
        effortProjectId: projectId,
      })
    ) {
      dispatch(saveMessage("Cannot overlap"));
      return;
    }
    setX(newX);
    // setY(newY);
    const start = Math.floor(newX / calendarStepWidth);
    const end = Math.floor((newX + width) / calendarStepWidth);
    setBarInfo({
      ...barInfo,
      start,
      end,
    });
  };

  return (
    <>
      {width && canDisplay ? (
        <Rnd
          style={{
            zIndex: 3,
            top: 0,
            left: 0,
            display: "absolute",
            borderRadius: 4,
            boxSizing: "border-box",
          }}
          size={{ width, height: calendarStepHeight }}
          position={{
            x,
            y,
          }}
          resizeGrid={[calendarStepWidth, calendarStepHeight]}
          dragGrid={[calendarStepWidth, calendarStepHeight]}
          dragAxis="x"
          onDrag={onDrag}
          onDragStop={onDragStop}
          // disableDragging={isDrawing}
          bounds=".planContainer"
          dragHandleClassName="dragHandle"
          enableResizing={{
            left: !isDrawing ? true : false,
            right: !isDrawing ? true : false,
          }}
          onResize={onResize}
          onResizeStop={onResizeStop}
        >
          <div
            id={barInfo.id}
            style={{
              display: "flex",
              // marginTop: 5,
              height: calendarStepHeight - 8,
              borderRadius: 4,
              backgroundColor: `rgb(${color})`,
              marginTop: 4,
            }}
            className="dragHandle"
          >
            {confirmation ===
            calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED ? (
              <AllocationBubble
                targetElemId={barInfo.id}
                onConfirm={(allocation) => {
                  onClickSave({ allocation });
                  setAllocation(allocation);
                }}
                onCancel={() => {
                  cancelDrawBar();
                }}
              />
            ) : (
              <></>
            )}
            <div className={classNames.allocation}>{`${allocation}%`}</div>
          </div>
        </Rnd>
      ) : (
        <></>
      )}
    </>
  );
};

export default EffortBarDrawer;
