import React, { useState, Fragment, useEffect } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Header, SideMenuSmall, TooltipForText } from "../common";
import { connect, useDispatch } from 'react-redux';
import { Separator } from '@fluentui/react/lib/Separator';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { updateSettingFilter } from '../../redux/user/user.actions';
import { setFilter } from '../../redux/machines/machines.actions';
import { postNewFilterSetting } from '../../redux/settingManagement/settingManagement.action';
import { useMutation } from 'react-query';
import { workingRoleOptions } from '../../utils/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@fluentui/react";
import ToggleButtonPill from "../common/ToggleButtonPill";
import { modifyCustomFilters } from "../../utils/utils";

const commonStationSelectorButton = {
    height: 20,
    borderRadius: 24,
    color: '#ffffff',
    background: '#006CAD',
    padding: '4px 16px',
    fontSize: 14,
    lineHeight: 20,
    margin: '8px 0px',
    cursor: 'pointer',
    selectors: {
        ':hover': {
            color: '#DBDBDB',
        }
    }
};

const classNames = mergeStyleSets({
    root: {
        display: "flex",
        flexDirection: "row",
        minHeight: "100%",
    },
    pageContentContainer: {
        height: "100vh",
        width: "100%",
    },
    pageInnerContainer: {
        margin: "40px 124px 40px 160px",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        width: 622,
    },
    row: {
        display: "flex",
        alignItems: "center",
        height: 56
    },
    column: {
        flexBasis: "100%",
        display: 'flex'
    },
    title: {
        fontWeight: 700,
        fontSize: 11
    },
    bottomContainer: {
        marginTop: 80
    },
    button: [
        commonStationSelectorButton,
        {
            color: '#868685',
            background: '#DFDFDF',
            selectors: {
                ':hover': {
                    color: '#DFDFDF',
                    background: '#868685'
                }
            } 
        }
    ],
    buttonSelected: commonStationSelectorButton,
    unselectedText: {
        fontSize: 13,
        color: "#006CAD",
    },
    selectedText: {
        fontSize: 13,
        color: "#fff",
    },
});

const toggleStyle = {
    root: {
        margin: 0
    },
    text: {
        fontWeight: 400,
        fontSize: 14
    }
};

const defaultSetting = [
    {
        key: 202,
        name: 'Ramme (202)',
        isOn: false,
        areas: [
            {
                key: 2,
                name: 'Øst',
                isOn: false,
            },
            {
                key: 3,
                name: 'Vest',
                isOn: false,
            },
        ]
    },
    {
        key: 201,
        name: 'Insitu (201)',
        isOn: false,
        areas: [
            {
                key: 4,
                name: 'Kelly',
                isOn: false,
            },
            {
                key: 5,
                name: 'Anker',
                isOn: false,
            },
        ]
    },
    {
        key: 203,
        name: 'Geo (203)',
        isOn: false,
        areas: [
            {
                key: 0,
                name: 'Geoteknik',
                isOn: false,
            },
            {
                key: 1,
                name: 'GVS',
                isOn: false,
            },
        ]
    },
];

const defaultStaffRoles = workingRoleOptions.map((role) => ({...role, isOn: true}));

const NotificationManagement = ({userName, updateSettingFilter, defaultFilter, postNewFilterSetting}) => {
    const dispatch = useDispatch();
    const [calendarStartFrom, setCalendarStartFrom] = useState('month');
    const titleClassNames = [classNames.column, classNames.title].join(' ');
    const [settings, setSettings] = useState(defaultSetting);
    const [staffRoles, setStaffRoles] = useState(defaultStaffRoles);
    const { mutate: mutateNewFilterSetting } = useMutation((settingData) => postNewFilterSetting(settingData), {
        onSuccess: (data, settingData) => {
            if(data) {
                updateSettingFilter(settingData);
                dispatch(setFilter({
                    techDepartment: settingData.techDepartmentFilter,
                    [settingData.techDepartmentFilter]: {
                        techArea: settingData.techAreaFilters,
                    }
                }))
            }
        }
    })

    useEffect(() => {
        const tempSettings = JSON.parse(JSON.stringify(defaultSetting));
        const tempStaffRoles = JSON.parse(JSON.stringify(defaultStaffRoles));
        if (defaultFilter?.techDepartmentFilter && defaultFilter?.techAreaFilters) {
            const index = tempSettings.findIndex((department) => department.key === defaultFilter.techDepartmentFilter);
            if (index !== -1) {
                tempSettings[index].isOn = true;
                tempSettings[index].areas = tempSettings[index].areas.map((item) => ({...item, isOn: defaultFilter.techAreaFilters.includes(item.key)}));
                setSettings(tempSettings)
            }
        }
        if (defaultFilter?.staffRoleFilters) {
            setStaffRoles(tempStaffRoles.map((role) => ({...role, isOn: defaultFilter.staffRoleFilters.includes(role.key)})));
        }
        if (Array.isArray(defaultFilter?.customFilters)) {
            const calendarStartFrom = defaultFilter.customFilters.find(item => item.key === 'calendarStartFrom')?.text;
            calendarStartFrom && setCalendarStartFrom(calendarStartFrom);
        }
    }, [defaultFilter]);

    const changeDepartment = (index, checked) => {
        const tempSettings = JSON.parse(JSON.stringify(settings));
        if (checked) {
            const indexAnyOn = settings.findIndex((department) => department.isOn);
            if (indexAnyOn !== -1) {
                tempSettings[indexAnyOn].isOn = false;
                tempSettings[indexAnyOn].areas = tempSettings[indexAnyOn].areas.map((item) => ({...item, isOn: false}));
            };
            tempSettings[index].isOn = true;
            tempSettings[index].areas = tempSettings[index].areas.map((item) => ({...item, isOn: true}));
            const settingData = {
                techDepartmentFilter: tempSettings[index].key,
                techAreaFilters: tempSettings[index].areas.map((area) => area.key),
                staffRoleFilters: [...defaultFilter.staffRoleFilters],
                customFilters: defaultFilter.customFilters,
            };
            mutateNewFilterSetting(settingData);
        }
    };

    const changeArea = (indexDepartment, index, checked) => {
        const tempSettings = JSON.parse(JSON.stringify(settings));
        tempSettings[indexDepartment].areas[index].isOn = checked;
        const settingData = {
            techDepartmentFilter: tempSettings[indexDepartment].key,
            techAreaFilters: tempSettings[indexDepartment].areas.filter((area) => area.isOn).map((area) => area.key),
            staffRoleFilters: [...defaultFilter.staffRoleFilters],
            customFilters: defaultFilter.customFilters,
        }
        mutateNewFilterSetting(settingData);
    };

    const changeStaffFilter = (index) => {
        const tempStaffRoles = JSON.parse(JSON.stringify(staffRoles));
        tempStaffRoles[index].isOn = !tempStaffRoles[index].isOn;
        const settingData = {
            techDepartmentFilter: defaultFilter.techDepartmentFilter,
            techAreaFilters: [...defaultFilter.techAreaFilters],
            staffRoleFilters: tempStaffRoles.filter((role) => role.isOn).map((role) => role.key),
            customFilters: defaultFilter.customFilters,
        }
        mutateNewFilterSetting(settingData);
    };

    const changeCalendarStartFrom = (value) => {
        const newSettings = modifyCustomFilters(defaultFilter, 'calendarStartFrom', value);
        mutateNewFilterSetting(newSettings);
    }

    return (
        <div className={classNames.root}>
            <SideMenuSmall />
            <div className={classNames.pageContentContainer}>
                <div className={classNames.pageInnerContainer}>
                    <Header title={`${userName}`} subTitle="OVERBLIK OVER AFDELINGER OG NOTIFIKATIONER"/> 
                    <div className={classNames.container}>
                        <div className={classNames.row}>
                            <div className={titleClassNames}>Tech Department</div>
                            <div className={titleClassNames}>Tech Area</div>
                        </div>
                        <Separator/>
                        {settings.map((department, index) => (
                            <Fragment key={index}>
                                <div className={classNames.row}>
                                    <div className={classNames.column}>
                                        <Toggle checked={department.isOn} onText={department.name} offText={department.name} styles={toggleStyle} onChange={(e, checked) => {changeDepartment(index, checked)}}/>
                                    </div>
                                    <div className={classNames.column}>
                                        {department.areas.map((area, index2) => (
                                            <div key={index2} className={classNames.column}>
                                                <Toggle checked={area.isOn} disabled={!department.isOn} onText={area.name} offText={area.name} styles={toggleStyle} onChange={(e, checked) => {changeArea(index, index2, checked)}}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Separator/>
                            </Fragment>
                        ))}

                        <div className={classNames.row}>
                            <div className={titleClassNames}>User Roles</div>
                            <div className={titleClassNames}>Calendar starting point</div>
                        </div>
                        <Fragment>
                            <div className={classNames.row}>
                                <div className={classNames.column}>
                                    <Stack horizontal="true" tokens={{ childrenGap: 8 }}> 
                                        {staffRoles.map((option, index) => (
                                            <TooltipForText key={option['key']} text={option.text} noWidth >
                                                <div className={option.isOn ? classNames.buttonSelected : classNames.button} onClick={() => {changeStaffFilter(index)}}>
                                                    <FontAwesomeIcon icon={option.icon} className={classNames.icon}/>
                                                </div>
                                            </TooltipForText>
                                        ))}
                                    </Stack>
                                </div>
                                <div className={classNames.column}>
                                    <ToggleButtonPill
                                        toggle={calendarStartFrom === 'week'}
                                        onClick={() => changeCalendarStartFrom(calendarStartFrom === 'month' ? "week" : "month")}
                                        leftText="Month"
                                        rightText="Week"
                                    />
                                </div>
                            </div>
                            <Separator/>
                        </Fragment>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    userName: `${state.user.user.firstName} ${state.user.user.lastName}`,
    defaultFilter: state.user.user.defaultFilter,
});

export default connect(mapStateToProps, {updateSettingFilter, postNewFilterSetting})(NotificationManagement);
