// import { monthsCapitalized } from './constants';
// Import libraries
import { MessageBarType } from "@fluentui/react";
import { datesGenerator } from "dates-generator";
import moment from "moment";
import { useLocation } from "react-router";

// Import actions
import { showConflictMessage } from "../redux/message/message.actions";

// Import constants
import {
  ACTIVITY_LOG_ENUM,
  ACTIVITY_LOG_TYPE_ENUM,
  PROJECT_CATEGORY,
  prodURLs,
  techDepartmentOptions,
} from "./constants";

// export const printDate = (date) => {
//     return `${date.getDate()}. ${monthsCapitalized[date.getMonth()]} ${date.getFullYear()}`;
// }

export const isProdUrl = () =>
  prodURLs.some((el) => window.location.href.includes(el));

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const copyAndSort = (items, key, isSortedDescending) => {
  return items.slice(0).sort((a, b) => {
    let compare1 = !isNaN(a[key]) ? +a[key] : a[key];
    let compare2 = !isNaN(b[key]) ? +b[key] : b[key];
    return (isSortedDescending ? compare1 < compare2 : compare1 > compare2)
      ? 1
      : -1;
  });
};

export const calculateFinalDates = (array, getCurrentWeekIndex = false) => {
  let currentWeekIndex = null;
  for (let i = 0; i < array.length - 1; i++) {
    const lastWeek1 = array[i].dates.length - 1;
    const lastDay1 = array[i].dates[lastWeek1][0]["date"];
    const lastDay2 = array[i + 1].dates[0][0]["date"];
    if (lastDay1 === lastDay2) {
      array[i + 1].dates.splice(0, 1);
    }
  }
  const finalDates = [];
  for (let i = 0; i < array.length; i++) {
    finalDates.push(...array[i].dates);
  }
  if (getCurrentWeekIndex) {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const tempFinalDates = JSON.parse(JSON.stringify(finalDates));
    tempFinalDates.shift();
    for (let i = 0; i < tempFinalDates.length; i++) {
      if (
        tempFinalDates[i].some(
          (item) =>
            item.date === currentDay &&
            item.month === currentMonth &&
            item.year === currentYear
        )
      ) {
        currentWeekIndex = i;
        break;
      }
    }
  }
  const start = new Date(
    array[0].dates[0][0]["year"],
    array[0].dates[0][0]["month"],
    array[0].dates[0][0]["date"]
  );
  const lastIndexWeekEnd = array[array.length - 1].dates.length - 1;
  const end = new Date(
    array[array.length - 1].dates[lastIndexWeekEnd][6]["year"],
    array[array.length - 1].dates[lastIndexWeekEnd][6]["month"],
    array[array.length - 1].dates[lastIndexWeekEnd][6]["date"]
  );
  return { finalDates, start, end, currentWeekIndex };
};

export const formatDate = (period) => {
  const start = moment(period[0]).format("YYYY-MM-DD[T]00:00:00.000");
  const end = moment(period[1]).format("YYYY-MM-DD[T]00:00:00.000");
  return [start, end];
};

export const dynamicSort = (properties, order = "asc") => {
  let sortOrder = 1;
  if (order === "desc") {
    sortOrder = -1;
  }
  return function (a, b) {
    // a should come before b in the sorted order
    if (properties.some((property) => a[property] < b[property])) {
      return -1 * sortOrder;
      // a should come after b in the sorted order
    } else if (properties.some((property) => a[property] > b[property])) {
      return 1 * sortOrder;
      // a and b are the same
    } else {
      return 0 * sortOrder;
    }
  };
};

export const startCalendarFromMonth = (date, firstWeek) => {
  const tempFirstWeek = firstWeek;
  for (let i = firstWeek.length - 1; i >= 0; i--) {
    const minDate = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
    const currentDate = new Date(
      firstWeek[i].year,
      firstWeek[i].month,
      firstWeek[i].date
    ).getTime();
    if (currentDate < minDate) tempFirstWeek.splice(i, 1);
  }
  return tempFirstWeek;
};

// Find the start of calendar if choose to start from selected week (avoid week/day different)
export const startCalendarFromSelectedWeek = (date, finalDates) => {
  const currentWeek = getWeekNumber(
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear()
  );
  const tempFinalDates = JSON.parse(JSON.stringify(finalDates));
  let stopLoop = false;
  for (let week of tempFinalDates) {
    if (stopLoop) break;
    for (let i = week.length - 1; i >= 0; i--) {
      const weekNumberOfDay = getWeekNumber(
        week[i].month + 1,
        week[i].date,
        week[i].year
      );
      const dateOfDay = new Date(week[i].year, week[i].month, week[i].date);
      if (
        weekNumberOfDay < currentWeek ||
        (weekNumberOfDay > currentWeek && dateOfDay.getTime() < date.getTime())
      )
        week.splice(i, 1);
      else {
        stopLoop = true;
        break;
      }
    }
  }
  return tempFinalDates.filter((item) => item.length);
};

export const removeFirstWeek = (dates, removeFirstWeek) => {
  let newDates = JSON.parse(JSON.stringify(dates));
  if (removeFirstWeek) newDates.splice(0, 1);
  return newDates;
};

export const getWeekNumber = (month, day, year) => {
  const date = `${day}-${month}-${year}`;
  var weeknumber = moment(date, "DMYYYY").isoWeek();
  return weeknumber;
};

export const getWeeksOfYear = (currentYear = new Date().getFullYear()) => {
  const array = new Array(12);
  array[0] = datesGenerator({ month: 0, year: currentYear, startingDay: 1 });
  for (let i = 1; i < array.length; i++) {
    array[i] = datesGenerator({
      month: array[i - 1].nextMonth,
      year: array[i - 1].nextYear,
      startingDay: 1,
    });
  }
  const { finalDates, currentWeekIndex } = calculateFinalDates(
    array,
    true /* getCurrentWeekIndex */
  );
  // finalDates.shift();
  const dt = finalDates[0][0];
  const date = new Date(dt.year, dt.month, dt.date);

  if (
    finalDates[0][6].year === currentYear &&
    new Date(date).getTime() === getMonday(date).getTime()
  ) {
  } else {
    finalDates.shift();
  }

  // This has to be done because we can't have 53 weeks
  // In some cases the first week is not thrown off
  if (
    finalDates.length > 52 &&
    finalDates[finalDates.length - 1][6].year !== currentYear
  ) {
    finalDates.splice(52, 1);
  }

  const now = new Date();
  const nowDay = now.getDate();
  const nowMonth = now.getMonth();
  const nowYear = now.getFullYear();

  const tempWeeks = [];
  for (let i = 0; i < finalDates.length; i++) {
   let isCurrentWeek = finalDates[i].some(
      (item) =>
        item.date === nowDay && item.month === nowMonth && item.year === nowYear
    );
    if (isCurrentWeek) {
      tempWeeks.push({
        isSelected: false,
        dates: finalDates[i],
        currentWeek: true,
        year: currentYear,
      });
    } else {
      tempWeeks.push({
        isSelected: false,
        dates: finalDates[i],
        year: currentYear,
      });
    }
  }
  return tempWeeks;
};

export const getMonday = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
};

export const getMonthNumberFromName = (monthName) => {
  return "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(monthName) / 3 + 1;
};

export const convertCamelCase = (string) => {
  const result = string.replace(/([a-z])([A-Z])/g, "$1 $2");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const findDifferent = (data, newData, key, usingDoubleQuotes = true) => {
  const doubleQuotes = usingDoubleQuotes ? '"' : "";
  if (!data) data = "";
  if (!newData) newData = "";
  if (typeof data === "string" || typeof data === "number") {
    if (data !== newData)
      return `Changed ${convertCamelCase(
        key
      )} from ${doubleQuotes}${data}${doubleQuotes} to ${doubleQuotes}${newData}${doubleQuotes}.`;
    else return null;
  } else if (Array.isArray(data)) {
    data = data.join(", ");
    newData = newData.join(", ");
    return findDifferent(data, newData, key);
  } else if (typeof data === "object") {
    const result = [];
    Object.keys(data).forEach((dataKey) => {
      const different = findDifferent(data[dataKey], newData[dataKey], key);
      if (different) result.push(different);
    });
    if (result.length) return result.join(", ");
  } else if (typeof data === "boolean") {
    if (data !== newData)
      return `Changed ${convertCamelCase(key)} from ${doubleQuotes}${
        data ? "Yes" : "No"
      }${doubleQuotes} to ${doubleQuotes}${
        newData ? "Yes" : "No"
      }${doubleQuotes}.`;
    else return null;
  }
};

export const checkTimeOverlap = (
  startDate,
  endDate,
  currentStartDate,
  currentEndDate,
  minusOneDay = true
) => {
  const startBeforeCurrentEnd = startDate.getTime() <= currentEndDate.getTime();
  const endAfterCurrentStart =
    endDate.getTime() >=
    currentStartDate.getTime() - (minusOneDay ? 86400000 : 0); //Minus one day
  const isOverLap = startBeforeCurrentEnd && endAfterCurrentStart;
  return isOverLap;
};

// Check for overlap machineService/staffVacation
export const handleBeforeSubmitPeriod = (
  bookedSchedules,
  targetInfo,
  period,
  dispatch
) => {
  let overlap = false;
  if (bookedSchedules) {
    const overlapSchedules = bookedSchedules.filter((schedule) => {
      const currentStartDate = new Date(schedule.start);
      const currentEndDate = new Date(schedule.end);
      const isOverLap = checkTimeOverlap(
        period[0],
        period[1],
        currentStartDate,
        currentEndDate
      );
      return isOverLap;
    });

    if (overlapSchedules.length) {
      overlap = true;
      dispatch(
        showConflictMessage(
          {
            [targetInfo.roleKey]: {
              [targetInfo.fullName]: overlapSchedules,
            },
          },
          MessageBarType.error
        )
      );
    }
  }
  return overlap;
};
/*
    params: 
        rgb: rgb string code (ex: '0, 107, 173')
        ratio: -100 to 100 (use negative to make darker color)
*/
export const lighterDarkerColor = (rgbString, ratio) => {
  const rgb = rgbString
    .replace(/ /g, "")
    .split(",")
    .map((item) => parseInt(item));
  return rgb
    .map((value) => ((value += ratio) < 0 ? 0 : value > 255 ? 255 : value | 0))
    .join(",");
};

export const addDaysToDate = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const findStaffSchedule = (staffId, projectId, role, allPlansData) => {
  const staffSchedule = [];
  allPlansData?.forEach((plan) => {
    plan.machineRequirements.forEach((requirement) => {
      requirement[role]?.forEach((staff) => {
        if (
          plan.projectId !== projectId &&
          staff.id === staffId &&
          !plan.inactive
        ) {
          const staffInfo = {
            projectId: plan.projectId,
            name: plan.projectName,
            color: plan.color,
            start: staff.start,
            end: staff.end,
          };
          staffSchedule.push(staffInfo);
        }
      });
    });
  });
  return staffSchedule;
};

export const findStaffBarVariant = (verticalPositions, id) => {
  const staffBarVariant = Object.values(verticalPositions[id]);
  const staffBarUniqueVariant = new Set(Object.values(staffBarVariant)).size;
  return staffBarUniqueVariant;
};

export const removeDupplicateInArray = (arrayData) => {
  const stringArrayData = arrayData.map((item) => JSON.stringify(item));
  const arrayUniqueData = [...new Set(stringArrayData)].map((item) =>
    JSON.parse(item)
  );
  return arrayUniqueData;
};

export const openInNewTab = (url) => {
  window.open(url, "_blank").focus();
};

export const projectBorder = (isAttention, isQuotation) => {
  let border = { borderRadius: 4, boxSizing: "border-box" };
  if (isAttention && isQuotation) {
    border = {
      ...border,
      borderTop: "4px solid #CB033F",
      borderRight: "4px solid #CB033F",
      borderBottom: "4px solid #E3B505",
      borderLeft: "4px solid #CB033F",
    };
  } else if (!isAttention && isQuotation) {
    border = {
      ...border,
      borderBottom: "4px solid #E3B505",
    };
  } else if (isAttention && !isQuotation) {
    border = {
      ...border,
      border: "4px solid #CB033F",
    };
  }
  return border;
};

export const setCookie = (name, value, expiresTime) => {
  const d = new Date();
  d.setTime(d.getTime() + expiresTime);
  let expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const updateProjectData = (
  data,
  newData,
  defaultData,
  currentUserId
) => {
  const different = [];
  Object.keys(data).forEach((key) => {
    let keyDataDifferent = null;
    switch (key) {
      case "personResponsbible":
        keyDataDifferent = findDifferent(
          data[key].name,
          typeof newData[key] === "string" ? newData[key] : newData[key]?.name,
          "personResponsible"
        );
        break;
      case "reason":
        keyDataDifferent = findDifferent(data[key], newData[key], "attention");
        break;
      case "geoCoordinate":
        keyDataDifferent = findDifferent(
          `${data[key]?.latitude || ""}, ${data[key]?.longitude || ""}`,
          `${newData[key]?.latitude || ""}, ${newData[key]?.longitude || ""}`,
          key
        );
        break;
      case "section":
        keyDataDifferent = findDifferent(
          defaultData?.sectionOptions.find((item) => item.key === data[key])
            ?.text,
          defaultData?.sectionOptions.find((item) => item.key === newData[key])
            ?.text,
          key
        );
        break;
      case "techDepartments":
        const techDepartments = data[key].map(
          (item) =>
            techDepartmentOptions.find((department) => department.key === item)
              .text
        );
        const newTechDepartments = newData[key].map(
          (item) =>
            techDepartmentOptions.find((department) => department.key === item)
              .text
        );
        keyDataDifferent = findDifferent(
          techDepartments,
          newTechDepartments,
          key
        );
        break;
      case "color":
        const color = data.color ? `rgb{${data.color}}` : "unset";
        const newColor = `rgb{${newData.color}}`;
        keyDataDifferent = findDifferent(
          color,
          newColor,
          key,
          false /*using double quotes*/
        );
        break;
      case "isQuotation":
        keyDataDifferent = findDifferent(
          data[key] ? "Yes" : "No",
          newData[key] ? "Yes" : "No",
          "Quotation"
        );
        break;
      case "start":
        const startText = moment(data.start, "YYYY-M-D").format("D.M.YY");
        const endText = moment(data.end, "YYYY-M-D").format("D.M.YY");
        const newStartText = moment(newData.start, "YYYY-M-D").format("D.M.YY");
        const newEndText = moment(newData.end, "YYYY-M-D").format("D.M.YY");
        keyDataDifferent = findDifferent(
          `${startText} - ${endText}`,
          `${newStartText} - ${newEndText}`,
          "period"
        );
        break;
      case "end":
        break;
      default:
        keyDataDifferent = findDifferent(data[key], newData[key], key);
        break;
    }
    if (keyDataDifferent) different.push(keyDataDifferent);
  });
  const personResponsbible = {
    userId: null,
    name:
      typeof newData.personResponsbible === "string"
        ? newData.personResponsbible
        : newData.personResponsbible?.name,
  };
  const activityLog = different.length
    ? {
        type: ACTIVITY_LOG_TYPE_ENUM.UPDATE,
        resourceId: data.projectId,
        resourceType: ACTIVITY_LOG_ENUM.PROJECT,
        userId: currentUserId,
        comment: different.join("\n"),
      }
    : null;
  const projectUpdatedData = {
    hovedsagsNummer: newData.hovedsagsNummer,
    projectId: newData.projectId,
    projectNo: newData.projectNo,
    projectName: newData.projectName,
    projectDescription: newData.projectDescription,
    color: newData.color,
    projectType: String(newData.projectType),
    start: newData.start,
    section: newData.section,
    end: newData.end,
    isQuotation: newData.isQuotation,
    reason: newData.reason,
    techDepartments: newData.techDepartments,
    geoCoordinate: newData.geoCoordinate,
    redirectToMachineReq: !!newData.redirectToMachineReq,
    personResponsbible,
    activityLog,
  };
  return projectUpdatedData;
};

export const crmLink = (projectId, category) => {
  let etn =
    category === PROJECT_CATEGORY.Aftaleopgave.key
      ? "paa_rammeaftaleopgave"
      : "paa_projekt";

  return `https://aarsleff.crm4.dynamics.com/main.aspx?appid=ea908395-6d2b-4e16-ad33-2f7e5853f0e1&forceUCI=1&pagetype=entityrecord&etn=${etn}&id=${projectId}`;
};

export const modifyCustomFilters = (defaultFilter, key, value) => {
  let newCustomFilters = defaultFilter.customFilters;
  const zoomRatio = defaultFilter.customFilters?.find(
    (item) => item.key === key
  );
  if (zoomRatio) {
    const indexOfZoomRatio = defaultFilter.customFilters
      .map((item) => JSON.stringify(item))
      .indexOf(JSON.stringify(zoomRatio));
    newCustomFilters[indexOfZoomRatio] = { key: key, text: value };
  } else {
    newCustomFilters = [
      ...(defaultFilter.customFilters || []),
      { key: key, text: value },
    ];
  }
  const newSettings = {
    ...defaultFilter,
    customFilters: newCustomFilters,
  };
  return newSettings;
};

export const elementObserver = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const getTextWidth = (text, fontProp) => {
  var tag = document.createElement("div");
  tag.style.position = "absolute";
  tag.style.left = "-99in";
  tag.style.whiteSpace = "nowrap";
  tag.style.font = fontProp;
  tag.innerHTML = text;

  document.body.appendChild(tag);
  var result = tag.clientWidth;
  document.body.removeChild(tag);
  return result;
};

export const getCalendarHoliday = (dates, holidays) => {
  let datesClone = dates.map((week) => {
    week = week.map((day) => {
      let isHoliday = false;
      let holidayName = "";
      const dateString = moment(
        new Date(day.year, day.month, day.date),
        "YYYY-M-D"
      ).format("YYYY-MM-DD");
      const holiday = holidays?.find((item) => item.date === dateString);
      if (holiday && holiday?.public) {
        isHoliday = true;
        holidayName = holiday.name;
      }
      return { ...day, isHoliday, holidayName };
    });
    return week;
  });
  return datesClone;
};

export const findYearOfDates = (dates) => {
  if (!dates.length) return [];
  const years = [];
  const firstYear = dates[0][0].year;
  years.push(firstYear);
  const secondYear = getLastDate(dates).year;
  if (firstYear !== secondYear) years.push(secondYear);
  return years;
};

export const getLastDate = (dates) => dates.slice(-1).slice(-1)[0].slice(-1)[0];

export const getMonthIndicator = (dates, months) => {
  let components = [];
  let temp = dates[0][0]["month"];
  let counter = 0;
  for (let i = 0; i < dates.length; i++) {
    for (let j = 0; j < dates[i].length; j++) {
      if (dates[i][j]["month"] === temp) {
        counter++;
      } else {
        components.push({
          length: counter,
          label: months[temp],
          year:
            dates[i][j].month === 0 ? dates[i][j].year - 1 : dates[i][j].year,
        });
        counter = 1;
        temp = dates[i][j]["month"];
      }
    }
  }
  components.push({
    length: counter,
    label: months[temp],
    year: getLastDate(dates).year,
  });
  return components;
};

export const generateUniqueId = (str = "") => {
  return Math.floor(10000 * Math.random()) + "-" + Date.now() + str;
};
