// Import libraries
import { Spinner, SpinnerSize, mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import { app, pages } from "@microsoft/teams-js";

// Import utils
import { isProdUrl } from "../../utils/utils";

const classNames = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
  },
});

const TeamsConfiguration = () => {
  //   const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const initialize = async () => {
      try {
        // setIsInitializing(true);
        await app.initialize();
        // const ctx = await app.getContext();

        // setCtxFrameData(app.getFrameContext());

        // if (ctx.user?.tenant?.id) {
        //   setTid(ctx.user.tenant.id);
        //   setCtxData(ctx.user);
        // }

        setIsInitialized(true);
      } catch (error) {
        setError((error as Error)?.message || "Failed to initialize");
      } finally {
        // setIsInitializing(false);
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      pages.config.registerOnSaveHandler((saveEvent) => {
        const isProd = isProdUrl();

        const host = "https://" + window.location.host + "/login-teams";

        const configPromise = pages.config.setConfig({
          websiteUrl: host,
          contentUrl: host,
          entityId: isProd ? "Aarsleff White" : "Aarsleff White Staging",
          suggestedDisplayName: "Fleetplanner White",
        });
        configPromise
          .then((result) => {
            saveEvent.notifySuccess();
          })
          .catch((error) => {
            saveEvent.notifyFailure("failure message");
          });
      });

      pages.config.setValidityState(true);
      app.notifySuccess();
    }
  }, [isInitialized]);

  return (
    <div className={classNames.container}>
      {isInitialized ? (
        <></>
      ) : (
        <Spinner
          size={SpinnerSize.large}
          label="Initializing Aarsleff tab"
          labelPosition="bottom"
        />
      )}
      <div
        style={{
          fontSize: 10,
          color: "red",
          textAlign: "center",
          marginBottom: -10,
        }}
      >
        {error}
      </div>
    </div>
  );
};

export default TeamsConfiguration;
