import { useLayoutEffect } from 'react';
import { useState } from 'react';
import useDebounce from './useDebounce';

const useScrollAndResize = () => {
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    const debouncedScrollX = useDebounce(scrollX,250);
    const debouncedScrollY = useDebounce(scrollY,250);
    const debouncedPageHeight = useDebounce(pageHeight,250);
    const debouncedPageWidth = useDebounce(pageWidth,250);

    useLayoutEffect(
        () => {
            const handleScroll = () => {
                const scrollPositionX = window.pageXOffset;
                const scrollPositionY = window.pageYOffset;
                setScrollX(scrollPositionX);
                setScrollY(scrollPositionY);
            };
        
            const handleResize = () => {
                setPageWidth(window.innerWidth);
                setPageHeight(window.innerHeight);
            };

            // Set up
            window.addEventListener('scroll',handleScroll,{ passive: true });
            window.addEventListener('resize',handleResize,{ passive: true });

            // Clean up
            return () => {
                window.removeEventListener('scroll',handleScroll);
                window.removeEventListener('resize',handleResize);
            };
        },[]
    );

    return { 
        pageWidth: debouncedPageWidth, 
        pageHeight: debouncedPageHeight, 
        scrollY: debouncedScrollY, 
        scrollX: debouncedScrollX
    };
}

export default useScrollAndResize;