// Import libraries
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Stack, TeachingBubble } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";

// Import redux
import { setFilter } from "../../../../redux/machines/machines.actions";
import { toggleSelectorItem } from "../../../../redux/machines/machines.utils";

// Import classes
import { classNames } from "./SelectorStyles";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface ToggleableSelectorProps {
  filterKey: any;
  allOptions: any;
  availableOptions: any;
  selectedOptions: any;
}

const ToggleableSelector = ({
  filterKey,
  allOptions,
  availableOptions,
  selectedOptions,
}: ToggleableSelectorProps) => {
  const dispatch = useDispatch();

  const [showSelectorMenu, setShowSelectorMenu] = useState<{
    id: string;
    key: number;
  } | null>(null);

  return (
    <>
      <div className={classNames.root}>
        <div className={classNames.techDepartmentSection}>
          <Stack horizontal tokens={{ childrenGap: 8 }} wrap={true}>
            {allOptions
              ?.filter((section: any) => availableOptions.includes(section.key))
              .map((option: any) => (
                <div
                  key={option["key"]}
                  className={
                    selectedOptions?.includes(option["key"])
                      ? classNames.buttonSelected
                      : classNames.button
                  }
                  onClick={() => {
                    dispatch(
                      setFilter({
                        [filterKey]: toggleSelectorItem(
                          selectedOptions,
                          option["key"]
                        ),
                      })
                    );
                  }}
                >
                  {option["text"]}{" "}
                  <span>
                    <FontAwesomeIcon
                      id={`selector-${option["key"]}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSelectorMenu({
                          id: `selector-${option["key"]}`,
                          key: option["key"],
                        });
                      }}
                      icon={faSquareEllipsisVertical as IconDefinition}
                    />
                  </span>
                </div>
              ))}
          </Stack>
        </div>
      </div>
      {showSelectorMenu ? (
        <TeachingBubble
          styles={{
            body: {
              // width: 424,
              // height: 300,
              display: "flex",
              alignContent: "center",
            },
            bodyContent: {
              padding: 20,
              // paddingBottom: 20,
            },
          }}
          closeButtonAriaLabel="Close"
          target={`#${showSelectorMenu.id}`}
          onDismiss={() => {
            setShowSelectorMenu(null);
          }}
          calloutProps={{
            // calloutHeight: 277,
            calloutWidth: 160,
          }}
        >
          <div
            style={{
              cursor: "pointer",
              fontWeight: "600",
              fontSize: 12,
              color: "black",
            }}
            onClick={(e) => {
              e.stopPropagation();

              dispatch(
                setFilter({
                  selectedTechDepartmentList: [showSelectorMenu.key],
                })
              );

              setShowSelectorMenu(null);
            }}
          >
            Select Only This Sektion
          </div>
        </TeachingBubble>
      ) : (
        <></>
      )}
    </>
  );
};

export default ToggleableSelector;
