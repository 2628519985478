import { useBoolean } from "@fluentui/react-hooks";
import {
  DefaultButton,
  DirectionalHint,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  faCalendarAlt,
  faMinus,
  faPencil,
  faPlus,
  faSignOut,
  faUmbrellaBeach,
  faUserTie,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { Calendar, DateRangeType } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBarDrawer } from "../../../redux/app/app.actions";
import { toggleDisplayMachineService } from "../../../redux/machines/machines.actions";
import { primaryButtonStyles } from "../../../utils/theme";
import { getMonday, modifyCustomFilters } from "../../../utils/utils";
import FixPositionBubble from "../FixPositionBubble";
import TooltipForText from "../TooltipForText";
import { buttonStyle } from "./ButtonStyles";
import WeekCalendar from "./WeekCalendar";
import ToggleButtonPill from "../ToggleButtonPill";
import { useMutation } from "react-query";
import { postNewFilterSetting } from "../../../redux/settingManagement/settingManagement.action";
import { logout, updateSettingFilter } from "../../../redux/user/user.actions";
import { useAccount, useMsal } from "@azure/msal-react";
import { ZOOM_CONTROL } from "../../../utils/constants";
import { useScrollAndResize } from "../../../hooks";

const iconStyle = {
  marginRight: 2,
  fontSize: 16,
};

const classNames = mergeStyleSets({
  controls: {
    marginTop: 30,
    position: "fixed",
    zIndex: 1004,
    left: 600,
  },
  newPrebookingSelection: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    width: 85,
    height: 32,
    fontFamily: "Verdana",
    cursor: "pointer",
    marginLeft: -5,
    selectors: {
      ":hover": {
        backgroundColor: "#99b3ff",
        borderRadius: 2,
      },
    },
  },
  unSelectedIcon: {
    ...iconStyle,
    color: "#006CAD",
  },
  selectedIcon: {
    ...iconStyle,
    color: "#fff",
  },
  userName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
  },
  signOut: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
    cursor: "pointer",
    color: "#006CAD",
    fontSize: 24,
    marginTop: 20,
    width: "100%",
  },
});

const zoomIconStyle = (enable) => ({
  marginRight: 2,
  fontSize: 16,
  color: enable ? "#006CAD" : "#C8C8C8",
  cursor: enable ? "pointer" : "default",
});

const CalendarControlsRight = ({ onClickChooseDate }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { defaultFilter } = user;
  const userZoomRatio = 1;
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [showDrawVacation, { toggle: toggleDrawVacation }] = useBoolean(false);
  const [showZoomBubble, { toggle: toggleShowZoomBubble }] = useBoolean(false);
  const [showUserPopup, { toggle: toggleTeachingShowUserPopup }] =
    useBoolean(false);
  const [
    teachingBubbleShowChooseMonth,
    { toggle: toggleTeachingShowChooseMonth },
  ] = useBoolean(false);
  const calendarStartFrom =
    useSelector((state) => state.user.user.defaultFilter.customFilters)?.find(
      (item) => item.key === "calendarStartFrom"
    )?.text || "month";
  const { selectedDayOnCalendar } = useSelector((state) => state.machine);

  const { scrollX, pageWidth } = useScrollAndResize();

  useEffect(() => {
    const el = document.getElementById("calendarRightControl");

    // el.style.left = `${scrollX + pageWidth - 400}px`;
  }, [scrollX, pageWidth]);

  useEffect(() => {
    try {
      if (!account && inProgress === "none") {
        logout();
      }
    } catch (err) {
      console.log(err);
    }
  }, [account, inProgress, instance]);

  const { mutate: mutateNewFilterSetting } = useMutation(
    (settingData) => dispatch(postNewFilterSetting(settingData, false)),
    {
      onSuccess: (data, settingData) => {
        if (data) {
          dispatch(updateSettingFilter(settingData));
        }
      },
    }
  );

  const handleTodayClick = () => {
    const monday = getMonday(new Date());
    onClickChooseDate(monday);
  };

  const handleZoom = (type) => {
    const nextValue =
      ZOOM_CONTROL[
        ZOOM_CONTROL.indexOf(userZoomRatio) + (type === "zoom in" ? 1 : -1)
      ];
    if (nextValue) changeDefaultZoomValue(nextValue);
  };

  const changeDefaultZoomValue = (value) => {
    const newSettings = modifyCustomFilters(defaultFilter, "zoomRatio", value);
    mutateNewFilterSetting(newSettings);
  };

  useEffect(() => {
    if (teachingBubbleShowChooseMonth) {
      // wait for calendar to be visible
      setTimeout(() => {
        const goTodayButton = document.getElementsByClassName("js-goToday");
        for (let i = 0; i < goTodayButton.length; i++) {
          goTodayButton[i].textContent = "Go to current month";
          goTodayButton[i].addEventListener("click", handleTodayClick);
        }
      }, 50);
    }
  }, [teachingBubbleShowChooseMonth]);

  const changeCalendarStartFrom = (value) => {
    const newSettings = modifyCustomFilters(
      defaultFilter,
      "calendarStartFrom",
      value
    );
    mutateNewFilterSetting(newSettings);
  };

  const onLogout = async () => {
    try {
      dispatch(logout());
      await instance.logoutRedirect({ postLogoutRedirectUri: "/login" });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div
        style={{
          transition: "all 0.4s",
        }}
        id="calendarRightControl"
        className={classNames.controls}
      >
        <Stack horizontal wrap tokens={{ childrenGap: 30 }}>
          <Stack horizontal>
            <TooltipForText text={"New Holiday For staff"}>
              <PrimaryButton
                id="newVacation"
                text="New"
                onClick={toggleDrawVacation}
                styles={{
                  ...primaryButtonStyles,
                  root: { ...primaryButtonStyles.root, marginRight: 15 },
                }}
              >
                <FontAwesomeIcon
                  style={{ marginLeft: "5px" }}
                  icon={faUmbrellaBeach}
                />
              </PrimaryButton>
            </TooltipForText>
            <TooltipForText text="Choose month" noWidth>
              <DefaultButton
                id="showChooseMonth"
                styles={buttonStyle(false)}
                onClick={toggleTeachingShowChooseMonth}
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className={classNames.unSelectedIcon}
                />
              </DefaultButton>
            </TooltipForText>
            <DefaultButton
              id="userIcon"
              styles={buttonStyle(false)}
              onClick={toggleTeachingShowUserPopup}
            >
              <FontAwesomeIcon
                icon={faUserTie}
                className={classNames.unSelectedIcon}
              />
            </DefaultButton>
          </Stack>
        </Stack>
      </div>

      {/* Choose month bubble */}
      {teachingBubbleShowChooseMonth && (
        <FixPositionBubble
          target={
            document.getElementById("showActions")
              ? "#showActions"
              : "#showChooseMonth"
          }
          onDismiss={toggleTeachingShowChooseMonth}
          calloutProps={{
            calloutWidth: calendarStartFrom === "month" ? 270 : 400,
          }}
          mainPositionRight="274.75px"
        >
          {calendarStartFrom === "month" ? (
            <Calendar
              dateRangeType={DateRangeType.Month}
              value={selectedDayOnCalendar}
              showGoToToday
              highlightSelectedMonth
              isDayPickerVisible={false}
              onSelectDate={onClickChooseDate}
            />
          ) : (
            <WeekCalendar onClickChooseDate={onClickChooseDate} />
          )}
        </FixPositionBubble>
      )}

      {/* New vacation bubble */}
      {showDrawVacation && (
        <FixPositionBubble
          target={"#newVacation"}
          onDismiss={toggleDrawVacation}
          calloutProps={{
            directionalHint: DirectionalHint.bottomCenter,
            calloutWidth: 140,
            calloutheight: 50,
          }}
          mainPositionRight="323.75px"
          beakPositionRight="60px"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className={classNames.newPrebookingSelection}
              onClick={() => {
                toggleDrawVacation();
                dispatch(
                  setBarDrawer({
                    barDrawer: "staffVacation",
                    vacationReason: 1,
                  })
                );
              }}
            >
              &ensp;
              <FontAwesomeIcon
                icon={faPencil}
                className={classNames.unSelectedIcon}
                style={{ marginRight: 10 }}
              />
              Ferie
            </div>
            <div
              className={classNames.newPrebookingSelection}
              onClick={() => {
                toggleDrawVacation();
                dispatch(
                  setBarDrawer({
                    barDrawer: "staffVacation",
                    vacationReason: 0,
                  })
                );
              }}
            >
              &ensp;
              <FontAwesomeIcon
                icon={faPencil}
                className={classNames.unSelectedIcon}
                style={{ marginRight: 10 }}
              />
              Fravær
            </div>
          </div>
        </FixPositionBubble>
      )}

      {/* Zoom bubble */}
      {showZoomBubble && (
        <FixPositionBubble
          target={
            document.getElementById("showActions")
              ? "#showActions"
              : "#pageZoom"
          }
          onDismiss={toggleShowZoomBubble}
          mainPositionRight={"193px"}
          beakPositionRight={"104.5px"}
          calloutProps={{
            directionalHint: DirectionalHint.bottomCenter,
            calloutWidth: 250,
            calloutheight: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#000",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "40%",
                fontFamily: "Verdana",
                fontSize: 14,
                userSelect: "none",
              }}
            >
              {userZoomRatio * 100}%
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "space-around",
                marginRight: 10,
              }}
            >
              <div onClick={() => handleZoom("zoom out")}>
                <FontAwesomeIcon
                  icon={faMinus}
                  style={zoomIconStyle(
                    ZOOM_CONTROL[ZOOM_CONTROL.indexOf(userZoomRatio) - 1]
                  )}
                />
              </div>
              <div onClick={() => handleZoom("zoom in")}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={zoomIconStyle(
                    ZOOM_CONTROL[ZOOM_CONTROL.indexOf(userZoomRatio) + 1]
                  )}
                />
              </div>
            </div>
            <div style={{ width: "30%", marginRight: 10 }}>
              <DefaultButton
                onClick={() => changeDefaultZoomValue(1)}
                styles={{
                  root: {
                    borderRadius: 4,
                    height: 30,
                    width: 40,
                    borderColor: "#006CAD",
                    color: "#006CAD",
                    backgroundColor: "#fff",
                  },
                  label: { fontWeight: 200 },
                  rootHovered: {
                    backgroundColor: "#99b3ff",
                  },
                }}
              >
                Reset
              </DefaultButton>
            </div>
          </div>
        </FixPositionBubble>
      )}

      {showUserPopup && (
        <FixPositionBubble
          target={"#userIcon"}
          onDismiss={toggleTeachingShowUserPopup}
          calloutProps={{
            directionalHint: DirectionalHint.rightTopEdge,
            calloutWidth: 400,
            calloutheight: 50,
          }}
          mainPositionRight="208.314px"
        >
          <div>
            {/* <div className={classNames.avatarOverlay}></div>
                        <Persona
                            className={classNames.avatar}
                            onClick={() => {
                                history.push('/notification-management');
                            }}
                            imageUrl={user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ''}
                            size={PersonaSize.size72}
                        /> */}
            <div className={classNames.userName}>
              {user.firstName} {user.lastName}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <div className={classNames.title}>Calendar starting point</div>
              <ToggleButtonPill
                toggle={calendarStartFrom === "week"}
                onClick={() =>
                  changeCalendarStartFrom(
                    calendarStartFrom === "month" ? "week" : "month"
                  )
                }
                leftText="Month"
                rightText="Week"
              />
            </div>
            <div className={classNames.signOut} onClick={onLogout}>
              <TooltipForText text={"Logout"}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon
                    id="logout"
                    className={classNames.unSelectedIcon}
                    icon={faSignOut}
                  />
                </div>
              </TooltipForText>
            </div>
            {/* {logoutConfirmation && (
                            <DeleteConfirmation
                                label='Are you sure you want to logout?'
                                target={`#logout`}
                                toggleTeaching={toggleLogoutConfirmation}
                                onClickConfirm={onLogout}
                            />
                        )} */}
          </div>
        </FixPositionBubble>
      )}
    </Fragment>
  );
};

export default CalendarControlsRight;
