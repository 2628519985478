// Import libraries
import { mergeStyleSets } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState, useCallback } from "react";

// Import types
import { TWeeks } from "../../../types/dates";
import { TStaff } from "../../../types/staff";
import { TProject } from "../../../types/project";

// Import components
import CalendarBackground from "../../common/CalendarBackground";
import ProjectList from "../../project/ProjectList";
import EffortBarDrawer from "../../common/EffortBarDrawer";

// Import redux
import { clearDrawEffort } from "../../../redux/app/app.actions";

// Import utils
import { generateProjectMap } from "../../../utils/map";
import {
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  HEADER_HEIGHT,
  ITEM_HEIGHT,
} from "../../../utils/constants";
import { THorizontalPositionMap } from "../../../types/positionmap";
import { TCalendar } from "../../../types/calendar";
import EffortBar from "../../common/EffortBar";
import FerieBar from "../../common/FerieBar";
import FerieBarDrawer from "../../common/FerieBarDrawer";

const classNames = mergeStyleSets({
  projectListContainer: {
    display: "flex",
    gap: 16,
    flexDirection: "column",
    padding: "16px 11px 0px",
  },
  peopleNameContainer: {
    height: HEADER_HEIGHT,
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    fontSize: 16,
    color: "#006CAD",
    cursor: "pointer",
  },
  leftContainer: {
    width: 250,
    position: "sticky",
    zIndex: 1002,
    top: 0,
    left: 0,
    paddingLeft: 30,
    background: "rgb(241, 241, 241)",
    transition: "all 0.5s ease",
  },
  machineContainer: {
    width: 250,
    minHeight: "100%",
    marginLeft: 0,
    // marginTop: 30,
    background: "#f1f1f1",
  },
  machineAndCalendarContainer: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "flex-start",
    background: "rgb(241, 241, 241)",
  },
  calendarContainer: {
    minHeight: "100%",
    marginLeft: 0,
    transition: "all 0.5s ease",
  },
  projectContainer: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid black",
  },
  projectNameContainer: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: ITEM_HEIGHT,
    borderBottom: "1px solid #DBDBDB",
  },
  addContainer: {
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: ITEM_HEIGHT,
    borderBottom: "1px solid #DBDBDB",
  },
});

export type TAddUserData = {
  projectId: string;
  selectedStaff: {
    techDepartment: number;
    techArea: number;
    firstName: string;
    lastName: string;
    role: string;
    userId: string;
    imageUrl: string;
  };
};

type ProjectProps = {
  dates: TWeeks[];
  calendarStartFrom: string;
  displayWeekView: boolean;
  totalHeight: number;
  calendarStepWidth: number;
  staffsList: TStaff[];
  projectsData: TProject[];
  horizontalPositionMap: THorizontalPositionMap;
  color: string;
  calendar: TCalendar;
};

const HoritonzalLine = ({ yPosition }: { yPosition: number }) => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      top: yPosition,
      borderBottom: "1px solid #c5c5c5",
    }}
  />
);

const Project = ({
  dates,
  calendarStartFrom,
  displayWeekView,
  calendarStepWidth,
  staffsList,
  projectsData,
  horizontalPositionMap,
  color,
  calendar,
}: ProjectProps) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const { barDrawer } = useSelector((state) => state.app) as {
    barDrawer: string;
  };

  // @ts-ignore
  const { drawUserEffortId } = useSelector((state) => state.app);

  const [addUserData, setAddUserData] = useState<TAddUserData>();

  // @ts-ignore
  const filter = useSelector((state) => state.machine.filter);

  // We don't want the project id to be persisted
  useEffect(() => {
    return () => {
      dispatch(clearDrawEffort());
    };
  }, [dispatch]);

  const staffNames = filter["staffNames"] || "";
  const projectNames = filter["projectNames"] || "";

  // This is to ensure draw vacation and draw effor do not contradict each other
  useEffect(() => {
    if (drawUserEffortId) {
      setAddUserData(undefined);
    }
  }, [drawUserEffortId]);

  useEffect(() => {
    if (addUserData) {
      dispatch(clearDrawEffort());
    }
  }, [addUserData, dispatch]);

  const {
    projectMap: projects,
    userIdToData,
    usersData,
  } = useMemo(
    () =>
      generateProjectMap({
        projectsData,
        staffsList,
        addUserData,
        staffNames,
        projectNames,
      }),
    [projectsData, staffsList, addUserData, staffNames, projectNames]
  );

  const onClickAddUser = ({
    selectedStaff,
    projectId,
  }: {
    selectedStaff: {
      techDepartment: number;
      techArea: number;
      firstName: string;
      lastName: string;
      role: string;
      userId: string;
      imageUrl: string;
    };
    projectId: string;
  }) => {
    setAddUserData({
      selectedStaff,
      projectId,
    });
  };

  const clearAddUserData = useCallback(() => {
    setAddUserData(undefined);
  }, []);

  const renderAddUserEffortBar = useCallback(() => {
    if (!addUserData) {
      return <></>;
    }

    const yPosition = projects.idToData[addUserData.projectId].yPosition;

    if (!yPosition) {
      return <></>;
    }

    const {
      selectedStaff: { userId },
      projectId,
    } = addUserData;

    const user = projects.idToData[projectId].users.find(
      (user) => user.userId === userId
    );

    if (!user) {
      return <></>;
    }

    const { efforts, staffVacations } = user;

    return (
      addUserData && (
        <EffortBarDrawer
          onDrawingCancelled={clearAddUserData}
          height={CALENDAR_STAFFVIEW_STEP_HEIGHT}
          yPosition={user.yPosition}
          calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
          calendarStepWidth={calendarStepWidth}
          horizontalPositionMap={horizontalPositionMap}
          color={projects.idToData[addUserData.projectId].projectData.color}
          projectId={addUserData.projectId}
          userId={addUserData.selectedStaff.userId}
          efforts={efforts}
          staffVacations={staffVacations}
        />
      )
    );
  }, [
    clearAddUserData,
    addUserData,
    projects,
    horizontalPositionMap,
    calendarStepWidth,
  ]);

  const renderEditProjectEffort = useCallback(() => {
    if (!drawUserEffortId) {
      return <></>;
    }

    const { userId, projectId } = drawUserEffortId as {
      userId: string;
      projectId: string;
    };

    const project = projects.idToData[projectId];
    // const user = projects.idToData[projectId]?.users?.find((user) => user.userId === userId);

    if (!project) {
      return <></>;
    }

    const { color } = project.projectData;

    const user = projects.idToData[projectId].users.find(
      (user) => user.userId === userId
    );

    if (!user) {
      return <></>;
    }

    const { efforts, staffVacations } = user;

    return (
      <EffortBarDrawer
        calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        calendarStepWidth={calendarStepWidth}
        onDrawingCancelled={() => {
          dispatch(clearDrawEffort());
        }}
        yPosition={user.yPosition}
        height={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        horizontalPositionMap={horizontalPositionMap}
        color={color}
        userId={userId}
        projectId={projectId}
        efforts={efforts}
        staffVacations={staffVacations}
      />
    );
  }, [
    drawUserEffortId,
    projects,
    calendarStepWidth,
    dispatch,
    horizontalPositionMap,
  ]);

  const renderEfforts = useCallback(() => {
    const arr: JSX.Element[] = [];

    projects.data.forEach(({ efforts, projectData, users }) => {
      const { projectId, color } = projectData;
      efforts.forEach((effort) => {
        const { percentage, start, end, userId, id } = effort;

        const user = users.find((user) => user.userId === userId);

        if (user) {
          const { staffVacations, yPosition } = user;

          arr.push(
            <EffortBar
              allocation={percentage}
              calendar={calendar}
              calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
              color={color}
              end={end}
              start={start}
              horizontalPositionMap={horizontalPositionMap}
              userId={userId}
              yPosition={yPosition}
              key={`${end}-${start}-${userId}-${projectId}`}
              efforts={efforts.filter((el) => el.userId === userId)}
              staffVacations={staffVacations}
              effortId={id}
              projectId={projectId}
            />
          );
        }
      });
    });

    return arr;
  }, [projects, calendar, horizontalPositionMap]);

  const renderFerie = useCallback(() => {
    const arr: JSX.Element[] = [];

    staffsList.forEach((staff) => {
      const { starfVacations, userId, efforts } = staff;

      if (starfVacations) {
        starfVacations.forEach((staffVacation, idx) => {
          const { end, start } = staffVacation;

          const user = userIdToData[userId];

          if (user) {
            user.yPositions.forEach((yPosition, positionIndex) => {
              arr.push(
                <FerieBar
                  calendar={calendar}
                  calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                  end={end}
                  start={start}
                  height={ITEM_HEIGHT}
                  horizontalPositionMap={horizontalPositionMap}
                  staff={staff}
                  staffVacation={staffVacation}
                  staffVacationIndex={idx}
                  userId={userId}
                  yPosition={yPosition}
                  id={`staff-vacation-${userId}-${idx}-${yPosition}-${positionIndex}`}
                  key={`staff-vacation-${userId}-${idx}-${positionIndex}`}
                  efforts={efforts}
                  staffVacations={starfVacations}
                />
              );
            });
          }
        });
      }
    });

    return arr;
  }, [staffsList, userIdToData, calendar, horizontalPositionMap]);

  const renderFerieBarDrawer = useCallback(() => {
    if (!barDrawer || !usersData) {
      return <></>;
    }

    return (
      <FerieBarDrawer
        calendar={calendar}
        calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        calendarStepWidth={calendarStepWidth}
        horizontalPositionMap={horizontalPositionMap}
        staffsData={staffsList}
        height={ITEM_HEIGHT}
        peopleData={usersData}
      />
    );
  }, [
    barDrawer,
    usersData,
    calendar,
    calendarStepWidth,
    horizontalPositionMap,
    staffsList,
  ]);

  const renderAddUserFerie = useCallback(() => {
    const arr: JSX.Element[] = [];

    if (!addUserData) {
      return arr;
    }

    const { selectedStaff } = addUserData;

    const { userId } = selectedStaff;

    const staff = staffsList.find((el) => el.userId === userId);
    const userData = usersData.find((el) => el.userId === userId);

    if (!staff || !userData) {
      return arr;
    }

    const { efforts } = userData;
    const { starfVacations } = staff;

    if (starfVacations) {
      starfVacations.forEach((staffVacation, idx) => {
        const { end, start } = staffVacation;

        const user = userIdToData[userId];

        if (user) {
          user.yPositions.forEach((yPosition, positionIndex) => {
            arr.push(
              <FerieBar
                calendar={calendar}
                calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                end={end}
                start={start}
                height={ITEM_HEIGHT}
                horizontalPositionMap={horizontalPositionMap}
                staff={staff}
                staffVacation={staffVacation}
                staffVacationIndex={idx}
                userId={userId}
                yPosition={yPosition}
                id={`add-user-staff-vacation-${userId}-${idx}-${yPosition}-${positionIndex}`}
                key={`add-user-staff-vacation-${userId}-${idx}-${positionIndex}`}
                efforts={efforts}
                staffVacations={starfVacations}
              />
            );
          });
        }
      });
    }

    return arr;
  }, [
    addUserData,
    staffsList,
    usersData,
    calendar,
    horizontalPositionMap,
    userIdToData,
  ]);

  const renderHorizontalLines = useCallback(() => {
    const arr: JSX.Element[] = [];

    if (projects.data.length) {
      arr.push(<HoritonzalLine key={"Start"} yPosition={16} />);
    }

    projects.data.forEach((project) => {
      const { users, yPosition, projectData } = project;

      const { projectId } = projectData;

      // Draw top and bottom of the project
      arr.push(
        <HoritonzalLine
          key={"top-" + projectId + "" + yPosition}
          yPosition={yPosition}
        />
      );

      arr.push(
        <HoritonzalLine
          key={"bottom-" + projectId + "" + (yPosition + HEADER_HEIGHT)}
          yPosition={yPosition + HEADER_HEIGHT}
        />
      );

      users.forEach(({ userId, yPosition }) => {
        arr.push(
          <HoritonzalLine
            key={`${userId}-${projectId}-${yPosition}`}
            yPosition={yPosition}
          />
        );
        arr.push(
          <HoritonzalLine
            key={`${userId}-${projectId}-${yPosition + ITEM_HEIGHT}`}
            yPosition={yPosition + ITEM_HEIGHT}
          />
        );
      });
    });
    return arr;
  }, [projects]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div className={classNames.leftContainer}>
        <div className={classNames.machineContainer}>
          <div className={classNames.projectListContainer}>
            {projects.data.map(({ projectData, users }) => (
              <ProjectList
                key={projectData.projectId}
                projectData={projectData}
                users={users}
                staffsList={staffsList}
                onClickAddUser={onClickAddUser}
                selectedUserId={
                  projectData.projectId === addUserData?.projectId
                    ? addUserData?.selectedStaff?.userId
                    : projectData.projectId === drawUserEffortId?.projectId
                    ? drawUserEffortId.userId
                    : undefined
                }
              />
            ))}
          </div>
        </div>
      </div>
      <div className={classNames.machineAndCalendarContainer}>
        <div className={classNames.calendarContainer}>
          <CalendarBackground
            dates={dates}
            calendarStartFrom={calendarStartFrom}
            displayWeekView={displayWeekView}
            totalHeight={projects.height}
            calendarStepWidth={calendarStepWidth}
          >
            <div
              id="planContainer"
              className="planContainer"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 556,
                width: "100%",
                height: projects.height,
              }}
            >
              {renderEditProjectEffort()}
              {renderAddUserEffortBar()}
              {renderEfforts()}
              {renderFerie()}
              {renderFerieBarDrawer()}
              {renderHorizontalLines()}
              {renderAddUserFerie()}
            </div>
          </CalendarBackground>
        </div>
      </div>
    </div>
  );
};

export default Project;
