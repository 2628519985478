// Import libraries
import { DirectionalHint, PrimaryButton, SpinButton, TeachingBubble, TextField, mergeStyleSets } from "@fluentui/react"
import { useEffect, useState } from "react"
import { primaryButtonStyles } from "../../utils/theme"

type AllocationBubbleProps = {
    targetElemId: string,
    onDismiss?: ((ev?: any) => void) | undefined,
    value?: number,
    onConfirm: (newValue: number) => void,
    onCancel: () => void
}

const classNames = mergeStyleSets({
    input: {
        border: 'none',
        width: 100,
        backgroundColor: '#f1f1f1',
        fontFamily: 'Verdana',
        fontSize: 14,
    }
})

const AllocationBubble = ({ targetElemId, onDismiss, onCancel, value = 0, onConfirm }: AllocationBubbleProps) => {

    const [textValue, setTextValue] = useState<number>(100);

    const onChange = (newValue: number) => {
        setTextValue(newValue);
    }

    useEffect(() => {
        if (value) {
            setTextValue(value);
        }
    }, [value]);

    return (
        <TeachingBubble
            calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
            target={`#${targetElemId}`} hasSmallHeadline={true} onDismiss={onDismiss} closeButtonAriaLabel='Close'
            styles={{
                body: {
                    minWidth: 275,
                    minHeight: 137,
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 31,
                    paddingBottom: 10,
                    boxSizing: 'border-box',
                },
                bodyContent: {
                    padding: 0,
                }
            }}
        >
            <SpinButton
                style={{
                    backgroundColor: "white",
                }}
                styles={{
                    input: {
                        backgroundColor: "white",
                        width: 30,
                    },
                    arrowButtonsContainer: {
                        visibility: 'hidden',
                    },
                    label: {
                        position: 'absolute',
                        top: 5,
                        fontWeight: 600,
                        fontSize: 11,
                    },
                    labelWrapper: {
                        marginRight: 0
                    }
                }}
                value={textValue + ''}
                min={0}
                max={100}
                step={1}
                onChange={(_, value) => {
                    if (value) {
                        const parsedVal = parseInt(value);
                        if (!isNaN(parsedVal) && typeof parsedVal === 'number') {
                            onChange(parsedVal);
                        }
                    }
                }}
                label="ALLOKATION"
            />
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: 10,
                gap: 10
            }}>
                {
                    !!onCancel &&
                    <PrimaryButton text='Cancel'
                        onClick={() => {
                            onCancel();
                        }}
                        // @ts-ignore
                        styles={primaryButtonStyles}
                    />
                }
                <PrimaryButton text='GEM'
                    onClick={() => {
                        if (typeof textValue === 'number' && textValue >= 0 && textValue <= 100) {
                            onConfirm(textValue);
                        }
                    }}
                    // @ts-ignore
                    styles={primaryButtonStyles}
                />
            </div>
        </TeachingBubble>
    )
}

export default AllocationBubble