import React from 'react';

export const buttonStyle = (selected = false, defaultBackground = '', rootStyle: React.CSSProperties = {}) => ({
    root: {
        borderRadius: 4,
        height: 40,
        width: 53,
        minWidth: 53,
        borderColor: "#006CAD",
        color: "#006CAD",
        backgroundColor: selected ? "#006CAD" : defaultBackground,
        marginRight: 15,
        ...rootStyle
    },
    label: { fontWeight: 400 },
    rootHovered: {
        backgroundColor: !selected && "#99b3ff"
    },
});