// Import libraries
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

// Import config
import { loginRequest, protectedResources } from "../../authConfig";

// Import redux
import { persistor } from "../../redux/store";
import { login, setAutoLogin } from "../../redux/user/user.actions";

// Import utils
import { primaryButtonStyles } from "../../utils/theme";
import { getCookie } from "../../utils/utils";

// Import hooks
import useFrameContext from "../../hooks/useFrameContext";

const classNames = mergeStyleSets({
  image: {
    margin: "0 10px 100px 10px",
  },
  input: {
    marginBottom: 12,
  },
  login: {
    marginTop: 24,
    width: 320,
  },
  spinner: {
    marginTop: 24,
  },
});

const Login = ({
  isAuthenticated,
  isLoading,
  login,
  tokenExpired,
  autoLogin,
}: {
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (token: string) => void;
  tokenExpired: boolean;
  autoLogin: boolean;
}) => {
  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { checkedFrameContext, isInTeamsApp } = useFrameContext();

  useEffect(() => {
    // Auto redirect to login
    if (!account && !isAuthenticated && !isLoading && autoLogin) {
      onLogin();
    }
  }, [tokenExpired, isLoading]);

  useEffect(() => {
    try {
      if (account && (inProgress === "startup" || inProgress === "none")) {
        instance
          .acquireTokenSilent({
            scopes: protectedResources.listApi.scopes,
            account: account,
          })
          .then((response) => {
            login(response.accessToken);
          })
          .catch((error) => {
            // in case if silent token acquisition fails, fallback to an interactive method
            if (error instanceof InteractionRequiredAuthError) {
              if (account && inProgress === "none") {
                instance
                  .acquireTokenPopup({
                    scopes: protectedResources.graphMe.scopes,
                  })
                  .then((response) => {})
                  .catch((error) => console.log(error));
              }
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }, [account, inProgress, instance]);

  let location = useLocation();

  if (isAuthenticated && !isLoading) {
    const machineReqUrl = getCookie("machineReqUrl");
    if (machineReqUrl) {
      return <Redirect to={machineReqUrl} />;
      // @ts-ignore
    } else if (location.state && location.state.from) {
      // @ts-ignore
      return <Redirect to={location.state.from} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  const onLogin = async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      persistor.purge();
      dispatch(setAutoLogin(false));
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.log(error);
    }
  };

  if (checkedFrameContext && isInTeamsApp) {
    return <Redirect to="/login-teams" />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        marginTop: "12%",
      }}
    >
      <form style={{ width: 320, height: 530, margin: "auto" }}>
        <Image
          src="./img/Logo.svg"
          imageFit={ImageFit.contain}
          alt="Aarsleff"
          width={300}
          height={163}
          className={classNames.image}
        />
        <div
          style={{
            fontSize: 32,
            fontWeight: "bold",
            marginBottom: 46,
            textAlign: "center",
          }}
        >
          Disponering
        </div>
        {!isLoading && (
          <Fragment>
            <PrimaryButton
              // @ts-ignore
              styles={primaryButtonStyles}
              className={classNames.login}
              text="Login"
              onClick={onLogin}
            />
          </Fragment>
        )}
        {isLoading && (
          <Spinner
            className={classNames.spinner}
            size={SpinnerSize.large}
            label="Logging in..."
            labelPosition="right"
          />
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.user.isAuthenticated,
  isLoading: state.user.isLoading,
  tokenExpired: state.user.tokenExpired,
  autoLogin: state.user.autoLogin,
});

export default connect(mapStateToProps, { login })(Login);
