import { Image } from '@fluentui/react/lib/Image';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MenuButtonSmall } from '.';
import { setSideBarStatus } from '../../redux/app/app.actions';
import { getUsersMissingInfo } from '../../redux/user/user.actions';
import { SIDE_MENU_LABEL } from '../../utils/constants';

const SideMenuSmall = ({ getUsersMissingInfo, scrollY }) => {
    const showSidebar = useSelector((state) => state.app.showSidebar);
    const classNames = mergeStyleSets({
        image: {
            margin: '50px 0px',
        },
        menuContainer: {
            position: 'fixed',
            background: 'white',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            height: '500vh', //maximum zoomin
            width: '104px',
            zIndex: 1004,
            alignItems: 'center',
        },
        link: {
            textDecoration: 'none',
        },
        labelContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        labelText: {
            position: 'absolute',
            top: 110,
            fontSize: 11,
            fontWeight: 400,
            color: '#868685',
        },
        showHideMenu: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 40,
            height: 40,
            top: 46,
            background: '#fff',
            borderRadius: '50%',
            left: showSidebar ? 86 : -17,
            zIndex: 100000,
            transition: 'all 0.5s ease',
            cursor: 'pointer',
        },
        showHideIcon: {
            color: '#006CAD',
            fontSize : 13,
            marginLeft: 17,
            fontWeight: 'bold',
        }
    });
    const dispatch = useDispatch();
    return (
        <>
            <div className={classNames.showHideMenu} onClick={() => dispatch(setSideBarStatus(!showSidebar))}>
                <FontAwesomeIcon icon={showSidebar ? faChevronLeft : faChevronRight} className={classNames.showHideIcon}/>
            </div>
            <div
                style={{
                    position: 'fixed',
                    marginLeft: showSidebar ? 0 : -104,
                    transform: `translate(0, 0)`,
                    transition: 'all 0.5s ease',
                    zIndex: 1004
                }} 
            >
                <div className={classNames.menuContainer}>
                    <div className={classNames.image}>
                        <Image
                            src='./img/Logo.svg'
                            alt='Aarsleff'
                            width={60}
                            height={32}
                        />
                    </div>
                    {SIDE_MENU_LABEL.map(({ to, label, iconName, icon }) => (
                        <div className={classNames.labelContainer} key={to}>
                            <NavLink to={to} className={classNames.link}>
                                <MenuButtonSmall to={to} iconName={iconName} icon={icon} />
                            </NavLink>
                            <div className={classNames.labelText}>
                                {label}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default connect(null, { getUsersMissingInfo })(SideMenuSmall);
