import { MachinesActionTypes } from "./machines.types";
import {
  techDepartmentOptions,
  workingRoleOptions,
} from "../../utils/constants";

const initialState = {
  filter: {
    availableTechDepartmentList: techDepartmentOptions.map((item) => item.key),
    selectedTechDepartmentList: techDepartmentOptions.map((item) => item.key),
    staffNames: "",
    techArea: null,
    staffRole: workingRoleOptions
      .filter((item) => [0, 1, 2, 4, 5].includes(item.key))
      .map((item) => ({ ...item, isOn: true })), // Functioner or Formand
  },
  displayStaffOnPlanning: false,
  displayWeekView: false,
  selectedDayOnCalendar: new Date(),
};

const machineReducer = (state = initialState, action) => {
  switch (action.type) {
    case MachinesActionTypes.CHANGE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case MachinesActionTypes.TOGGLE_WEEK_VIEW:
      return {
        ...state,
        displayWeekView: action.payload,
      };
    case MachinesActionTypes.SELECT_NEW_MONTH:
      return {
        ...state,
        selectedDayOnCalendar: action.payload,
      };
    default:
      return state;
  }
};

export default machineReducer;
