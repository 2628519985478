import { TeachingBubble } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { faSquare, faSquareCheck, faSquareEllipsisVertical, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/da';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { connect, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { saveMessage } from '../../../redux/message/message.actions';
import { updateStaff } from '../../../redux/user/user.actions';
import {
    calendarConfirmationStatus,
    CALENDAR_STAFFVIEW_STEP_HEIGHT,
    CALENDAR_STEP_HEIGHT,
    CALENDAR_STEP_WIDTH,
    CALENDAR_WEEKVIEW_STEP_WIDTH,
    hasEditRight,
    reasonVacationStaffOptions,
} from '../../../utils/constants';
import { checkTimeOverlap } from '../../../utils/utils';
import { DeleteConfirmation, TooltipForText } from '../../common';
import ConfirmBubble from '../../common/ConfirmBubble';

const convertTimeToNumber = (horizontalPositions, time) => {
    const formattedDate = moment(time, 'YYYY-M-D').format('YYYY-M-D');
    return horizontalPositions[formattedDate] || null;
};

const StaffVacationItem = ({
    staffVacation,
    staffVacationIndex,
    staff,
    userId,
    start,
    end,
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    updateStaff,
    saveMessage,
    isApproved,
    calendarStepHeight,
}) => {
    const userRoles = useSelector((state) => state.user.user.workingRole);
    let allowEdit = hasEditRight(userRoles);
    const calendarState = JSON.stringify(useSelector((state) => state.machine));
    const { displayWeekView } = useSelector((state) => state.machine);
    const calendarStepWidth = displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH;
    const currentUserId = useSelector((state) => state.user.user.userId);
    const [teachingBubbleStaffVacation, { toggle: toggleTeachingStaffVacation }] = useBoolean(false);
    const [openActions, { toggle: toggleOpenActions }] = useBoolean(false);
    if (userId === currentUserId) {
        allowEdit = true;
    }
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [width, setWidth] = useState(250);
    const [display, setDisplay] = useState(false);
    const [confirmation, setConfirmation] = useState(calendarConfirmationStatus.CONFIRMATION_UNSET);
    const needConfirmation = confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
    const colorCode = '0,108,173';
    let lighterColorCode = '51,137,189';
    if (isApproved) lighterColorCode = colorCode;
    let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterColorCode}) 24px, rgb(${lighterColorCode}) 48px)`;

    const queryClient = useQueryClient();
    const [updatePackage, setUpdatePackage] = useState(null);
    const ref = useRef(null);
    const renderDependencies = calendarState + JSON.stringify(verticalPositions);
    if (!ref.current) ref.current = renderDependencies;
    const shouldComponentRerender = ref.current !== renderDependencies;

    const classNames = mergeStyleSets({
        icon: {
            color: '#fff',
            marginRight: 2,
            fontSize: 13,
            fontWeight: 'lighter',
            selectors: {
                ':hover': {
                    color: '#DBDBDB',
                },
            },
            cursor: 'pointer',
        },
        iconBlack: {
            color: '#000',
        },
        draggable: {
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        barContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 'calc(100% - 15px)',
            height: '100%',
            cursor: allowEdit ? `url(${window.location.origin}/img/arrows-alt.svg), auto` : 'default',
        },
    });

    const listVacation = useMemo(
        () =>
            staff?.starfVacations.map((item) => {
                const start = convertTimeToNumber(horizontalPositions, item.start);
                const end = convertTimeToNumber(horizontalPositions, item.end);
                return { start, end };
            }) || [],
        [staff?.starfVacations, horizontalPositions]
    );

    const { mutate: mutateUpdateStaff } = useMutation((staff) => updateStaff(staff.data), {
        onSuccess: (data, variables) => {
            setUpdatePackage(null);
            if (data !== true) {
                rollback(variables.rollbackData);
            }
            queryClient.invalidateQueries('staffs');
            queryClient.invalidateQueries('plans');
        },
        onFailure: (data, variables) => {
            setUpdatePackage(null);
            rollback(variables.rollbackData);
        },
        onSettled: () => {
            queryClient.invalidateQueries('staffs');
            queryClient.invalidateQueries('plans');
        },
    });

    useEffect(() => {
        if (confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET && ((!x && !y) || shouldComponentRerender)) {
            if (shouldComponentRerender) ref.current = renderDependencies;
            let yPosition = verticalPositions[userId][0];

            const keyStart = moment(start).utcOffset(0).format('YYYY-M-D');
            const keyEnd = moment(end).add(1, 'd').utcOffset(0).format('YYYY-M-D');

            let xPosition = horizontalPositions[keyStart];
            let endValue = horizontalPositions[keyEnd];

            if (
                new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
                new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
                typeof xPosition === 'undefined' &&
                typeof endValue === 'undefined'
            ) {
                xPosition = 0;
                endValue = horizontalPositions['end'];
            }

            if (new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() && typeof endValue !== 'undefined') {
                xPosition = 0;
            }

            if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
                endValue = horizontalPositions['end'];
            }
            setY(yPosition * calendarStepHeight);

            if (typeof xPosition !== 'undefined') {
                setX(xPosition * calendarStepWidth);
                setWidth((endValue - xPosition) * calendarStepWidth);
                setDisplay(true);
            } else {
                setDisplay(false);
            }
        }
    }, [start, end, horizontalPositions, verticalPositions, calendarStart, calendarEnd, staff, userId, calendarStepWidth]);

    const rollback = ({ previousX, previousY, previousW }) => {
        setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
        setX(previousX);
        setY(previousY);
        setWidth(previousW);
        setUpdatePackage(null);
    };

    const update = ({ newX, newY, newW, previousX, previousY, previousW }) => {
        const newStaff = { ...staff };
        setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
        if (newX !== previousX) {
            const newStartDate = horizontalPositions['positionsToDates'][newX / calendarStepWidth];
            if (!newStartDate) {
                return false;
            }
            newStaff.starfVacations[staffVacationIndex].start = newStartDate;
        }

        if (newX !== previousX || newW !== previousW) {
            const endPositionKey = (newW + newX) / calendarStepWidth;
            const newEndDate = horizontalPositions['positionsToDates'][endPositionKey];
            if (!newEndDate) {
                return false;
            }
            const newEndDateMinusOneDay = moment(newEndDate).subtract(1, 'd').format('YYYY-MM-DD[T]00:00:00.000');
            newStaff.starfVacations[staffVacationIndex].end = newEndDateMinusOneDay;
        }
        mutateUpdateStaff({ data: newStaff, rollbackData: { previousX, previousY, previousW } });
    };

    const isOverlap = (newX, newWidth) => {
        const newStart = newX / calendarStepWidth;
        const newEnd = newStart + newWidth / calendarStepWidth;
        const staffData = verticalPositions["dataInfo"][userId];
        const startDate = new Date(horizontalPositions.positionsToDates[newStart].slice(0, 10));
        const endDate = new Date(horizontalPositions.positionsToDates[newEnd].slice(0, 10));
        endDate.setDate(endDate.getDate() - 1);
        const listToCheck = [...staffData.starfVacations.filter(item => item.start !== start && item.end !== end), ...staffData.staffPlans];
        for (let i = 0; i < listToCheck.length; i++) {
            let itemStartDate = new Date(listToCheck[i].start.slice(0.1));
            let itemEndDate = new Date(listToCheck[i].end.slice(0.1));
            const isOverlap = checkTimeOverlap(startDate, endDate, itemStartDate, itemEndDate, false);

            if (isOverlap) {
                saveMessage('Cannot Overlap');
                return true;
            }
        }
        return false;
    };

    const text = reasonVacationStaffOptions[staffVacation['reason']]['text'];

    const onClickConfirmDelete = (staffVacationIndex) => {
        const staffData = { ...staff };
        staffData.starfVacations.splice(staffVacationIndex, 1);
        toggleTeachingStaffVacation();
        mutateUpdateStaff({ data: staffData });
    };

    const onChangeStatus = (staffVacationIndex) => {
        if (!hasEditRight(userRoles)) return;
        const staffData = { ...staff };
        staffData.starfVacations[staffVacationIndex] = {
            ...staffData.starfVacations[staffVacationIndex],
            isApproved: !staffData.starfVacations[staffVacationIndex]?.isApproved,
        };
        mutateUpdateStaff({ data: staffData });
    };

    let content = (
        <div
            id={`staffVacationBar-${userId}-${staffVacationIndex}`}
            className={`${allowEdit && !needConfirmation ? 'dragHandle' : 'dragDisabled'} ${classNames.barContent}`}
            style={{ marginLeft: width > calendarStepWidth * 2 ? 10 : 6, justifyContent: 'flex-start' }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'flex-end',
                        marginTop: 4,
                    }}
                >
                    {(width > calendarStepWidth * 2 || !allowEdit) && (
                        <span
                            style={{
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 11,
                                marginRight: 8,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                minWidth: 30,
                            }}
                        >
                            {text}
                            <span style={{ color: '#fff', fontWeight: 400, fontSize: 11, marginLeft: 8 }}>{staffVacation['notes'] || ''}</span>
                        </span>
                    )}
                    {allowEdit && (
                        <FontAwesomeIcon
                            id={`staffVacationAction-${userId}-${staffVacationIndex}`}
                            icon={faSquareEllipsisVertical}
                            style={{ marginRight: width > calendarStepWidth * 2 ? 5 : 4, fontSize: 13 }}
                            className={classNames.icon}
                            onClick={toggleOpenActions}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    return (
        display &&
        width > 0 && (
            <Fragment>
                <Rnd
                    style={{ zIndex: 3, top: 0, left: 0, display: 'absolute' }}
                    // default={{ x: x, y: y, width: width, height: 48}}
                    size={{ width: width, height: calendarStepHeight }}
                    position={{ x: x, y: y }}
                    enableResizing={{ left: allowEdit && !needConfirmation, right: allowEdit && !needConfirmation }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        const prevX = x;
                        const prevW = width;
                        const newX = parseInt(position.x.toFixed(0));
                        let newWidth = ref.offsetWidth;
                        // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
                        if (newWidth % calendarStepWidth !== 0) {
                            newWidth = (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
                        }
                        if (newX === prevX && newWidth === prevW) {
                            return;
                        }
                        // if (isOverlap(newX, newWidth)) {
                        //     rollback({ previousY: y, previousW: prevW });
                        //     return;
                        // }
                        setX(newX);
                        setWidth(newWidth);
                        setUpdatePackage({
                            newX: newX,
                            newY: y,
                            newW: newWidth,
                            previousX: prevX,
                            previousY: y,
                            previousW: prevW,
                        });
                        setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
                    }}
                    onDragStop={(e, d) => {
                        const prevX = x;
                        const prevY = y;
                        if ((d.lastX === prevX && d.lastY === prevY) || d.lastY !== prevY) {
                            return;
                        }
                        if (isOverlap(d.lastX, width)) {
                            return;
                        }
                        setX(d.lastX);
                        setY(d.lastY);
                        setUpdatePackage({
                            newX: d.lastX,
                            newY: d.lastY,
                            newW: width,
                            previousX: x,
                            previousY: y,
                            previousW: width,
                        });
                        setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
                    }}
                    resizeGrid={[calendarStepWidth, calendarStepHeight]}
                    dragGrid={[calendarStepWidth, calendarStepHeight]}
                    dragAxis={allowEdit ? 'both' : 'x'}
                    bounds='.planContainer'
                    dragHandleClassName='dragHandle'
                >
                    <div
                        id={JSON.stringify(staffVacation)}
                        className={classNames.draggable}
                        style={{
                            backgroundImage: bgColor,
                            backgroundSize: '48px 48px',
                            backgroundPosition: '0 0, 0 0, -24px -24px, 24px 24px',
                            display: 'flex',
                            height: calendarStepHeight - 8,
                            borderRadius: 4,
                        }}
                    >
                        {confirmation === calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED && typeof updatePackage === 'object' && (
                            <ConfirmBubble
                                onCancel={() => rollback(updatePackage)}
                                onApprove={() => update(updatePackage)}
                                targetId={JSON.stringify(staffVacation)}
                            />
                        )}
                        <TooltipForText text={text}>{content}</TooltipForText>
                    </div>
                </Rnd>
                {openActions && (
                    <TeachingBubble
                        target={`#staffVacationAction-${userId}-${staffVacationIndex}`}
                        onDismiss={toggleOpenActions}
                        styles={{
                            subText: { color: 'black' },
                            subComponentStyles: {
                                callout: {
                                    root: {
                                        maxWidth: 110,
                                    },
                                },
                            },
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', height: 50, justifyContent: 'space-around' }}>
                            {hasEditRight(userRoles) && (
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}>
                                    <FontAwesomeIcon
                                        icon={isApproved ? faSquareCheck : faSquare}
                                        style={{ marginRight: 4, fontSize: 13, color: '#000' }}
                                        className={classNames.icon}
                                        onClick={() => onChangeStatus(staffVacationIndex)}
                                    />
                                    <span style={{ fontSize: 11 }}>Godkendt</span>
                                </div>
                            )}
                            <div
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}
                                id={`deleteVacation-${userId}-${staffVacationIndex}`}
                                onClick={toggleTeachingStaffVacation}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 4, fontSize: 13, color: '#000' }} className={classNames.icon} />
                                <span style={{ fontSize: 11 }}>Slet</span>
                            </div>
                        </div>
                    </TeachingBubble>
                )}
                {teachingBubbleStaffVacation && (
                    <DeleteConfirmation
                        target={`#staffVacationAction-${userId}-${staffVacationIndex}`}
                        toggleTeaching={toggleTeachingStaffVacation}
                        onClickConfirm={() => {
                            onClickConfirmDelete(staffVacationIndex);
                        }}
                    />
                )}
            </Fragment>
        )
    );
};

export default connect(null, { updateStaff, saveMessage })(StaffVacationItem);
