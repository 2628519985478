import { Stack } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/machines/machines.actions";
import { stationOptions, techDepartmentOptions } from "../../../utils/constants";
import { outlinedDropDownStyles } from "../../../utils/theme";
import PlanningPageFilter from "../../pages/planning/detailedPlanning/PlanningPageFilter";
import ScreenSizeAndPosition from "../ScreenSizeAndPosition";

const CalendarControlsLeft = () => {
    const classNames = mergeStyleSets({
        buttonContainer: {
            display: "flex",
            width: 270,
            flexWrap: "wrap",
            margin: `0px 0 30px 10px`,
        },
        unselectedIcon: {
            fontSize: 18,
            color: "#006CAD",
        },
        selectedIcon: {
            fontSize: 18,
            color: "#fff",
        },
        image: {
            fontSize: 36,
        },
    });
    const dispatch = useDispatch();
    const filter = useSelector(state => state.machine.filter);
    const { techDepartment } = useSelector(state => state.machine.filter);
    return (
        <div className={classNames.buttonContainer}>
            <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
                {/* <Dropdown
                    selectedKey={techDepartment}
                    name="projectType"
                    onChange={(event, item) =>
                        dispatch(
                            setFilter({
                                techDepartment: item.key,
                                [item.key]: {
                                    techArea: filter[item.key].techArea
                                        ? filter[item.key].techArea
                                        : stationOptions[item.key]["options"].map((item) => item.key),
                                },
                            })
                        )
                    }
                    placeholder="Department"
                    options={techDepartmentOptions}
                    styles={outlinedDropDownStyles}
                /> */}
                <ScreenSizeAndPosition>
                    <PlanningPageFilter/>
                </ScreenSizeAndPosition>
            </Stack>
        </div>
    );
};

export default CalendarControlsLeft;
