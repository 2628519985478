import axios from 'axios';
import { SOURCE_API } from '../../utils/constants';
import setDefaults from '../../utils/setDefaults';
import { checkTokenValidity } from '../user/user.actions';
import { AppActionTypes } from './app.types';

export const changePage = (value) => (dispatch) => {
    dispatch({ type: AppActionTypes.CHANGE_PAGE, value });
}

export const setSideBarStatus = (value) => (dispatch) => {
    dispatch({ type: AppActionTypes.SET_SIDE_BAR_STATUS, value: value });
};

export const changeCalendarLength = (value) => (dispatch) => {
    dispatch({ type: AppActionTypes.CHANGE_CALENDAR_LENGTH, value: value });
};

export const setBarDrawer = (value) => (dispatch) => {
    dispatch({ type: AppActionTypes.SET_BAR_DRAWER, payload: value });
};

// value: {userId: string,projectId: string}
export const setDrawUserEffort = (value) => (dispatch) => {
    dispatch({ type: AppActionTypes.SET_DRAW_USER_EFFORT, value })
}

export const clearDrawEffort = () => (dispatch) => {
    dispatch({ type: AppActionTypes.CLEAR_DRAW_EFFORT })
}

export const setHighlightStaff = (value) => (dispatch) => {
    dispatch({ type: AppActionTypes.SET_HIGHLIGHT_STAFF, value: value });
};

export const setStaffVacationBubble = (value) => (dispatch) => {
    dispatch({ type: AppActionTypes.SET_STAFF_VACATION_BUBBLE, value: value });
};

export const getHolidayList = (year, countryCode = 'DK', isPublic = true, language = 'da') => async (dispatch) => {
    try {
        setDefaults(localStorage.getItem('token'));
        const response = await axios.get(`${SOURCE_API}/api/Search/Holidays?country=${countryCode}&year=${year}&isPublic=${isPublic}&language=${language}`);
        return response.data.data ? response.data.data : null;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
};

