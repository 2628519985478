/* eslint-disable no-unused-expressions */
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { datesGenerator } from "dates-generator";
import moment from "moment";
import "moment/locale/da";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useBodyClass } from "../../../hooks";
import { getHolidayList } from "../../../redux/app/app.actions";
import {
  getMachines,
  selectNewDate,
  setFilter,
} from "../../../redux/machines/machines.actions";
import { getPlans } from "../../../redux/plan/plan.actions";
import { getStaffs } from "../../../redux/user/user.actions";
import {
  CALENDAR_PAGE,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  hasEditRight,
  months,
  techDepartmentOptions,
} from "../../../utils/constants";
import {
  calculateFinalDates,
  findYearOfDates,
  getCalendarHoliday,
  getLastDate,
  getMonthIndicator,
  removeFirstWeek,
  startCalendarFromMonth,
  startCalendarFromSelectedWeek,
} from "../../../utils/utils";
import { ItemContainer } from "../../common";
import {
  CalendarControlsLeft,
  CalendarControlsRight,
  CalendarHeaderLeft,
  DayLabel,
  MonthLabel,
  WeekLabel,
} from "../../common/calendar";
import BarDrawer from "../../common/calendar/BarDrawer";
import ScreenSizeAndPosition from "../../common/ScreenSizeAndPosition";
import StaffPlan from "./StaffPlan";
import StaffVacationItem from "./StaffVacationItem";
import { PAGES } from "../../../redux/app/app.reducer";
import People from "./People";
import Project from "./Project";
import ProjectDetailsContainer from "../../common/ProjectDetailsContainer";

// https://dev.to/aibrahim3546/creating-a-custom-calendar-in-react-from-scratch-1hej
// https://gist.github.com/aibrahim3546/dc68f9525f0f206ff08b1d09ee3adab5
const DispositionCalendar = () => {
  const { displayWeekView, selectedDayOnCalendar } = useSelector(
    (state) => state.machine
  );
  const { page } = useSelector((state) => state.app);

  const classNames = mergeStyleSets({
    root: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    leftContainer: {
      width: 250,
      position: "sticky",
      zIndex: 1002,
      top: 0,
      left: 0,
      paddingLeft: 30,
      background: "rgb(241, 241, 241)",
      transition: "all 0.5s ease",
    },
    machineAndCalendarContainer: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      justifyContent: "flex-start",
      background: "rgb(241, 241, 241)",
    },
    machineContainer: {
      width: 250,
      minHeight: "100%",
      marginLeft: 0,
      // marginTop: 30,
      background: "#f1f1f1",
    },
    calendarContainer: {
      minHeight: "100%",
      marginLeft: 0,
      transition: "all 0.5s ease",
    },
    monthText: {
      fontSize: 26,
      fontWeight: "bold",
      textAlign: "center",
    },
    days: {
      display: "flex",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
    stickyHeader: {
      // position: -webkit-sticky; /* Safari */
      paddingLeft: 30,
      width: 4890,
      position: "sticky",
      top: 0,
      minWidth: "calc(100vw - 150px)",
      background: "#F1F1F1",
      zIndex: 1003,
      display: "flex",
      flexDirection: "row",
    },
    teamName: {
      fontWeight: "bold",
      textTransform: "uppercase",
      height: 50,
      display: "flex",
      alignItems: "flex-end",
      marginLeft: 15,
      marginBottom: 10,
    },
  });
  useBodyClass(`home`);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const userRoles = useSelector((state) => state.user.user.workingRole);
  const { barDrawer } = useSelector((state) => state.app);
  const allowEdit = hasEditRight(userRoles);
  const { selectedTechDepartmentList, staffNames, staffRole } = useSelector(
    (state) => state.machine.filter
  );

  // console.log(staffRole);

  const calendarStartFrom =
    useSelector((state) => state.user.user.defaultFilter.customFilters)?.find(
      (item) => item.key === "calendarStartFrom"
    )?.text || "month";

  const [dates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({});
  const [totalHeight, setTotalHeight] = useState("calc(100vh - 305px)");
  const [plansData, setPlansData] = useState([]);
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );
  const { data: staffsData } = useQuery("staffs", dispatch(getStaffs));
  const { data: allPlansData } = useQuery("plans", () =>
    dispatch(getPlans(new Date(selectedDayOnCalendar).getFullYear()))
  );
  const holidayRef = useRef(null);

  const projectsData = useMemo(() => {
    if (Array.isArray(allPlansData) && allPlansData.length) {
      return allPlansData
        .filter(
          (el) =>
            selectedTechDepartmentList.includes(el.section) ||
            page !== PAGES.PROJECT
        )
        .map((el) => {
          return {
            // projectId,
            // projectNo,
            // projectName,
            // color,
            // section,
            ...el,
          };
        });
    }
    return [];
  }, [allPlansData, selectedTechDepartmentList, page]);

  const filterPlansData = () => {
    const startDate = dates[0][0];
    const endDate = getLastDate(dates);
    const startCalendar = new Date(
      startDate.year,
      startDate.month,
      startDate.date
    );
    const endCalendar = new Date(endDate.year, endDate.month, endDate.date);
    const filteredPlansData = allPlansData.filter(
      (plan) =>
        plan.projectNo &&
        plan.projectName &&
        plan.section &&
        !plan["inactive"] &&
        (plan["machineRequirements"].some((requirement) =>
          requirement.machines.some(
            (machine) =>
              new Date(machine.start) <= endCalendar &&
              new Date(machine.end) >= startCalendar
          )
        ) ||
          plan["machineRequirements"].some(
            (requirement) => !requirement.machines.length
          ))
    );
    return filteredPlansData;
  };

  useEffect(() => {
    if (allPlansData && dates.length) {
      const filteredPlansData = filterPlansData();
      setPlansData(filteredPlansData);
    }
  }, [allPlansData, dates]);

  const filterStaffs = () => {
    const staffKeys = (staffRole || [])
      .filter((el) => el.isOn)
      .map((el) => el.key + "");

    let staffList = [];
    const staffListWithPlan = [];
    if (staffsData && plansData.length) {
      staffList = staffsData
        .filter(
          (staff) =>
            !staff.inactive &&
            (staff.firstName + " " + staff.lastName)
              .toLowerCase()
              .includes(staffNames?.toLowerCase() || "") &&
            (selectedTechDepartmentList
              ? selectedTechDepartmentList.includes(staff.techDepartment) ||
                page !== PAGES.PEOPLE
              : true) &&
            staffKeys.some((key) => staff.workingRole.includes(key))
          //   &&
          // staffRole?.some(
          //   (role) => role.isOn && staff.workingRole.includes(role.key)
          // )
        )
        .sort((a, b) =>
          a.firstName?.toLowerCase() < b.firstName?.toLowerCase() ? -1 : 1
        );
      plansData.forEach((plan) => {
        if (!plan.inactive) {
          plan.machineRequirements.forEach(
            (machineRequirement, indexMachineRequirement) => {
              [
                machineRequirement["drivers"],
                machineRequirement["workers"],
                machineRequirement["managers"],
              ].forEach((array) => {
                array.forEach((item) => {
                  if (
                    staffList.some((item2) => item2.userId === item.id) &&
                    (item.firstName + " " + item.lastName)
                      .toLowerCase()
                      .includes(staffNames?.toLowerCase() || "")
                  ) {
                    let replicateStaff = null;
                    for (let i = staffListWithPlan.length - 1; i >= 0; i--) {
                      if (staffListWithPlan[i].id === item.id) {
                        replicateStaff = staffListWithPlan[i];
                        break;
                      }
                    }
                    const index = !replicateStaff
                      ? 0
                      : replicateStaff.index + 1;
                    staffListWithPlan.push({
                      ...item,
                      starfVacations: !replicateStaff
                        ? item.starfVacations
                        : null,
                      personResponsbible: plan?.personResponsbible?.name,
                      isQuotation: plan.isQuotation,
                      projectNo: plan.projectNo,
                      projectId: plan.projectId,
                      projectName: plan.projectName,
                      color: plan.color,
                      indexMachineRequirement,
                      index,
                    });
                  }
                });
              });
            }
          );
        }
      });
      staffList.forEach((item) => {
        const index = staffListWithPlan.findIndex(
          (item2) => item2.id === item.userId
        );
        if (index === -1) {
          staffListWithPlan.push({ ...item, id: item.userId, index: 0 });
        }
      });
    }
    return { staffList, staffListWithPlan };
  };

  const createHorizontalPositionMap = (dates) => {
    const result = {};
    const datePositionCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        //date: 1, month: 2, year: 2021
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        result[key] = counter;
        datePositionCorrespondence[counter] = moment(key, "YYYY-M-D").format(
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        );
        counter++;
      }
    }
    result["end"] = --counter;
    result["positionsToDates"] = datePositionCorrespondence;
    return result;
  };

  const createVerticalPositionMap = (staffList, staffListWithPlan) => {
    const result = {};
    const positionIdCorrespondence = {};
    const dataInfo = {};
    let counter = 0;
    for (let i = 0; i < staffList.length; i++) {
      const tempListWithSameId = staffListWithPlan.filter(
        (staff) => staff.id === staffList[i]["userId"]
      );
      dataInfo[staffList[i]["userId"]] = {
        starfVacations: staffList[i].starfVacations || [],
        staffPlans: tempListWithSameId
          .map((item) => ({
            start: item.start,
            end: item.end,
            name: item.projectName,
          }))
          .filter((item) => item.start && item.end),
      };
      const tempIndex = counter;
      if (tempListWithSameId.length > 1) {
        for (let j = 0; j < tempListWithSameId.length; j++) {
          let flag = false;
          for (let k = j + 1; k < tempListWithSameId.length; k++) {
            if (
              !(
                new Date(tempListWithSameId[j]?.start).getTime() >
                  new Date(tempListWithSameId[k]?.end).getTime() ||
                new Date(tempListWithSameId[j]?.end).getTime() <
                  new Date(tempListWithSameId[k]?.start).getTime()
              )
            ) {
              result[staffList[i]["userId"]] = {
                ...result[staffList[i]["userId"]],
                // For staff plan
                // [tempListWithSameId[j].index]: ++counter,
                [tempListWithSameId[j].index]: counter,
              };
              flag = true;
              break;
            }
          }
          if (!flag) {
            result[staffList[i]["userId"]] = {
              ...result[staffList[i]["userId"]],
              [tempListWithSameId[j].index]: tempIndex,
            };
          }
        }
      } else {
        result[staffList[i]["userId"]] = { 0: counter };
      }
      positionIdCorrespondence[counter * CALENDAR_STAFFVIEW_STEP_HEIGHT] =
        staffList[i]["userId"];
      counter++;
    }
    result["positionsToIds"] = positionIdCorrespondence;
    result["dataInfo"] = dataInfo;
    return result;
  };

  const { staffList, staffListWithPlan } = useMemo(
    () => filterStaffs(),
    [
      staffsData,
      plansData,
      selectedTechDepartmentList,
      staffNames,
      page,
      staffRole,
    ]
  );
  const verticalPositionMap = useMemo(
    () => createVerticalPositionMap(staffList, staffListWithPlan),
    [staffList, staffListWithPlan]
  );
  const horizontalPositionMap = useMemo(
    () => createHorizontalPositionMap(dates),
    [dates]
  );

  const onClickChooseDate = (date) => {
    dispatch(selectNewDate(date));
    const array = new Array(displayWeekView ? 12 : 6);
    array[0] = datesGenerator({
      month: date.getMonth(),
      year: date.getFullYear(),
      startingDay: 1,
    });
    for (let i = 1; i < array.length; i++) {
      array[i] = datesGenerator({
        month: array[i - 1].nextMonth,
        year: array[i - 1].nextYear,
        startingDay: 1,
      });
    }
    let { finalDates, start, end } = calculateFinalDates(array);
    if (calendarStartFrom === "month" && !displayWeekView) {
      finalDates[0] = startCalendarFromMonth(date, finalDates[0]);
    }
    // Find the start of calendar if choose to start from selected week
    if (calendarStartFrom === "week") {
      finalDates = startCalendarFromSelectedWeek(date, finalDates);
    }
    start = new Date(
      finalDates[0][0].year,
      finalDates[0][0].month,
      finalDates[0][0].date
    );
    // Find the holiday of calendar
    async function getDatesWithHolidays() {
      const yearOfDates = findYearOfDates(finalDates);
      let holidays = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays = await dispatch(getHolidayList(year));
          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);
      setDates(finalDates);
    }
    getDatesWithHolidays();
    setDates(finalDates);

    setCalendar({
      ...calendar,
      nextMonth: array[array.length - 1].nextMonth,
      nextYear: array[array.length - 1].nextYear,
      previousMonth: array[0].previousMonth,
      previousYear: array[0].previousYear,
      start,
      end,
    });
  };

  useEffect(() => {
    setCalendarStepWidth(
      displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    onClickChooseDate(new Date(selectedDayOnCalendar));
  }, [displayWeekView, new Date(selectedDayOnCalendar).toLocaleString()]);

  // Add icon to resize cursor
  useEffect(() => {
    setTimeout(() => {
      const resizeDivList = document.getElementsByClassName("react-draggable");
      for (let index = 0; index < resizeDivList.length; index++) {
        const resizeDiv = resizeDivList[index].lastChild;
        if (resizeDiv.firstChild && resizeDiv.lastChild) {
          resizeDiv.firstChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.firstChild.style.left = "-14px";
          resizeDiv.lastChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.lastChild.style.right = "3px";
        }
      }
    }, 500);
  });

  const staffItemContainer = useMemo(() => {
    const list = [];
    let totalCount = 0;
    for (let i = 0; i < staffList.length; i++) {
      const temp = verticalPositionMap[staffList[i].userId];
      let tempList = new Set();
      for (const key in temp) {
        tempList.add(temp[key]);
      }
      tempList = Array.from(tempList);
      let count = 1;
      // For staff plan
      // if (tempList.length >= 2) {
      //     count = tempList.length;
      // }
      totalCount += count;
      list.push(
        <div>
          <ItemContainer
            calendarPage={CALENDAR_PAGE.DISPOSITION}
            count={count}
            key={`${staffList[i].userId}`}
            workingRole={staffList[i].workingRole}
            index={`${staffList[i].userId}`}
            name={`${staffList[i].firstName} ${staffList[i].lastName ?? ""}`}
            techDepartment={staffList[i].techDepartment}
            techArea={staffList[i].techArea}
            imageUrl={staffList[i]?.imageUrl}
            staffId={staffList[i].userId}
            calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
          />
          <hr
            style={{
              position: "absolute",
              border: "0.6px solid rgb(219, 219, 219, 0.5)",
              width: CALENDAR_STEP_WIDTH * 7 * dates.length,
              margin: 0,
              height: "0.1px",
              backgroundColor: "rgb(219, 219, 219, 0.5)",
            }}
          />
        </div>
      );
    }
    setTotalHeight(totalCount * CALENDAR_STAFFVIEW_STEP_HEIGHT);
    return list;
  }, [verticalPositionMap]);

  const render = () => {
    return (
      <div
        style={{
          display: "flex",
          // visibility: 'hidden',
          flexDirection: "row",
        }}
      >
        <div className={classNames.leftContainer}>
          <div className={classNames.machineContainer}>
            {staffItemContainer}
          </div>
        </div>
        <div className={classNames.machineAndCalendarContainer}>
          <div className={classNames.calendarContainer}>
            <div
              style={{
                minHeight: totalHeight,
                width: calendarStepWidth * 7 * dates.length - 24,
                position: "absolute",
                backgroundImage: displayWeekView
                  ? 'url("./img/calendarBackground5.png")'
                  : 'url("./img/calendarBackground3.png")',
                backgroundRepeat: "repeat",
                backgroundPositionX:
                  calendarStartFrom === "month" &&
                  !displayWeekView &&
                  Array.isArray(dates) &&
                  dates[0]?.length < 7
                    ? dates[0].length * CALENDAR_STEP_WIDTH
                    : 0,
              }}
            >
              <div
                id="planContainer"
                className="planContainer"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 556,
                  width: "100%",
                  height: staffList.length * CALENDAR_STAFFVIEW_STEP_HEIGHT,
                }}
              >
                {barDrawer && (
                  <BarDrawer
                    calendarStepWidth={calendarStepWidth}
                    calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                    horizontalPositions={horizontalPositionMap}
                    verticalPositions={verticalPositionMap}
                    calendarStart={calendar.start}
                    calendarEnd={calendar.end}
                    staffsData={staffsData}
                  />
                )}
                {/* To Draw The Vacation Bar */}
                {dates.length &&
                  horizontalPositionMap &&
                  verticalPositionMap &&
                  Array.isArray(staffList) &&
                  staffList.map((staff, index) => (
                    <Fragment key={`${staff.id}-${index}`}>
                      {staff.starfVacations &&
                        staff?.starfVacations.length > 0 &&
                        staff.starfVacations
                          .filter((item) => item.reason !== 2)
                          .map((item, index) => (
                            <StaffVacationItem
                              calendarStepWidth={calendarStepWidth}
                              key={`${staff.userId}-${index}`}
                              staffVacationIndex={index}
                              staffVacation={item}
                              staff={staff}
                              userId={staff.userId}
                              start={item["start"]}
                              end={item["end"]}
                              horizontalPositions={horizontalPositionMap}
                              verticalPositions={verticalPositionMap}
                              calendarStart={calendar.start}
                              calendarEnd={calendar.end}
                              isApproved={item["isApproved"]}
                              calendarStepHeight={
                                CALENDAR_STAFFVIEW_STEP_HEIGHT
                              }
                            />
                          ))}
                    </Fragment>
                  ))}
                {/* For staff plan */}
                {/* {dates.length &&
                                horizontalPositionMap &&
                                verticalPositionMap &&
                                Array.isArray(staffListWithPlan) &&
                                staffListWithPlan.map((staff, index) => (
                                    <Fragment key={`${staff.id}-${index}`}>
                                        <StaffPlan
                                            key={`${staff.id}-${index}`}
                                            staff={staff}
                                            horizontalPositions={horizontalPositionMap}
                                            verticalPositions={verticalPositionMap}
                                            calendarStart={calendar.start}
                                            calendarEnd={calendar.end}
                                            calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                                        />
                                    </Fragment>
                                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classNames.root}>
      <CalendarControlsRight
        onClickChooseDate={onClickChooseDate}
        calendarPage={CALENDAR_PAGE.DISPOSITION}
      />
      <div className={classNames.stickyHeader}>
        <div style={{ width: "250px", zIndex: 1003 }}>
          <CalendarHeaderLeft title="PeoplePlanner" />
          <CalendarControlsLeft calendarPage={CALENDAR_PAGE.DISPOSITION} />
        </div>
        <div className={classNames.machineAndCalendarContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
            className={classNames.calendarContainer}
          >
            {displayWeekView && <div style={{ height: 54 }} />}
            <div className={classNames.weeks}>
              {dates.length &&
                getMonthIndicator(dates, months).map(
                  (monthData, indexMonth) => (
                    <MonthLabel
                      isWeekView={displayWeekView}
                      index={indexMonth}
                      key={
                        indexMonth + monthData["length"] + monthData["label"]
                      }
                      length={monthData["length"]}
                      label={monthData["label"]}
                      year={monthData.year}
                    />
                  )
                )}
            </div>

            <div
              className={classNames.weeks}
              style={{
                marginLeft:
                  calendarStartFrom === "month" &&
                  !displayWeekView &&
                  Array.isArray(dates) &&
                  dates[0]?.length < 7
                    ? dates[0].length * CALENDAR_STEP_WIDTH
                    : 0,
              }} // Calculate position for first week
            >
              {dates.length > 0 &&
                removeFirstWeek(
                  dates,
                  calendarStartFrom === "month" &&
                    !displayWeekView &&
                    Array.isArray(dates) &&
                    dates[0]?.length < 7
                ).map((week, indexWeek) => (
                  <WeekLabel
                    isWeekView={displayWeekView}
                    index={indexWeek}
                    month={week[0]["month"]}
                    day={week[0]["date"]}
                    year={week[0]["year"]}
                    key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                  />
                ))}
            </div>
            {!displayWeekView && (
              <>
                <div className={classNames.days}>
                  {dates.length > 0 &&
                    dates.map((week) =>
                      week.map((each) => (
                        <DayLabel
                          label={each.date}
                          date={each}
                          key={JSON.stringify(each)}
                        />
                      ))
                    )}
                </div>
                <div className={classNames.dayOfWeeks}>
                  {dates.length > 0 &&
                    dates.map((week) =>
                      week.map((each) => (
                        <DayLabel
                          label={each.date}
                          date={each}
                          key={JSON.stringify(each)}
                          isDaysOfWeek
                        />
                      ))
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ProjectDetailsContainer dates={dates} />
      {/* Add here */}
      {/* {render()} */}
      {page === PAGES.PEOPLE ? (
        <People
          calendarStartFrom={calendarStartFrom}
          dates={dates}
          displayWeekView={displayWeekView}
          totalHeight={totalHeight}
          calendarStepWidth={calendarStepWidth}
          projectsData={projectsData}
          staffsList={staffList}
          horizontalPositionMap={horizontalPositionMap}
          calendar={calendar}
        />
      ) : (
        <></>
      )}
      {page === PAGES.PROJECT ? (
        <Project
          calendarStartFrom={calendarStartFrom}
          dates={dates}
          displayWeekView={displayWeekView}
          totalHeight={totalHeight}
          calendarStepWidth={calendarStepWidth}
          projectsData={projectsData}
          staffsList={staffList}
          horizontalPositionMap={horizontalPositionMap}
          calendar={calendar}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DispositionCalendar;
